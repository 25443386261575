import React from 'react';
import { NavLink } from 'react-router-dom';
import { Popover } from '../../popup/popup';
import { Button } from '../buttons';
import FeatherIcon from 'feather-icons-react';

const AddNew = () => {
  const content = (
    <>
      <NavLink to="/customers/add">
        <FeatherIcon size={16} icon="user" />
        <span>Customers</span>
      </NavLink>
      <NavLink to="/items/add">
        <FeatherIcon size={16} icon="shopping-bag" />
        <span>Items</span>
      </NavLink>
      <NavLink to="/invoices/add">
        <FeatherIcon size={16} icon="file-text" />
        <span>Invoice</span>
      </NavLink>
      <NavLink to="/quotation/add">
        <FeatherIcon size={16} icon="file-minus" />
        <span>Quotation</span>
      </NavLink>
      <NavLink to="/credit-note/add">
        <FeatherIcon size={16} icon="file" />
        <span>Credit Note</span>
      </NavLink>
    </>
  );

  return (
    <Popover placement="bottomLeft" content={content} trigger="click">
      <Button type="primary" style={{marginRight: '8px'}}>
        <FeatherIcon icon="plus" size={14} />
        Create New
      </Button>
    </Popover>
  );
};

export { AddNew };
