import React, { useState } from 'react';
import { Avatar, Badge } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoWraper, UserDropDwon } from './auth-info-style';
// import Notification from './notification';
// import Settings from './settings';
// import Support from './support';
import { Popover } from '../../popup/popup';
import { logOut } from '../../../redux/authentication/actionCreator';
import Heading from '../../heading/heading';
import { AddNew } from '../../buttons/add-new/AddNew';

const AuthInfo = () => {
  const dispatch = useDispatch();
  // const [state, setState] = useState({
  //   flag: 'english',
  // });
  // const { flag } = state;

  const SignOut = e => {
    e.preventDefault();
    dispatch(logOut());
  };

  const { selectedCompany } = useSelector(state => {
    return {
      selectedCompany: state.custom.selectedCompany,
    };
  });

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          {selectedCompany?.companyImage ? (
            <img
              style={{ borderRadius: '50%', backgroundColor: '#fff', objectFit: 'contain' }}
              width="50"
              height="50"
              src={`${process.env.REACT_APP_BACKEND_URL}/uploads/company/companyImage/${selectedCompany?.companyImage}`}
              alt=""
            />
          ) : (
            <img
              style={{ borderRadius: '50%' }}
              width="50"
              height="50"
              src={`https://ui-avatars.com/api/?name=${selectedCompany?.companyName}&background=237CFF&color=fff`}
              alt=""
            />
          )}
          <figcaption>
            <Heading as="h5">{selectedCompany?.companyName}</Heading>
            <p>{selectedCompany?.billingAddress}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/">
              <FeatherIcon icon="grid" /> Dashboard
            </Link>
          </li>
          <li>
            <Link to="/profile">
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
          <li>
            <Link to="/add-company">
              <FeatherIcon icon="plus" /> Add a Company
            </Link>
          </li>
          <li>
            <Link to="/all-company">
              <FeatherIcon icon="layers" /> All Companies
            </Link>
          </li>
          {/* <li>
            <Link to="/pricing-plan">
              <FeatherIcon icon="dollar-sign" /> Pricing Plan
            </Link>
          </li> */}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <AddNew />
      {/* <Notification /> */}
      <Badge dot offset={[-8, -5]}>
        <Link to="/company/settings/notification" className="head-example">
          <FeatherIcon icon="bell" size={20} />
        </Link>
      </Badge>
      <div className="settings">
        <Link to="/company/settings/account" className="head-example">
          <FeatherIcon icon="settings" size={20} />
        </Link>
      </div>
      {/* <div className="settings">
        <Link to="/company/help-and-supports" className="head-example">
            <FeatherIcon icon="help-circle" size={20} />
        </Link>
      </div> */}

      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            {selectedCompany?.companyImage ? (
              <Avatar
                src={`${process.env.REACT_APP_BACKEND_URL}/uploads/company/companyImage/${selectedCompany?.companyImage}`}
                alt={selectedCompany?.companyName}
                style={{
                  objectFit: 'scale-down',
                  width: '40px',
                  height: '40px',
                  border: '1px solid #237cff1a'
                }}
                />
                ) : (
                <Avatar
                  src={`https://ui-avatars.com/api/?name=${selectedCompany?.companyName}&background=237CFF&color=fff`}
                  alt={selectedCompany?.companyName}
                  style={{
                    objectFit: 'scale-down',
                    width: '40px',
                    height: '40px'
                  }}
                />
            )}
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
