import actions from './actions';

const {
  SET_TOTALINVOICES,
  REQUEST_BEGIN,
  REQUEST_ERR,
  REQUEST_SUCCESS,
  SET_USER,
  SET_COMPANY,
  SET_PAGETITLE,
  SET_ADMINSCREEN,
  SET_COMPANIES,
  SET_MENUCLOSE,
  SET_STEP_STATUS
} = actions;

const initalState = {
  user: null,
  errMsg: '',
  successMsg: '',
  loading: false,
  selectedCompany: null,
  companies: [],
  pageTitle: '',
  adminScreen: false,
  noOfInvoices: 0,
  menuClose: false,
  stepsCompleted: false
};

const customeReducer = (state = initalState, action) => {
  const {
    type,
    errMsg,
    successMsg,
    user,
    selectedCompany,
    pageTitle,
    adminScreen,
    companies,
    noOfInvoices,
    menuClose,
    stepsCompleted
  } = action;
  switch (type) {
    case REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        successMsg,
      };
    case REQUEST_ERR:
      return {
        ...state,
        loading: false,
        errMsg,
      };
    case SET_USER:
      return {
        ...state,
        user,
      };
    case SET_STEP_STATUS:
      return {
        ...state,
        stepsCompleted
      };  
    case SET_COMPANY:
      return {
        ...state,
        selectedCompany,
      };
    case SET_COMPANIES:
      return {
        ...state,
        companies,
      };
    case SET_TOTALINVOICES:
      return {
        ...state,
        noOfInvoices,
      };
    case SET_PAGETITLE:
      return {
        ...state,
        pageTitle,
      };
    case SET_ADMINSCREEN:
      return {
        ...state,
        adminScreen,
      };
    case SET_MENUCLOSE:
      return {
        ...state,
        menuClose,
      };

    default:
      return state;
  }
};

export default customeReducer;
