import { Button, Col, Form, Input, Modal, notification, Radio, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { BasicFormWrapper } from '../../../container/styled';
import DataService from '../../../config/dataService/dataService';

const { Option } = Select;
function AddCustomerPopup({ show, handleCancel, onSubmit }) {
  const { selectedCompany } = useSelector(state => ({
    selectedCompany: state.custom.selectedCompany,
  }));
  const [form] = Form.useForm();
  const [states, setStates] = useState([]);
  const [isGSTNumberAdded, setIsGSTNumberAdded] = useState(false)
  const handleSubmit = async values => {
    try {
      const res = await DataService.AddCustomer({ ...values, company: selectedCompany._id });
      form.resetFields();
      onSubmit(res);
      notification.success({
        message: 'Customer added successfully.',
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: error.response.data,
      });
    }
  };

  const fetchStates = async () => {
    try {
      const res = await DataService.fetchStates();
      setStates(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchStates();
  }, []);
  return (
    <Modal type="primary" title="Add Customer" open={show} footer={null} onCancel={handleCancel} width={ window.innerWidth > 1024 ? '60%' : '90%'} centered  bodyStyle={{
      padding: '10px'
    }}>
      <div className="project-modal">
        <BasicFormWrapper>
          <Form form={form} onFinish={handleSubmit} name="multi-form" layout="vertical" size='small'>
            <Row gutter={10}>
              <Col sm={12} lg={8} xs={24} className="mb-10">
                <Form.Item
                  name="customerType"
                  className='add-modal'
                  label="Customer Type"
                  rules={[{ required: true, message: 'This is required field' }]}
                >
                  <Radio.Group>
                    <Row>
                      <Col sm={12} xs={24}>
                        <Radio value="business"> Business </Radio>
                      </Col>
                      <Col sm={12} xs={24}>
                        <Radio value="individual"> Individual </Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                  {/* <Select size="large" className="sDash_fullwidth-select" placeholder="Select Customer Type">
                      <Option value="business">Business</Option>
                      <Option value="individual">Individual</Option>
                    </Select> */}
                </Form.Item>
              </Col>
              <Col sm={12} lg={8} xs={24} className="mb-10">
                <Form.Item
                  label="Company Name / Name"
                  name="name"
                  className='add-modal'
                  rules={[{ required: true, message: 'This is required field' }]}
                >
                  <Input placeholder="Company Name" />
                </Form.Item>
              </Col>
              <Col sm={12} lg={8} xs={24} className="mb-10">
                <Form.Item
                  name="email"
                  label="Email"
                  className='add-modal'
                  rules={[
                    { required: true, message: 'This is required field' },
                    {
                      pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      message: 'Provide valid email',
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
              <Col sm={12} lg={8} xs={24} className="mb-10">
                <Form.Item
                  name="phone"
                  label="Phone"
                  className='add-modal'
                  rules={[
                    { required: true, message: 'This is required field' },
                    { pattern: '^[0-9]*$', message: 'Phone number should be digit only!' },
                  ]}
                >
                  <Input 
                    placeholder="Phone" 
                    minLength={10}
                    maxLength={10}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col md={12} lg={8} sm={12} xs={24} className="mb-10">
                <Form.Item
                  name="GST"
                  className='add-modal'
                  label="GST"
                  dependencies={['PAN']}
                  onChange={() => {
                    if(form.getFieldValue('GST').length >= 14) {
                      form.setFieldsValue({'PAN': form.getFieldValue('GST').slice(2, 12)})
                      setIsGSTNumberAdded(true)
                    }else {
                      setIsGSTNumberAdded(false)
                    }
                  }}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        var re = new RegExp('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$');
                        if(!value){
                          return Promise.resolve()
                        }
                        if(!re.test(value)){
                           return  Promise.reject(new Error('Enter valid GST number'));
                        }
                        if(value.includes(getFieldValue('PAN'))) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('GST No and PAN No does not match'));
                      }
                    }), 
                  ]}
                >
                  <Input style={{ textTransform: 'uppercase' }} placeholder="GST" />
                </Form.Item>
              </Col>
              <Col md={12} lg={8} sm={12} xs={24} className="mb-10">
                <Form.Item
                  name="PAN"
                  className='add-modal'
                  label="PAN"
                  rules={[{ message: 'Enter valid PAN Number', pattern: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/ }]}
                >
                  <Input style={{textTransform: 'uppercase'}} disabled={isGSTNumberAdded} placeholder="PAN" />
                </Form.Item>
              </Col>
              <Col sm={12} xs={24} lg={8} className="mb-10">
                <Form.Item
                  name="address"
                  className='add-modal'
                  label="Address"
                  rules={[{ required: true, message: 'This is required field' }]}
                >
                  <Input placeholder="Address" />
                </Form.Item>
              </Col>
              <Col sm={12} xs={24} lg={8} className="mb-10">
                <Form.Item name="state"  className='add-modal' label="State" rules={[{ required: true, message: 'This is required field' }]}>
                  <Select showSearch placeholder="Select a state" optionFilterProp="children">
                    {states.map(state => (
                      <Option key={state._id} value={state._id}>
                        {state.state}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={12} xs={24} lg={8} className="mb-10">
                <Form.Item  className='add-modal' name="city" label="City" rules={[{ required: true, message: 'This is required field' }]}>
                  <Input placeholder="City" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={10}>
              <Col xs={24} className="mb-10">
                <h3 style={{ color: '' }}>Alternate Contact</h3>
                <Form.List name="alternateContact">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Row key={key} gutter={30} align="middle" className="mb-10">
                          <Col sm={12} xs={24} lg={8}>
                            <Form.Item
                              {...restField}
                              name={[name, 'name']}
                              rules={[{ required: true, message: 'This is required field' }]}
                              className="mb-sm-25"
                            >
                              <Input placeholder="Name" />
                            </Form.Item>
                          </Col>
                          <Col sm={12} xs={24} lg={8}>
                            <Form.Item
                              {...restField}
                              name={[name, 'email']}
                              rules={[
                                { required: true, message: 'This is required field' },
                                {
                                  pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                                  message: 'Provide valid email',
                                },
                              ]}
                              className="mb-sm-25"
                            >
                              <Input placeholder="Email" />
                            </Form.Item>
                          </Col>
                          <Col sm={12} xs={24} lg={7}>
                            <Form.Item
                              {...restField}
                              name={[name, 'phone']}
                              rules={[
                                { required: true, message: 'This is required field' },
                                { pattern: '^[0-9]*$', message: 'Phone number should be digit only!' },
                              ]}
                              className="mb-sm-25"
                            >
                              <Input 
                                  placeholder="Phone" 
                                  minLength={10}
                                  maxLength={10} 
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                  }}
                                />
                            </Form.Item>
                          </Col>
                          <Col sm={12} lg={1} xs={24}>
                            <MinusCircleOutlined style={{color: 'red'}} className="mb-sm-25" onClick={() => remove(name)} />
                          </Col>
                        </Row>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add field
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
              <Col sm={12} xs={24} className="mb-10" />
            </Row>
            <Row>
              <Col sm={12} xs={24}>
                <div className="sDash_form-action">
                  <Button className="btn-signin" htmlType="submit" type="primary" size="large">
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </BasicFormWrapper>
      </div>
    </Modal>
  );
}

AddCustomerPopup.propTypes = {
  show: propTypes.bool,
  handleCancel: propTypes.func,
  onSubmit: propTypes.func,
};

export default AddCustomerPopup;
