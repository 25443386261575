/* eslint-disable no-shadow */
import React, { Component, useEffect } from 'react';
import { Layout, Button, Row, Col, Menu, Alert } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { ThemeProvider } from 'styled-components';
import { connect, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import MenueItems from './MenueItems';
import { Div, SmallScreenAuthInfo, SmallScreenSearch, TopMenuSearch } from './style';
import HeaderSearch from '../components/header-search/header-search';
import AuthInfo from '../components/utilities/auth-info/info';
import DataService from '../config/dataService/dataService';
import actions from '../redux/custom/actions';
import { fetchCompanies, getAllInvoices } from '../redux/custom/actionCreator';

const { darkTheme } = require('../config/theme/themeVariables');

const { SubMenu } = Menu;

const { Header, Footer, Sider, Content } = Layout;
// const { darkMode } = config;

const ThemeLayout = WrappedComponent => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed:window.innerWidth < 768 ? true : false,
        hide: true,
        searchHide: true,
        activeSearch: false,
        up: false,
        noOfInvoice: 0,
      };
      this.updateDimensions = this.updateDimensions.bind(this);
      // this.fetchCompanies = this.fetchCompanies.bind(this);
      // this.getAllInvoices = this.getAllInvoices.bind(this);
    }


    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
      this.props.fetchCompanies();
    }
    
    componentDidUpdate(prevProps, prevState){
      if (this.props.company !== prevProps.company) {
        this.props.getAllInvoices(this.props.company?._id)
      }
      if(this.props.menuClose !== prevProps.menuClose){
        this.setState({collapsed: this.props.menuClose})
      }
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    // getAllInvoices = async company => {
    //   try {
    //     const res = await DataService.listInvoices({
    //       company: company._id,
    //       pageno: 1,
    //       itemsPerPage: 1,
    //     });
    //     this.setState({ noOfInvoice: res.noOfInvoices });
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    // fetchCompanies = async () => {
    //   try {
    //     const res = await DataService.fetchCompanyByUser();
    //     this.props.setCompany(res[0]);
    //     this.getAllInvoices(res[0]);
    //     this.setState(prevState => ({ ...prevState, companies: res }));
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    updateDimensions() {
      // this.setState({
      //   collapsed: window.innerWidth <= 1200 && true,
      // });
    }

    render() {
      const { collapsed, hide, searchHide, activeSearch } = this.state;
      const { ChangeLayoutMode, rtl, topMenu, company } = this.props;

      const left = !rtl ? 'left' : 'right';
      const darkMode = ChangeLayoutMode;
      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };
      // console.log(this.props.menuClose)
      
      const toggleCollapsedMobile = () => {
        if (window.innerWidth <= 990) {
          this.setState({
            collapsed: !collapsed,
          });
        }
      };

      const onShowHide = () => {
        this.setState({
          hide: !hide,
          searchHide: true,
        });
      };

      const toggleSearch = () => {
        this.setState({
          activeSearch: !activeSearch,
        });
      };

      const handleSearchHide = e => {
        e.preventDefault();
        this.setState({
          searchHide: !searchHide,
          hide: true,
        });
      };

      const footerStyle = {
        padding: '20px 30px 18px',
        color: 'rgba(0, 0, 0, 0.65)',
        fontSize: '14px',
        background: 'rgba(255, 255, 255, .90)',
        width: '100%',
        boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
      };

      const SideBarStyle = {
        margin: '70px 0 0 0',
        padding: '15px 15px 55px 15px',
        overflowY: 'auto',
        height: '100vh',
        position: 'fixed',
        [left]: 0,
        zIndex: 998,
      };

      const renderView = ({ style, ...props }) => {
        const customStyle = {
          marginRight: 'auto',
          [rtl ? 'marginLeft' : 'marginRight']: '-17px',
        };
        return <div {...props} style={{ ...style, ...customStyle }} />;
      };

      const renderThumbVertical = ({ style, ...props }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
          [left]: '2px',
        };
        return <div style={{ ...style, ...thumbStyle }} props={props} />;
      };

      const renderTrackVertical = () => {
        const thumbStyle = {
          position: 'absolute',
          width: '6px',
          transition: 'opacity 200ms ease 0s',
          opacity: 0,
          [rtl ? 'left' : 'right']: '2px',
          bottom: '2px',
          top: '2px',
          borderRadius: '3px',
        };
        return <div style={thumbStyle} />;
      };

      const renderThumbHorizontal = ({ style, ...props }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
        };
        return <div style={{ ...style, ...thumbStyle }} props={props} />;
      };

      const handleClick = company => {
        this.props.setCompany(company);
        localStorage.setItem('company',JSON.stringify(company))
        this.props.getAllInvoices(company?._id);

        const { history } = this.props;
        history.push('/');
        window.location.reload()
      };

      return (
        <>
          {
            this.props.isStepsCompleted ?
            <Div darkMode={darkMode}>
              <Layout className="layout">
                <Header
                  style={{
                    position: 'fixed',
                    width: '100%',
                    top: 0,
                    paddingLeft: 0,
                    height: '70px',
                    [!rtl ? 'left' : 'right']: 0,
                  }}
                >
                  <Row className="justify-content-between flex-start">
                    <Col sm={12} xs={12} className="align-center-t navbar-brand">
                      {/* <Link
                        className={topMenu && window.innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'}
                        to="/admin"
                      >
                        <img
                          src={!darkMode ? require(`../static/img/Logo_Dark.svg`) : require(`../static/img/Logo_white.png`)}
                          alt=""
                        />
                      </Link> */}
                      <div style={{position: 'relative'}}>
                        <Menu
                          style={{ width: 280, border: 0 }}
                          defaultSelectedKeys={['1']}
                          defaultOpenKeys={['sub1']}
                          mode="inline"
                          className="logo submenu-css"
                        >
                          <SubMenu
                            key="sub2"
                            style={{ background: '#2697FA' }}
                            title={
                              <div className="logo-content" onClick={() => this.setState({ up: !this.state.up })}>
                                <div>
                                  <h1 className="text-white" style={{textTransform: 'capitalize', fontSize: '17px'}} title={company?.companyName}>
                                    {company?.companyName?.length > 18
                                      ? company?.companyName?.substring(0, 18) + '..'
                                      : company?.companyName}
                                  </h1>
                                  <p style={{fontSize:'13px'}}>
                                    Total Invoices: <strong> {this.props.noOfInvoices} </strong>
                                  </p>
                                </div>
                                <div className={this.state.up ? 'up' : ''}>
                                  <FontAwesome name="chevron-down" />
                                </div>
                              </div>
                            }
                          >
                            {this.props.companies.map((c, i) => (
                                <Menu.Item key={i} className={company?._id === c?._id ? 'selected-company' : ''} onClick={() => handleClick(c)}>
                                  {c.companyName}
                                </Menu.Item>
                            ))}
                          </SubMenu>
                        </Menu>
                      </div>
                      {((!topMenu || window.innerWidth <= 991) && !this.props.adminScreen) ? (
                        <Button type="link" className="toggle-btn" onClick={toggleCollapsed}>
                          <img src={require(`../static/img/icon/${collapsed ? 'right.svg' : 'left.svg'}`)} alt="menu" />
                        </Button>
                      ) : null}
                      <h2 className="page-name">{this.props.pageTitle}</h2>
                    </Col>

                    {/* <Col lg={!topMenu ? 14 : 15} md={8} sm={0} xs={0}>
                      {topMenu && window.innerWidth > 991 ? <TopMenu /> : <HeaderSearch rtl={rtl} darkMode={darkMode} />}
                    </Col> */}

                    <Col lg={6} md={10} sm={0} xs={0}>
                      {topMenu && window.innerWidth > 991 ? (
                        <TopMenuSearch>
                          <div className="top-right-wrap d-flex">
                            <Link
                              className={`${activeSearch ? 'search-toggle active' : 'search-toggle'}`}
                              onClick={() => {
                                toggleSearch();
                              }}
                              to="#"
                            >
                              <FeatherIcon icon="search" />
                              <FeatherIcon icon="x" />
                            </Link>
                            <div className={`${activeSearch ? 'topMenu-search-form show' : 'topMenu-search-form'}`}>
                              <form action="">
                                <span className="search-icon">
                                  <FeatherIcon icon="search" />
                                </span>
                                <input type="text" name="search" />
                              </form>
                            </div>
                            <AuthInfo />
                          </div>
                        </TopMenuSearch>
                      ) : (
                        <AuthInfo />
                      )}
                    </Col>

                    <Col md={0} sm={18} xs={12}>
                      <>
                        <div className="mobile-action mt-30">
                          <Link className="btn-search" style={{display: window.innerWidth < 768 ? 'none' : ''}} onClick={handleSearchHide} to="#">
                            {searchHide ? <FeatherIcon icon="search" /> : <FeatherIcon icon="x" />}
                          </Link>
                          <Link className="btn-auth" onClick={onShowHide} to="#">
                            <FeatherIcon icon="more-vertical" />
                          </Link>
                        </div>
                      </>
                    </Col>
                  </Row>
                </Header>
                <div className="header-more">
                  <Row>
                    <Col md={0} sm={24} xs={24}>
                      <div className="small-screen-headerRight">
                        <SmallScreenSearch hide={searchHide} darkMode={darkMode}>
                          <HeaderSearch rtl={rtl} />
                        </SmallScreenSearch>
                        <SmallScreenAuthInfo hide={hide} darkMode={darkMode}>
                          <AuthInfo rtl={rtl} />
                        </SmallScreenAuthInfo>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Layout>
                  {((!topMenu || window.innerWidth <= 991) && !this.props.adminScreen) ? (
                    <ThemeProvider theme={darkTheme}>
                      <Sider width={280} style={SideBarStyle} collapsed={collapsed} theme={!darkMode ? 'light' : 'dark'}>
                        <Scrollbars
                          className="custom-scrollbar"
                          autoHide
                          autoHideTimeout={500}
                          autoHideDuration={200}
                          renderThumbHorizontal={renderThumbHorizontal}
                          renderThumbVertical={renderThumbVertical}
                          renderView={renderView}
                          renderTrackVertical={renderTrackVertical}
                        >
                          <p className="sidebar-nav-title" style={{marginTop: '15px', marginBottom: '5px'}}>MAIN MENU</p>
                          <MenueItems
                            topMenu={topMenu}
                            rtl={rtl}
                            toggleCollapsed={toggleCollapsedMobile}
                            darkMode={darkMode}
                            isGSTRegistered={(company && company.isGSTRegistered ? true : false)}
                          />
                        </Scrollbars>
                        <div
                          style={{
                            position: 'relative',
                            marginTop: '25px',
                            paddingTop: '15px',
                            borderTop: '1px solid #efefef',
                          }}
                        >
                          {
                            this.state.collapsed ?
                            <figure style={{ width: '40px', margin: 'auto'}}>
                              <img style={{ width: '95%', marginLeft: '5%' }} src={require('../static/img/logo-icon.svg')} alt="" />
                            </figure>
                            :
                            <figure style={{ width: '160px' }}>
                              <img style={{ width: '100%' }} src={require('../static/img/logo.svg')} alt="" />
                            </figure>
                          }
                          {
                            !this.state.collapsed &&
                            <span style={{ position: 'absolute', bottom: '-15px', right: '0', fontSize: '18px' }}>
                              v.2.7.0 
                            </span>
                          }
                        </div>
                      </Sider>
                    </ThemeProvider>
                  ) : null}
                  <Layout className="atbd-main-layout" style={this.props.adminScreen ? {marginLeft:'0'} : {}}>
                    <Content>
                      {

                        (this.props.company && !this.props.company?.isProfileSetUp) ? 
                        <Alert
                          style={{
                            margin: '0 10px'
                          }}
                          className='mt-20'
                          message="Please setup your company profile before generating Invoice and Proforma."
                          type="warning"
                          action={
                            <Button size="small" href='/app/company/settings/account'>
                              Setup Profile
                            </Button>
                          }
                          closable
                        /> : null
                      }
                      <WrappedComponent {...this.props} />
                      <Footer className="admin-footer" style={footerStyle}>
                        <Row>
                          <Col md={12} xs={24}>
                            <span className="admin-footer__copyright"><strong>{new Date().getFullYear()} &copy; OneInvoice</strong> All Rights Reserved.</span>
                          </Col>
                          <Col md={12} xs={24}>
                            <span className="admin-footer__copyright text-right">
                              Made with love at <strong>One ERP Solutions.</strong>
                            </span>
                          </Col>
                        </Row>
                      </Footer>
                    </Content>
                  </Layout>
                </Layout>
              </Layout>
            </Div>
            :
            <WrappedComponent {...this.props} />
          }
        </>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      ChangeLayoutMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      company: state.custom.selectedCompany,
      pageTitle: state.custom.pageTitle,
      adminScreen: state.custom.adminScreen,
      companies: state.custom.companies,
      noOfInvoices: state.custom.noOfInvoices,
      menuClose: state.custom.menuClose,
      isStepsCompleted: state.custom.stepsCompleted
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      setCompany: company => dispatch(actions.setCompany(company)),
      setPageTitle: pageTitle => dispatch(actions.setPageTitle(pageTitle)),
      fetchCompanies : () =>  dispatch(fetchCompanies()),
      getAllInvoices : (company) =>  dispatch(getAllInvoices(company))
    };
  };


  LayoutComponent.propTypes = {
    ChangeLayoutMode: propTypes.bool,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
    changeRtl: propTypes.func,
    changeLayout: propTypes.func,
    changeMenuMode: propTypes.func,
  };

  return connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
};
export default ThemeLayout;
