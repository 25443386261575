import React, { lazy } from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Support from './support/Support';

const Settings = lazy(() => import('./settings/settings'));
const Reports = lazy(() => import('./reports/reports'));
const HelpAndSupport = lazy(() => import('./helpAndSupport/HelpAndSupport'));

function Company() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path === '/' ? '' : path}/settings`} component={Settings} />
      <Route path={`${path === '/' ? '' : path}/reports`} component={Reports} />
      <Route path={`${path === '/' ? '' : path}/help-and-supports`} component={HelpAndSupport} />
      <Route path={`${path === '/' ? '' : path}/support`} component={Support}/>
    </Switch>
  );
}

export default Company;
