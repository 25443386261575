import React from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { NavTitle } from './style';
import { useSelector } from 'react-redux';

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, isGSTRegistered }) => {
  const { path } = useRouteMatch();
  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );
  const { user } = useSelector(state => state.custom);

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      className='side_menu'
    >
      {/* DASHBOARD */}
      <Menu.Item key="home" icon={!topMenu && <FeatherIcon icon="home" />}>
        <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}`}>
          Dashboard
        </NavLink>
      </Menu.Item>

      {/* CUSTOMERS */}
      <SubMenu key="customers"  icon={!topMenu && <FeatherIcon icon="users" />} title="Customers">
        <Menu.Item key="customerList">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/customers/list`}>
            All Customers
          </NavLink>
        </Menu.Item>
        <Menu.Item key="customerAdd">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/customers/add`}>
            Add Customer
          </NavLink>
        </Menu.Item>
        {
          !isGSTRegistered ?
          <>
            <Menu.Item key="foreignCustomerAdd">
              <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/customers/foreign/add`}>
                Add Foreign Customer
              </NavLink>
            </Menu.Item>
            <Menu.Item key="foreignCustomerList">
              <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/customers/foreign/list`}>
                All Foreign Customers
              </NavLink>
            </Menu.Item>
          </> : ''
        }
      </SubMenu>

      {/* ITEMS */}
      <SubMenu key="items" icon={!topMenu && <FeatherIcon icon="shopping-bag" />} title="Items / Services">
        <Menu.Item key="itemsList">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/items/list`}>
            All Items / Services
          </NavLink>
        </Menu.Item>
        <Menu.Item key="itemsCategory">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/items/category/list`}>
            Category
          </NavLink>
        </Menu.Item>
        <Menu.Item key="itemsSubCategory">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/items/sub-category/list`}>
            Sub Category
          </NavLink>
        </Menu.Item>
        <Menu.Item key="itemsAdd">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/items/add`}>
            Add Items / Services
          </NavLink>
        </Menu.Item>
      </SubMenu>

      {/* INVOICES */}
      <SubMenu key="invoices" icon={!topMenu && <FeatherIcon icon="file-text" />} title="Invoices">
        <Menu.Item key="invoicesList">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/invoices/list`}>
            All Invoices
          </NavLink>
        </Menu.Item>
        <Menu.Item key="invoiceAdd">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/invoices/add`}>
            Add Invoice
          </NavLink>
        </Menu.Item>
        <Menu.Item key="recurringPayments">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/invoices/recurring-payments/list`}>
            Recurring/Subscription
          </NavLink>
        </Menu.Item>
        <Menu.Item key="invoiceDeleted">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/invoices/deleted`}>
            Deleted Invoices
          </NavLink>
        </Menu.Item>
      </SubMenu>

      {/* PROFORMA */}
      <SubMenu key="proforma" icon={!topMenu && <FeatherIcon icon="file-minus" />} title="Quotations">
        <Menu.Item key="proformaList">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/quotation/list`}>
            All Quotations
          </NavLink>
        </Menu.Item>
        <Menu.Item key="proformaAdd">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/quotation/add`}>
            Add Quotations
          </NavLink>
        </Menu.Item>
      </SubMenu>

      {/* CREDIT NOTE */}
      <SubMenu key="creditNote" icon={!topMenu && <FeatherIcon icon="file-plus" />} title="Credit Note">
        <Menu.Item key="creditNoteList">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/credit-note/list`}>
            All Credit Note
          </NavLink>
        </Menu.Item>
        <Menu.Item key="creditNoteAdd">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/credit-note/add`}>
            Add Credit Note
          </NavLink>
        </Menu.Item>
      </SubMenu>
      {/* CREDIT NOTE */}
 
       {/* GSTIN */}
       {
        user?.role === 'admin' &&
        <Menu.Item
          icon={
            !topMenu && (
              <NavLink className="menuItem-iocn" to={`${path === '/' ? '' : path}/gst-in/list`}>
                <FeatherIcon icon="database" />
              </NavLink>
            )
          }
          key="gstin"
        >
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/gst-in/list`}>
            Monthly GSTIN
          </NavLink>
        </Menu.Item>
       }

      {/* TASK */}
      {/* <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path === '/' ? '' : path}/task`}>
              <FeatherIcon icon="list" />
            </NavLink>
          )
        }
        key="task"
      >
        <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/task`}>
        Task
        </NavLink>
      </Menu.Item> */}

      {/* COMPANY */}
      {!topMenu && <NavTitle className="sidebar-nav-title">Purchase</NavTitle>}
      <SubMenu key="purchase" icon={!topMenu && <FeatherIcon icon="shopping-cart" />} title="Purchase">
        <Menu.Item key="purchaseList">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/purchase/list`}>
            All Purchase
          </NavLink>
        </Menu.Item>
        <Menu.Item key="purchaseAdd">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/purchase/add`}>
            Add Purchase
          </NavLink>
        </Menu.Item>
      </SubMenu>

      {/* Vendors */}
      <SubMenu key="vendors" icon={!topMenu && <FeatherIcon icon="briefcase" />} title="Vendors">
        <Menu.Item key="vendorsList">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/vendors/list`}>
            All Vendors
          </NavLink>
        </Menu.Item>
        <Menu.Item key="vendorsAdd">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/vendors/add`}>
            Add Vendors
          </NavLink>
        </Menu.Item>
      </SubMenu>

      <SubMenu key="purchaseOrder" icon={!topMenu && <FeatherIcon icon="file" />} title="Purchase Order">
        <Menu.Item key="purchaseOrderList">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/purchase-order/list`}>
            All Purchase Order
          </NavLink>
        </Menu.Item>
        <Menu.Item key="purchaseOrderAdd">
          <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/purchase-order/add`}>
            Add Purchase Order
          </NavLink>
        </Menu.Item>
      </SubMenu>

      {/* COMPANY */}
      {!topMenu && <NavTitle className="sidebar-nav-title">Company</NavTitle>}

      {/* REPORTS */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path === '/' ? '' : path}/company/reports`}>
              <FeatherIcon icon="pie-chart" />
            </NavLink>
          )
        }
        key="reports"
      >
        <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/company/reports`}>
          Reports
        </NavLink>
      </Menu.Item>

      {/* SETTINGS */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path === '/' ? '' : path}/company/settings/account`}>
              <FeatherIcon icon="settings" />
            </NavLink>
          )
        }
        key="settings"
      >
        <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/company/settings/account`}>
          Settings
        </NavLink>
      </Menu.Item>

      {/* HELP AND SUPPORTS */}
      <Menu.Item
        icon={
          !topMenu && (
            <NavLink className="menuItem-iocn" to={`${path === '/' ? '' : path}/company/help-and-supports`}>
              <FeatherIcon icon="mail" />
            </NavLink>
          )
        }
        key="helpAndSupports"
      >
        <NavLink onClick={toggleCollapsed} to={`${path === '/' ? '' : path}/company/help-and-supports`}>
          Help And Supports
        </NavLink>
      </Menu.Item>
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
