import actions from './actions';
import { removeItem } from '../../utility/localStorageControl';

const { logoutBegin, logoutSuccess, logoutErr } = actions;

const logOut = () => {
  return async dispatch => {
    try {
      dispatch(logoutBegin());
      removeItem('token');
      removeItem('company')
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

export { logOut };
