import React, { useEffect, useMemo, useRef, useState } from 'react';
import propTypes, { object } from 'prop-types';
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  DatePicker,
  Divider,
  Button,
  notification,
  Upload,
  Empty,
  Card,
  Tooltip,
  Switch,
  Space,
  Checkbox,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined, UserOutlined, EditOutlined, CaretDownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, BasicFormWrapper } from '../../../container/styled';

import { Cards } from '../../../components/cards/frame/cards-frame';
import DataService from '../../../config/dataService/dataService';
import AddCustomerPopup from '../common/AddCustomerPopup';
import AddForeignCustomerPopup from '../common/AddForeignCustomerPopup.js';
import TermsAndConditionsPopup from '../common/TermsAndConditionsPopup';
import AddNotePopup from '../common/AddNotePopup';
import AddItemPopup from '../common/AddItemPopup';
import AddBankDetailsPopUp from '../common/AddBankDetailsPopUp';
import { fetchCompanies, fetchUser, getAllInvoices } from '../../../redux/custom/actionCreator';
import FontAwesome from 'react-fontawesome';
import { TextEditor } from './components.js/TextEditor';
const { TextArea } = Input;
// import TermsAndConditions from '../settings/overview/TermsAndConditions';

const { Option } = Select;
let timeout;
let currentValue;

const AddInvoices = () => {
  const dispatch = useDispatch();
  const pageTitle = useSelector(state => state.custom.pageTitle);

  const history = useHistory();
  const [form] = Form.useForm();
  const isTdsDeductedWatch = Form.useWatch('isTdsDeducted', form);
  const isTdsPaidWatch = Form.useWatch('isTdsPaid', form);
  const { selectedCompany, loading, stepsCompleted } = useSelector(state => {
    return {
      selectedCompany: state.custom.selectedCompany,
      loading: state.custom.loading,
      stepsCompleted: state.custom.stepsCompleted,
    };
  });
  const [bankDetails, setBankDetails] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [termsConditions, setTermsConditions] = useState([]);
  const [notes, setNotes] = useState([]);
  const [showCustomerPopup, setShowCustomerPopup] = useState(false);
  const [showTermsAndConditionsPopup, setshowTermsAndConditionsPopup] = useState(false);
  const [showNotePopup, setshowNotePopup] = useState(false);
  const [showItemPopup, setshowItemPopup] = useState(false);
  const [showAddBankDetailPopup, setshowAddBankDetailPopup] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [shareLoading, setShareLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [showPrintShare, setShowPrintShare] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [grandTotal, setGrandTotal] = useState(0);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [discount , setDiscount] = useState(0);

  const [tdsAmount, setTdsAmount] = useState(0);
  const [termsData, setTermsData] = useState({});

  const [termsContent, setTermsContent] = useState(null);
  const [noteData, setNoteData] = useState({});
  const [notesContent, setNotesContent] = useState(null);
  const [signature, setSignature] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [isForeignCustomer, setIsForeignCustomer] = useState(false);
  const [showForeignCustomerPopup, setShowForeignCustomerPopup] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [createdGroupName, setCreatedGroupName] = useState([]);

  const [showPartiallyPaid, setShowPartiallyPaid] = useState(false);
  const [isGroupItems, setIsGroupItems] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const fetchBankDetails = async () => {
    try {
      const res = await DataService.fetchBankDetails({ company: selectedCompany._id });
      setBankDetails(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTaxes = async () => {
    try {
      const allTaxes = await DataService.getTaxes();
      setTaxes(allTaxes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchGroupNames = async () => {
    try {
      const { groupNames } = await DataService.allGroupNames({ company: selectedCompany._id });
      setCreatedGroupName(groupNames);
    } catch (error) {
      console.log(error);
    }
  };

  const updateSelectedValue = value => {
    let _address;
    if (selectedCustomer.addressLabel === value) {
      _address = selectedCustomer.address;
    } else {
      _address = selectedCustomer.alternateAddress.find(addr => addr.addressLabel === value).address;
    }
    setSelectedAddress(_address);
  };

  const fetchTermsAndConditions = async () => {
    try {
      const res = await DataService.fetchAllTermsAndConditions({ company: selectedCompany._id });
      setTermsConditions(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNotes = async () => {
    try {
      const res = await DataService.fetchAllNotes({ company: selectedCompany._id });
      setNotes(res);
    } catch (error) {
      console.log(error);
    }
  };

  /* Customer popup handling */
  const onCancelCustomerPopup = () => {
    setShowCustomerPopup(false);
  };
  const onCancelForeignCustomerPopup = () => {
    setShowForeignCustomerPopup(false);
  };

  const setCustomer = customer => {
    setCustomerOptions(prev => [...prev, customer]);
    form.setFieldsValue({ customer: customer._id });
    setSelectedCustomer(customer);
    setShowCustomerPopup(false);
  };

  const setForeignCustomer = customer => {
    setCustomerOptions(prev => [...prev, customer]);
    form.setFieldsValue({ customer: customer._id });
    setSelectedCustomer(customer);
    setShowForeignCustomerPopup(false);
  };

  /* Terms And Conditions popup handling */
  const onCancelTermsConditionsPopup = () => {
    setshowTermsAndConditionsPopup(false);
  };

  const setTermsCondition = item => {
    setTermsConditions(prev => [...prev, item]);
    setTermsData(item);
    // const prevTerms = form.getFieldValue('termsAndConditions') || [];
    form.setFieldsValue({ termsAndConditions: item._id });
    setshowTermsAndConditionsPopup(false);
  };

  /* Notes popup handling */
  const onCancelNotesPopup = () => {
    setshowNotePopup(false);
  };

  const setNote = item => {
    setNotes(prev => [...prev, item]);
    setNoteData(item);
    form.setFieldsValue({ note: item._id });
    setshowNotePopup(false);
  };

  /* Item popup handling */
  const onCancelItemPopup = () => {
    setshowItemPopup(false);
  };

  /* Bank details popup handling */
  const onCancelBankDetailPopup = () => {
    setshowAddBankDetailPopup(false);
  };

  const setBankDetail = item => {
    setBankDetails(prev => [...prev, item]);
    form.setFieldsValue({ bankDetails: item._id });
    setshowAddBankDetailPopup(false);
  };

  /* On search item handling */
  const fetchItemsList = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
    const fetchItems = async () => {
      try {
        const items = await DataService.searchItem({ company: selectedCompany?._id, title: currentValue });
        if (callback) {
          callback(items);
        } else {
          setItemOptions(items);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (callback) {
      timeout = setTimeout(fetchItems, 300);
    } else {
      fetchItems();
    }
  };
  const getItemsForOptions = async items => {
    try {
      const res = await DataService.getItemsForOptions(items);
      setItemOptions(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (selectedCustomer) {
      const items = form.getFieldValue('items').map(i => {
        if (typeof i.item !== 'undefined') {
          return i.item;
        }
      });
      if (items.length > 0 && items[0] !== undefined) {
        getItemsForOptions(items);
      }
    }
  }, [selectedCustomer]);
  const SearchItem = ({ setSelectedItem, fieldIndex, ...rest }) => {
    const [data, setData] = useState(itemOptions);

    const handleSearch = newValue => {
      if (newValue) {
        fetchItemsList(newValue, setData);
      } else {
        setData([]);
      }
    };

    const handleChange = newValue => {
      rest.onChange(newValue);
      // console.log(rest.fieldKey);
      const item = data.find(item => item._id.toString() === newValue);
      setSelectedItem(item);

      form.setFieldsValue({
        items: [
          ...form.getFieldValue('items').map((_item, index) => {
            if (index === fieldIndex) {
              return {
                ..._item,
                customLabel: item?.title,
              };
            }
            return _item;
          }),
        ],
      });

      return newValue;
    };

    const options = data.map(d => (
      <Option key={d._id} value={d._id}>
        {d.title}
      </Option>
    ));

    return (
      <Select
        showSearch
        {...rest}
        // value={value}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={null}
        placeholder="Search Items"
      >
        {options}
      </Select>
    );
  };

  /* On search customer handling */
  const fetchCustomersList = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
    const fetchItems = async () => {
      try {
        const items = await DataService.searchCustomer({ company: selectedCompany?._id, name: currentValue });
        if (callback) {
          callback(items);
        } else {
          // check selected value here
          const _prevSelected = form.getFieldValue('customer');
          if (_prevSelected) {
            let isMatch = items.find(i => i._id.toString() === _prevSelected.toString());
            if (!isMatch) {
              form.setFieldsValue({ customer: undefined });
            }
          }
          setCustomerOptions(items);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (callback) {
      timeout = setTimeout(fetchItems, 300);
    } else {
      fetchItems();
    }
  };

  const SearchCustomer = useMemo(() => {
    return props => {
      const [data, setData] = useState(customerOptions);

      const handleSearch = newValue => {
        if (newValue) {
          fetchCustomersList(newValue, setData);
        } else {
          // setData([]);
          fetchCustomersList();
        }
      };

      const options = data.map(d => (
        <Option key={d._id} value={d._id}>
          {d.name}-{d.phone}
        </Option>
      ));

      const handleSelect = v => {
        const customer = data.find(c => v == c._id);
        setSelectedCustomer(customer);
        setSelectedAddress('');
        form.setFieldsValue({ displayAddress: '' });
      };
      return (
        <Select
          {...props}
          showSearch
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onSelect={handleSelect}
          notFoundContent={null}
          placeholder="Search Customers"
        >
          {options}
        </Select>
      );
    };
  }, [customerOptions, selectedCompany]);

  const fetchForeignCustomersList = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
    const fetchItems = async () => {
      try {
        const items = await DataService.searchForeignCustomer({ company: selectedCompany?._id, name: currentValue });
        if (callback) {
          callback(items);
        } else {
          // check selected value here
          const _prevSelected = form.getFieldValue('customer');
          if (_prevSelected) {
            let isMatch = items.find(i => i._id.toString() === _prevSelected.toString());
            if (!isMatch) {
              form.setFieldsValue({ customer: undefined });
            }
          }
          setCustomerOptions(items);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (callback) {
      timeout = setTimeout(fetchItems, 300);
    } else {
      fetchItems();
    }
  };

  const SearchForeignCustomer = useMemo(() => {
    return props => {
      const [data, setData] = useState(customerOptions);
      const handleSearch = newValue => {
        if (newValue) {
          fetchForeignCustomersList(newValue, setData);
        } else {
          // setData([]);
          fetchForeignCustomersList();
        }
      };

      const options = data.map(d => (
        <Option key={d._id} value={d._id}>
          {d.name}-{d.phone}
        </Option>
      ));

      const handleSelect = v => {
        const customer = data.find(c => v == c._id);
        setSelectedCustomer(customer);
      };
      return (
        <Select
          {...props}
          showSearch
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onSelect={handleSelect}
          notFoundContent={null}
          placeholder="Search Customers"
        >
          {options}
        </Select>
      );
    };
  }, [customerOptions, selectedCompany]);

  useEffect(() => {
    if (selectedCompany) {
      fetchBankDetails();
      fetchTaxes();
      fetchTermsAndConditions();
      fetchNotes();
      fetchCompany();
      fetchItemsList();
      fetchCustomersList();
      fetchInvoiceNumber();
      fetchGroupNames();
    }
    form.setFieldsValue({
      items: [
        {
          item: undefined,
          amount: undefined,
          quantity: undefined,
          tax: undefined,
          total: undefined,
          includingTax: undefined,
          displayTotal: undefined,
        },
      ],
    });

    return () => {
      setCreatedGroupName([]);
    };
  }, [selectedCompany]);

  const setMeta = () => {
    dispatch({ type: 'SET_PAGETITLE', pageTitle: 'Add Invoice' });
  };

  const fetchInvoiceNumber = async () => {
    try {
      const { invoiceNo } = await DataService.fetchInvoiceNumber({ company: selectedCompany._id });
      setInvoiceNumber(invoiceNo);
    } catch (error) {
      console.log(error);
      notification.error({ message: error.response.data.message ? error.response.data.message : error.response.data });
    }
  };

  useEffect(() => {
    setMeta();
    document.title = 'One Invoice - Add Invoice';
  }, []);

  // const getTotal = () => {
  //   let items = form.getFieldValue('items');

  //   let amount = 0;
  //   let subTotal = 0;
  //   let taxAmount = 0;
  //   let discount = form.getFieldValue('discount') ? form.getFieldValue('discount') : 0;

  //   if(items.length === 0) {
  //     setSubTotalAmount(0)
  //     setGrandTotal(0);
  //     if(form.getFieldValue('tdsPercentage')) {
  //       handleTdsChange(0, 0, 0)
  //     }
  //     if(form.getFieldValue('tdsPaidAmount')){
  //       handleTdsPaid(0,0)
  //     }
  //     if(company.isGSTRegistered){
  //       setTotalTaxAmount(0);
  //     }
  //   }else {
  //     items.map(item => {
  //       if(item) {
  //         amount += Number(item?.displayTotal);
  //         taxAmount += Number(item?.igst);
  //         subTotal += Number(item?.total);
  //         setSubTotalAmount(subTotal)
  //         if(discount !== 0) {
  //           let gt = (subTotal - discount) + taxAmount;
  //           setGrandTotal(gt)
  //         }else {
  //           setGrandTotal(amount);
  //         }
  //         if(form.getFieldValue('tdsPercentage')) {
  //           handleTdsChange(form.getFieldValue('tdsPercentage'), subTotal, amount)
  //         }
  //         if(form.getFieldValue('tdsPaidAmount')){
  //           handleTdsPaid(form.getFieldValue('tdsPaidAmount'), form.getFieldValue('tdsAmount'))
  //         }
  //         if(company.isGSTRegistered){
  //           setTotalTaxAmount(taxAmount);
  //         }
  //       }
  //     });
  //   }
  // };

  const getTotal = () => {

    let items = form.getFieldValue('items');
    
    if (items.length === 0) {
      setSubTotalAmount(0);
      setGrandTotal(0);
      if (form.getFieldValue('tdsPercentage')) handleTdsChange(0, 0, 0);
      if (form.getFieldValue('tdsPaidAmount')) handleTdsPaid(0, 0);
      if (company.isGSTRegistered) setTotalTaxAmount(0);
    } else {
      let discount = form.getFieldValue('discount') ?? 0;
      
      let DR = 1;
      let invoiceTotal = items.reduce((acc, item) => (acc += parseFloat(item.total)), 0);
      let itemArr = [];

      if (discount > 0) {
        DR = (invoiceTotal - discount) / invoiceTotal;
      }

      for (let item of items) {
        // GST registered
        let tax = taxes.find(t => t._id === item.tax);
        if (selectedCompany.isGSTRegistered) {
          if (item.includingTax) {
            let taxableAmount = parseFloat(item.total * DR);
            let gst = parseFloat((tax.percentage * taxableAmount) / (100 + tax.percentage));
            taxableAmount = parseFloat(taxableAmount - gst);
            let _item = {
              subtotal: +item.total,
              discount: item.total - parseFloat(item.total * DR),
              taxableAmount,
              gst,
              total: parseFloat(taxableAmount + gst),
            };
            itemArr.push(_item);
          } else {
            let taxableAmount = parseFloat(item.total) - parseFloat(discount / items.length);
            let gst = parseFloat((taxableAmount / 100) * tax.percentage);

            let _item = {
              subtotal: +item.total,
              discount: item.total - parseFloat(item.total * DR),
              taxableAmount,
              gst,
              total: parseFloat(parseFloat(taxableAmount) + parseFloat(gst)),
            };
            itemArr.push(_item);
          }
        } else {
          // Non GST registered
          let taxableAmount = parseFloat(item.total * DR);
          let _item = {
            subtotal: +item.total,
            discount: item.total - parseFloat(item.total * DR),
            taxableAmount,
            gst: 0,
            total: taxableAmount,
          };
          itemArr.push(_item);
        }
      }

      let subTotal = itemArr.reduce((acc, item) => (acc += item.taxableAmount), 0);
      let taxAmount = itemArr.reduce((acc, item) => (acc += item.gst), 0);
      let grandTotal = itemArr.reduce((acc, item) => (acc += item.total), 0);

      setSubTotalAmount(subTotal.toFixed(2));
      setTotalTaxAmount(taxAmount.toFixed(2));
      setGrandTotal(grandTotal.toFixed(2));

    }
  };

  //Item
  const ItemFields = useMemo(() => {
    return ({ name, restField, remove, form, index }) => {
      const [isIncluded, setIsIncluded] = useState(undefined);
      const [selectedItem, setSelectedItem] = useState();
      const groupRef = useRef(null);
      const [groupName, setGroupName] = useState('');

      const addGroupName = e => {
        e.preventDefault();
        setCreatedGroupName([...createdGroupName, groupName]);
        setGroupName('');
      };

      const handlSetFields = () => {
        const key = index;
        const items = form.getFieldValue('items');
        let displayTotal = 0;
        const isIncluded = selectedItem.includingTax;
        let cgst;
        let igst;
        let sgst;
        if (company.isGSTRegistered) {
          if (!selectedItem.tax) {
            setTimeout(() => {
              history.push(`/items/${selectedItem._id}/edit`);
            }, 1000);
            return notification.warning({ message: "Please Update Item's Tax Details first" });
          }

          if (!isIncluded) {
            const sellingPrice = selectedItem.totalAmount;
            const taxableAmount = sellingPrice;
            const tax = taxes.find(t => t._id === selectedItem.tax);
            const taxAmount = parseFloat(((taxableAmount * tax.percentage) / 100).toFixed(2));
            cgst = taxAmount / 2;
            sgst = taxAmount / 2;
            igst = taxAmount;
            displayTotal = taxableAmount + taxAmount;
          } else {
            const sellingPrice = selectedItem.totalAmount;
            const tax = taxes.find(t => t._id === selectedItem.tax);
            if (tax) {
              const taxAmount = parseFloat(((sellingPrice * tax.percentage) / (100 + tax.percentage)).toFixed(2));
              cgst = taxAmount / 2;
              sgst = taxAmount / 2;
              igst = taxAmount;
              displayTotal = selectedItem.totalAmount.toString();
            }
          }
        } else {
          displayTotal = selectedItem.totalAmount.toString();
        }
        items[key] = {
          ...items[key],
          amount: selectedItem.totalAmount.toString(),
          quantity: '1',
          tax: selectedItem.tax?.toString(),
          displayTotal: displayTotal.toString(),
          total: selectedItem.totalAmount.toString(),
          includingTax: selectedItem.includingTax,
          cgst,
          sgst,
          igst,
          HSN_SAC: selectedItem.HSN_SAC,
          description: selectedItem.description ? selectedItem.description : '',
        };
        form.setFieldsValue({
          items,
        });
        form.validateFields(['items']);
        getTotal();
      };
      useEffect(() => {
        if (selectedItem) {
          handlSetFields();
        }
      }, [selectedItem]);

      const handleChange = e => {
        const key = index;
        const items = form.getFieldValue('items');

        let displayTotal = 0;
        let cgst;
        let igst;
        let sgst;
        const isIncluded = items[key].includingTax;
        if (company.isGSTRegistered) {
          if (!isIncluded) {
            const sellingPrice = items[key].amount;
            const taxableAmount = sellingPrice * items[key].quantity;
            const tax = taxes.find(t => t._id === items[key].tax);
            if (tax) {
              const taxAmount = parseFloat(((taxableAmount * tax.percentage) / 100).toFixed(2));
              cgst = taxAmount / 2;
              sgst = taxAmount / 2;
              igst = taxAmount;
              displayTotal = (taxableAmount + taxAmount)?.toFixed(2);
            }
          } else {
            const sellingPrice = items[key].amount;
            const tax = taxes.find(t => t._id === items[key].tax);
            if (tax) {
              const taxAmount = parseFloat(
                (((sellingPrice * tax.percentage) / (100 + tax.percentage)) * items[key].quantity).toFixed(2),
              );
              cgst = taxAmount / 2;
              sgst = taxAmount / 2;
              igst = taxAmount;
              displayTotal = (items[key].amount * items[key].quantity)?.toFixed(2);
            }
          }
        } else {
          displayTotal = (items[key].amount * items[key].quantity)?.toFixed(2);
        }
        items[key] = {
          ...items[key],
          displayTotal,
          total: (items[key].amount * items[key].quantity)?.toFixed(2),
          cgst,
          igst,
          sgst,
        };
        form.setFieldsValue({
          items,
        });
        getTotal();
      };

      const handleRemoveItem = () => {
        remove(name);
        getTotal();
      };

      useEffect(() => {
        if (selectedItem) {
          setIsIncluded(selectedItem.includingTax);
        }
      }, [selectedItem]);

      useEffect(() => {
        if (itemOptions) {
          if (form.getFieldValue('items')[index]) {
            const item = itemOptions.find(i => i._id === form.get);
            const isIncluded = form.getFieldValue('items')[index].includingTax;
            setIsIncluded(isIncluded);
          }
        }
      }, [itemOptions]);

      return (
        // eslint-disable-next-line react/prop-types
        <Row align="top" gutter={10} className="mb-10" key={restField.fieldKey}>
          {isGroupItems && (
            <Col span={24}>
              <Row>
                <Col span={24} md={12} lg={8}>
                  <Form.Item
                    {...restField}
                    name={[name, 'groupName']}
                    label={window.innerWidth > 991 ? (index === 0 ? 'Select Group Name' : '') : 'Select Group Name'}
                  >
                    <Select
                      placeholder="Select Group Name"
                      size="small"
                      className="select-small mb-10"
                      dropdownRender={menu => (
                        <>
                          {menu}
                          <Divider style={{ margin: '8px 0' }} />
                          <Space style={{ padding: '0 8px 4px' }}>
                            <Input
                              placeholder="Please enter item"
                              ref={groupRef}
                              value={groupName}
                              onChange={e => setGroupName(e.target.value)}
                            />
                            <Button type="text" icon={<PlusOutlined />} onClick={addGroupName}>
                              Add item
                            </Button>
                          </Space>
                        </>
                      )}
                      options={createdGroupName.map(item => ({
                        label: item,
                        value: item,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          )}
          {/* //items  */}
          <Col xs={24} md={8} lg={6} className="mb-5">
            {form.getFieldValue('items')[index]?.item ? (
              <Form.Item
                {...restField}
                name={[name, 'customLabel']}
                // label={window.innerWidth > 991 ? (index === 0 ? 'Item' : '') : 'Item'}
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input placeholder="Item Title" />
              </Form.Item>
            ) : (
              <Form.Item
                {...restField}
                name={[name, 'item']}
                // label={window.innerWidth > 991 ? (index === 0 ? 'Item' : '') : 'Item'}
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <SearchItem fieldIndex={index} setSelectedItem={setSelectedItem} />
              </Form.Item>
            )}
          </Col>
          {/* Selling Price  */}
          <Col xs={24} md={8} lg={3} className="mb-5">
            <Form.Item
              {...restField}
              name={[name, 'amount']}
              // label={window.innerWidth > 991 ? (index === 0 ? 'Selling Price' : '') : 'Selling Price'}
              rules={[
                { required: true, message: 'This field is required' },
                { pattern: '^[0-9]*(.?[0-9]*)$', message: 'Selling Price should be digit only!' },
              ]}
            >
              <Input
                placeholder="Selling Price"
                onChange={handleChange}
                onKeyPress={event => {
                  if (!/^[0-9]*(\.?[0-9]*)$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          {/* Quantity */}
          <Col md={8} xs={24} lg={2} className="mb-5">
            <Form.Item
              {...restField}
              name={[name, 'quantity']}
              // label={window.innerWidth > 991 ? (index === 0 ? 'Quantity' : '') : 'Quantity'}
              rules={[
                { required: true, message: 'This field is required' },
                { pattern: '^[0-9]*$', message: 'Quantity should be digits only!' },
              ]}
            >
              <Input
                placeholder="Quantity"
                onChange={handleChange}
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          {/* Subtotal */}
          <Col md={8} xs={24} lg={3} className="mb-5">
            <Form.Item
              {...restField}
              name={[name, 'total']}
              // label={window.innerWidth > 991 ? (index === 0 ? 'Subtotal' : '') : 'Subtotal'}
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input
                placeholder="Total"
                disabled
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          {/* Tax  with IGST logic */}
          {company?.isGSTRegistered ? (
            <>
              <Col md={8} xs={24} lg={2} className="mb-5">
                <div style={{ position: 'relative' }}>
                  <Form.Item
                    {...restField}
                    name={[name, 'tax']}
                    // label={window.innerWidth > 991 ? (index === 0 ? 'Tax' : '') : 'Tax'}
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Select onChange={handleChange} suffixIcon={<CaretDownOutlined />} placeholder="Tax">
                      {taxes.map(t => (
                        <Option key={t._id} value={t._id}>
                          {t.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {typeof isIncluded !== 'undefined' && (
                    <span style={{ position: 'absolute', bottom: '-20px', right: 0, fontSize: '12px' }}>
                      {isIncluded ? '*Inclusive' : '*Exclusive'}
                    </span>
                  )}
                </div>
              </Col>
              {/* //IGST / SGST OR IGST Conditions */}
              {selectedCustomer?.state && selectedCompany?.state === selectedCustomer?.state ? (
                <>
                  <Col xs={24} lg={2} className="mb-5">
                    <Form.Item
                      {...restField}
                      // label={window.innerWidth > 991 ? (index === 0 ? 'CGST' : '') : 'CGST'}
                      name={[name, 'cgst']}
                    >
                      <Input placeholder="CGST" disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={2} className="mb-5">
                    <Form.Item
                      {...restField}
                      // label={window.innerWidth > 991 ? (index === 0 ? 'SGST' : '') : 'SGST'}
                      name={[name, 'sgst']}
                    >
                      <Input placeholder="SGST" disabled />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <Col xs={24} md={8} lg={4} className="mb-5">
                  <Form.Item
                    {...restField}
                    // label={window.innerWidth > 991 ? (index === 0 ? 'IGST' : '') : 'IGST'}
                    name={[name, 'igst']}
                  >
                    <Input placeholder="IGST" disabled />
                  </Form.Item>
                </Col>
              )}
            </>
          ) : null}
          {/* HSN SAC HIDDEN NOT USING FOR NOW  */}
          <Col xs={24} md={8} lg={4} className="mb-10" hidden>
            <Form.Item {...restField} label="HSN_SAC" name={[name, 'HSN_SAC']} hidden>
              <Input placeholder="HSN_SAC" disabled />
            </Form.Item>
          </Col>
          {/* Total */}
          <Col md={8} xs={24} lg={3} className="mb-10">
            <Form.Item
              {...restField}
              name={[name, 'displayTotal']}
              // label={window.innerWidth > 991 ? (index === 0 ? 'Total' : '') : 'Total'}
              // label={`Total ${typeof isIncluded !== 'undefined' ? (isIncluded ? '(Inclusive)' : '(Exclusive)') : ''}`}
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input
                placeholder="Total"
                disabled
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={2} hidden>
            <Form.Item {...restField} name={[name, 'includingTax']}>
              <Input />
            </Form.Item>
          </Col>
          {/* Close X Button  */}
          <Col lg={1} md={2} xs={24} className="mb-10 input_btn_x">
            <Button
              onClick={handleRemoveItem}
              type="danger-transparent"
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,
                height: '30px',
                // marginTop:
                //   window.innerWidth > 991 ? (index === 0 ? '30px' : '') : window.innerWidth < 576 ? '' : '25px',
              }}
              size="default"
            >
              <FeatherIcon icon="x" size={16} />
            </Button>
          </Col>
          {/* Description */}
          <Col span={24} lg={6} className="mb-20">
            <Form.Item name={[name, 'description']} {...restField}>
              <TextArea placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
      );
    };
  }, [itemOptions, taxes, selectedCompany, selectedCustomer, company, createdGroupName, isGroupItems]);

  const updateTermAndCondition = async id => {
    try {
      let termValue;
      termsConditions.map(term => {
        if (term._id === id) {
          termValue = term;
        }
      });
      termValue.termAndCondition = termsContent;
      await DataService.editTermsAndCondition({ data: termValue, id });
      return;
    } catch (err) {
      console.log(err);
      notification.success({ message: err.response.data });
    }
  };

  const updateNote = async id => {
    try {
      let noteValue;
      notes.map(note => {
        if (note._id === id) {
          noteValue = note;
        }
      });
      noteValue.note = notesContent;
      const res = await DataService.editNote({ data: noteValue, id });
      return;
    } catch (err) {
      console.log(err);
      notification.success({ message: err.response.data });
    }
  };

  const handleSubmit = async values => {
    if (values.generateStatus === 'share') {
      setShareLoading(true);
    }
    if (values.generateStatus === 'generate') {
      setGenerateLoading(true);
    }
    if (values.generateStatus === 'save') {
      setDraftLoading(true);
    }
    // return console.log(values);
    try {
      // if(values.termsConditions){
      //   await updateTermAndCondition(values.termsAndConditions);
      // }
      // if(values.note){
      //   await updateNote(values.note);
      // }
      const formData = new FormData();
      let temp = values.items.map((item, i) => {
        var tempObj = {};
        Object.keys(item).forEach(key => {
          if (item[key] !== undefined) {
            tempObj[key] = item[key];
          }
        });
        return tempObj;
      });
      // return console.log(temp)
      temp.forEach((item, i) => {
        Object.keys(item).forEach(key => {
          formData.append(`items[${i}][${key}]`, item[key]);
        });
      });
      if (!temp.length > 0) {
        return notification.error({
          message: 'Atleast one item needed to create invoice.',
        });
      }
      if (values.termsAndConditions) {
        // values.termsAndConditions.forEach((item, i) => {
        //   formData.append(`termsAndConditions[${i}]`, item);
        // });
        formData.append('termsAndConditions', values.termsAndConditions);
        formData.append('termsContent', termsContent);
      }
      if (values.note) {
        formData.append('note', values.note);
        formData.append('notesContent', notesContent);
      }

      formData.append('bankDetails', values.bankDetails);
      formData.append('customer', values.customer);
      if (values?.displayAddress) {
        formData.append('displayAddress', values.displayAddress);
      }
      formData.append('isForeignCustomer', isForeignCustomer);
      formData.append('date', values.date);
      if (values.poNo) {
        formData.append('poNo', values.poNo);
      }
      if (values.discount) {
        formData.append('discount', values.discount);
      }
      if (selectedCurrency) {
        formData.append('currency', selectedCurrency);
      }
      formData.append('dueDate', values.dueDate);
      formData.append('generateStatus', values.generateStatus);
      if (values.status) {
        formData.append('status', values.status);
        if (values.status === 'partially-paid') {
          formData.append('partiallyPaidAmount', values.partiallyPaidAmount);
        }
      }
      if (values.signature) {
        formData.append('signature', values.signature.file);
      }
      formData.append('company', selectedCompany?._id);

      formData.append('isTdsDeducted', values.isTdsDeducted);
      formData.append('tdsPercentage', values.tdsPercentage);
      formData.append('tdsAmount', values.tdsAmount);
      formData.append('clientPayableAmount', values.clientPayableAmount);
      formData.append('isTdsPaid', values.isTdsPaid);
      formData.append('tdsPaidAmount', values.tdsPaidAmount);
      formData.append('tdsDueAmount', values.tdsDueAmount);
      formData.append('remark', values.remark);

      const res = await DataService.createInvoice(formData);
      if (res.generateStatus === 'generate') {
        const link = document.createElement('a');
        link.href = `${process.env.REACT_APP_BACKEND_URL}/uploads/invoice/${res.invoice}.pdf`;
        link.setAttribute('download', `${res.invoice}.pdf`);
        link.target = '_blank';
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        notification.success({ message: 'Invoice generated successfully.' });
      }
      if (res.generateStatus === 'share') {
        notification.success({ message: 'Invoice generated and sent successfully.' });
      }
      if (res.generateStatus === 'save') {
        notification.success({ message: 'Invoice saved successfully.' });
      }
      dispatch(getAllInvoices(selectedCompany._id));
      fetchCompany();
      setSignature([]);
      if (stepsCompleted) {
        history.push('/invoices/list');
      } else {
        history.push('/');
        dispatch(fetchUser());
      }
    } catch (error) {
      console.log(error);
      notification.error({ message: error.response.data });
    } finally {
      setShareLoading(false);
      setGenerateLoading(false);
      setDraftLoading(false);
    }
  };

  const handleStatusSubmit = generateStatus => {
    form.setFieldsValue({
      generateStatus,
    });
    form.submit();
  };

  const fetchCompany = async () => {
    try {
      const res = await DataService.fetchCompany(selectedCompany._id);
      setCompany(res.company);
    } catch (error) {
      console.log(error);
    }
  };

  const updateCompanyImage = async e => {
    try {
      if (e.target.files[0]) {
        const formData = new FormData();
        formData.append('companyImage', e.target.files[0]);

        const res = await DataService.updateCompanyImage({ file: formData, id: company._id });
        fetchCompany();
        notification.success({ message: res });
      }
    } catch (err) {
      console.log(err);
      notification.error({ message: 'Something went wrong' });
    }
  };

  const handleStatusChange = value => {
    setShowPartiallyPaid(false);
    if (value === 'draft') {
      setShowPrintShare(false);
    } else if (value === 'partially-paid') {
      setShowPartiallyPaid(true);
    } else {
      setShowPrintShare(true);
    }
  };

  const handleTermsChange = e => {
    if (!e) {
      setTermsData({});
      setTermsContent(null);
      return;
    }
    termsConditions.map(term => {
      if (term._id === e) {
        setTermsData(term);
      }
    });
  };

  const handleNoteChange = e => {
    if (!e) {
      setNoteData({});
      setNotesContent(null);
      return;
    }
    notes.map(note => {
      if (note._id === e) {
        setNoteData(note);
      }
    });
  };

  const handleForeignCustomerToggle = checked => {
    setIsForeignCustomer(checked);
    form.setFieldsValue({ customer: '' });
    if (checked) {
      fetchForeignCustomersList();
    }
  };

  const handleCurrencyToggle = value => {
    setSelectedCurrency(value);
  };

  const handleTdsChange = (percent, subTotal = subTotalAmount, gTotal = grandTotal) => {
    const tds = (percent / 100) * subTotal;
    setTdsAmount(tds);
    form.setFieldsValue({
      tdsAmount: tds,
      clientPayableAmount: gTotal - tds,
    });
  };

  const handleTdsPaid = (amount, tds = tdsAmount) => {
    form.setFieldsValue({
      tdsDueAmount: tds - amount,
    });
  };

  return (
    <>
      <PageHeader
        ghost
        className="invoice-header2"
        subTitle={
          <div className="page-header-subtite">
            <div>
              <Space style={{ backgroundColor: 'white', padding: '7px 15px', borderRadius: '5px' }}>
                <span style={{ color: '#237CFF', fontWeight: '600' }}>All Invoices</span>
                <FeatherIcon style={{ marginRight: '2px', marginBottom: '-2px' }} icon="chevrons-right" size="13px" />
                <span style={{ fontWeight: '600' }}>{invoiceNumber ? `# ${invoiceNumber}` : '- ---'}</span>
              </Space>
            </div>

            <div className="custom-checkbox-container" style={{ padding: '5px 10px' }}>
              <input type="checkbox" className="custom-checkbox2" />
              <span className="custom-label">Recurring Invoice</span>
            </div>
          </div>
        }
        buttons={[
          <div key="1" className="page-header-actions2">
            <Button
              htmlType="button"
              loading={draftLoading}
              size="small"
              onClick={() => handleStatusSubmit('save')}
              style={{ border: '1px solid #D9870B', color: '#D9870B' }}
              icon={<FeatherIcon style={{ marginRight: '2px', marginBottom: '-2px' }} icon="file-text" size="15px" />}
            >
              Save
            </Button>
            <Button
              htmlType="button"
              loading={draftLoading}
              size="small"
              onClick={() => console.log('Copy Link')}
              style={{ border: '1px solid #277EFE', color: '#277EFE' }}
              icon={<FeatherIcon style={{ marginRight: '2px', marginBottom: '-2px' }} icon="link" size="13px" />}
            >
              Copy Link
            </Button>
            {showPrintShare ? (
              <>
                <Button
                  htmlType="button"
                  size="small"
                  style={{ border: '1px solid #277EFE', color: '#277EFE' }}
                  onClick={() => handleStatusSubmit('generate')}
                  icon={
                    <FeatherIcon style={{ marginRight: '2px', marginBottom: '-2px' }} icon="file-text" size="15px" />
                  }
                  loading={generateLoading}
                >
                  Download PDF
                </Button>
                <Button
                  htmlType="button"
                  size="small"
                  onClick={() => handleStatusSubmit('share')}
                  icon={<FeatherIcon style={{ marginRight: '2px', marginBottom: '-2px' }} icon="send" size={'14px'} />}
                  loading={shareLoading}
                  style={{ background: '#119E40', color: 'white' }}
                >
                  Send Invoice
                </Button>
              </>
            ) : null}
          </div>,
        ]}
      />
      <Main style={{ minHeight: '77.5vh' }}>
        <BasicFormWrapper>
          <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={{ status: 'unpaid', partiallyPaidAmount: 0 }}
            name="multi-form"
            layout="vertical"
            size="small"
            className="require_mark_reverse"
          >
            <Cards headless>
              <Row gutter={18}>
                {company && !company.isGSTRegistered ? (
                  <>
                    <Col span={24} md={12}>
                      <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
                        {isForeignCustomer && (selectedCompany.isDollarCurrency || selectedCompany.isPoundCurrency) && (
                          <>
                            <Select
                              style={{ width: '100px' }}
                              placeholder="Currency"
                              defaultValue="USD"
                              onChange={handleCurrencyToggle}
                            >
                              <Option value="INR">INR</Option>
                              <Option value="USD">USD</Option>
                              <Option value="GBP">Pound</Option>
                            </Select>
                            {!selectedCurrency && (
                              <Input style={{ width: '110px', height: '38px' }} placeholder="$1 - ₹83.49" />
                            )}
                            {selectedCurrency === 'INR' && (
                              <Input style={{ width: '110px', height: '38px' }} placeholder="₹1 - ₹1" />
                            )}
                            {selectedCurrency === 'USD' && (
                              <Input style={{ width: '110px', height: '38px' }} placeholder="$1 - ₹83.49" />
                            )}
                            {selectedCurrency === 'GBP' && (
                              <Input style={{ width: '110px', height: '38px' }} placeholder="£1 - ₹105.26" />
                            )}
                          </>
                        )}
                        <h4 className="mb-0">
                          Foreign Customer <Switch onChange={handleForeignCustomerToggle} />
                        </h4>
                      </Space>
                    </Col>
                  </>
                ) : (
                  ''
                )}

                <Col span={24}>
                  <Row gutter={16}>
                    <Col span={24} md={6} className="mb-5">
                      <Form.Item
                        name="status"
                        label="Status"
                        rules={[{ required: true, message: 'This field is required' }]}
                        className="customField"
                      >
                        <Select
                          className="custom-select"
                          suffixIcon={<CaretDownOutlined />}
                          placeholder="Select Status"
                          onChange={handleStatusChange}
                        >
                          <Option value="paid">Paid</Option>
                          <Option value="unpaid">Unpaid</Option>
                          <Option value="draft">Draft</Option>
                          <Option value="partially-paid">Partially Paid</Option>
                          <Option value="cancel">Cancel</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {showPartiallyPaid && (
                      <Col span={24} md={6} className="mb-5">
                        <Form.Item
                          name="partiallyPaidAmount"
                          label="Partially Paid Amount"
                          rules={[{ required: true, message: 'This field is required' }]}
                        >
                          <Input
                            style={{ paddingLeft: '10px' }}
                            prefix="₹"
                            placeholder="Enter amount"
                            onKeyPress={event => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                    )}
                    <Col span={24} md={6} className="mb-5">
                      <Form.Item
                        name="date"
                        label="Invoice date"
                        rules={[{ required: true, message: 'This field is required' }]}
                      >
                        <DatePicker style={{ width: '100%', minWidth: 'unset' }} placeholder="Invoice date" />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={6} className="mb-5">
                      <Form.Item
                        name="dueDate"
                        label="Invoice due date"
                        dependencies={['date']}
                        rules={[
                          { required: true, message: 'This field is required' },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || moment(getFieldValue('date')).isBefore(moment(value))) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Invoice due date can not be less than invoice date!'));
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          style={{ width: '100%', minWidth: 'unset' }}
                          placeholder="Select invoice due date"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={6} className="mb-5">
                      <Form.Item name="poNo" label="PO No. / WO No.">
                        <Input placeholder="Enter PO No. / WO No." />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16} style={{ padding: '10px 0px' }}>
                    {/* <Col span={24} className="mb-5">
                      <p style={{ fontSize: '13px', marginBottom: '6px', marginTop: '5px' }}>Company Logo</p>
                      <label htmlFor="companyImage" className="change-company-profile">
                        <span className="change-img-btn">
                          <FeatherIcon icon="edit" size={'15px'} />
                        </span>
                        {company?.companyImage ? (
                          <img
                            style={{ objectFit: 'scale-down', border: '1px solid #E3E6EF', borderRadius: '4px' }}
                            width="100%"
                            height="98"
                            src={`${process.env.REACT_APP_BACKEND_URL}/uploads/company/companyImage/${company?.companyImage}`}
                            alt={company?.companyName}
                          />
                        ) : (
                          <img
                            style={{ objectFit: 'scale-down', border: '1px solid #E3E6EF', borderRadius: '4px' }}
                            width="100%"
                            height="98"
                            src={`https://ui-avatars.com/api/?name=${company?.companyName}&background=237CFF&color=fff`}
                            alt={company?.companyName}
                          />
                        )}
                      </label>
                      <input
                        type="file"
                        id="companyImage"
                        accept="image/*"
                        onChange={updateCompanyImage}
                        name="companyImage"
                        style={{ display: 'none' }}
                      />
                    </Col> */}
                    {isForeignCustomer ? (
                      <Col span={24} md={6} className="mb-5">
                        <Space align="end" className="input_btn_wrap">
                          <Form.Item
                            name="customer"
                            label="Choose customer"
                            rules={[{ required: true, message: 'This field in required' }]}
                          >
                            <SearchForeignCustomer />
                          </Form.Item>

                          <Tooltip title="Add Customer">
                            <Button
                              type="info"
                              size="default"
                              style={{ width: '100%', border: '1px solid' }}
                              onClick={() => setShowForeignCustomerPopup(true)}
                            >
                              <div className="flex align-center-v justify-content-center">
                                <FeatherIcon icon="plus" size="18px" />
                              </div>
                            </Button>
                          </Tooltip>
                          {/* <Tooltip title="Customer list">
                            <Button
                              type="info"
                              size="default"
                              style={{ paddingTop: '1px !important', width: '100%' }}
                              onClick={() => {
                                window.open('/customers/foreign/list', '_blank');
                              }}
                              target="_blank"
                            >
                              <FontAwesome name="address-book" style={{ fontSize: '18px' }} />
                            </Button>
                          </Tooltip> */}
                        </Space>
                      </Col>
                    ) : (
                      <Col span={24} md={6} className="mb-5 items_NewUi">
                        <Space align="end" className="input_btn_wrap">
                          <Form.Item
                            name="customer"
                            label="Choose customer"
                            rules={[{ required: true, message: 'This field in required' }]}
                          >
                            <SearchCustomer />
                          </Form.Item>

                          <Tooltip title="Add Customer">
                            <Button
                              type="info"
                              size="default"
                              style={{ width: '100%' }}
                              onClick={() => setShowCustomerPopup(true)}
                            >
                              <div className="flex align-center-v justify-content-center">
                                <FeatherIcon icon="plus" size="18px" />
                              </div>
                            </Button>
                          </Tooltip>

                          {/* <Tooltip title="Customer list">
                            <Button
                              type="info"
                              size="default"
                              style={{ paddingTop: '1px !important', width: '100%' }}
                              onClick={() => {
                                window.open('/app/customers/list', '_blank');
                              }}
                              target="_blank"
                            >
                              <FontAwesome name="address-book" style={{ fontSize: '18px' }} />
                            </Button>
                          </Tooltip> */}
                        </Space>
                      </Col>
                    )}
                    {selectedCustomer && selectedCustomer?.alternateAddress?.length > 0 && (
                      <>
                        {/* <Col span={24} md={6} className="mb-10">
                        <Space className="alt-address-wrap">
                            <Form.Item name="displayAddress" label="Address">
                            <Select placeholder="Choose Address" onChange={updateSelectedValue}>
                              <Option value={selectedCustomer?.addressLabel}>{selectedCustomer?.addressLabel}</Option>
                              {selectedCustomer?.alternateAddress?.map(address => (
                                <Option key={address?._id} value={address.addressLabel}>
                                  {address?.addressLabel}
                                </Option>
                                ))}
                            </Select>
                          </Form.Item>

                          <Form.Item label="Selected Address">
                            <Input disabled readOnly value={selectedAddress} />
                          </Form.Item>
                        </Space>
                      </Col> */}
                        <Col span={24} md={6} className="mb-5">
                          <Form.Item
                            // name="displayAddress"
                            name="Billing Address"
                            label="Billing Address"
                            rules={[{ required: true, message: 'This field in required' }]}
                          >
                            <Select
                              className="sDash_fullwidth-select"
                              suffixIcon={<CaretDownOutlined />}
                              placeholder="Select Address"
                              onChange={updateSelectedValue}
                            >
                              <Option value={selectedCustomer?.addressLabel}>{selectedCustomer?.addressLabel}</Option>
                              {selectedCustomer?.alternateAddress?.map(address => (
                                <Option key={address?._id} value={address.addressLabel}>
                                  {address?.addressLabel}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </>
                    )}
                    {/* <Col span={24} md={6} className="mb-5">
                      <Space align="end" className="input_btn_wrap _single">
                        <Form.Item
                          name="bankDetails"
                          label="Bank Details"
                          rules={[{ required: true, message: 'This field in required' }]}
                        >
                          <Select className="sDash_fullwidth-select" placeholder="Select Bank Account">
                            {bankDetails.map((bankDetail, i) => (
                              <Option key={i + 1} value={bankDetail._id}>
                                {bankDetail.bankName} - {bankDetail.accountNo} - {bankDetail.accountHolderName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Tooltip title="Add Bank Details">
                          <Button
                            style={{ width: '100%' }}
                            size="default"
                            type="info"
                            onClick={() => setshowAddBankDetailPopup(true)}
                          >
                            <div className="flex align-center-v justify-content-center">
                              <FeatherIcon icon={'plus'} size={'16px'} />
                            </div>
                          </Button>
                        </Tooltip>
                      </Space>
                    </Col> */}
                    <Col span={24} md={6} className="mb-5">
                      <Form.Item name="shipping Address" label="Shipping Address">
                        <Select
                          className="sDash_fullwidth-select"
                          suffixIcon={<CaretDownOutlined />}
                          placeholder="Select Address"
                        >
                          <Option value="Address 1">Address 1</Option>
                          <Option value="Address 2">Address 2</Option>
                          <Option value="Address 3">Address 3</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24} md={6} className="mb-5">
                      <Form.Item
                        name="bankDetails"
                        label="Choose Bank Details"
                        rules={[{ required: true, message: 'This field in required' }]}
                      >
                        <Select
                          className="sDash_fullwidth-select"
                          suffixIcon={<CaretDownOutlined />}
                          placeholder="Select Bank Account"
                        >
                          {bankDetails.map((bankDetail, i) => (
                            <Option key={i + 1} value={bankDetail._id}>
                              {bankDetail.bankName} - {bankDetail.accountNo} - {bankDetail.accountHolderName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Cards>

            <Cards headless>
              <Row gutter={18} className="items_NewUi">
                <Col xs={24} className="mb-5">
                  <Form.List name="items">
                    {(fields, { add, remove }) => (
                      <>
                        <Row style={{ alignItems: 'center' }} justify="space-between" gutter={5}>
                          <Col>
                            <h4 style={{ color: '#237CFF', fontWeight: '600' }} className="mb-0">
                              Items
                            </h4>
                          </Col>
                          <Col>
                            <Space>
                              <Form.Item>
                                <Button
                                  className="w-100 vertical-border"
                                  size="small"
                                  onClick={() => add()}
                                  style={{ height: '30px', background: 'none', color: '#0070E7', fontWeight: '600' }}
                                >
                                  <div className="flex align-center-v justify-content-center">
                                    <FeatherIcon style={{ marginRight: '2px' }} icon="plus" size="13px" />
                                    <span>Add Item</span>
                                  </div>
                                </Button>
                              </Form.Item>

                              <Form.Item>
                                <Button
                                  onClick={() => setshowItemPopup(true)}
                                  block
                                  icon={
                                    <FeatherIcon
                                      style={{ marginRight: '2px', marginBottom: '-2px' }}
                                      icon="plus"
                                      size="13px"
                                    />
                                  }
                                  style={{ height: '30px', color: '#119D41', border: 'none', fontWeight: '600' }}
                                >
                                  <span>Create New Item</span>
                                </Button>
                              </Form.Item>

                              {/* <Button
                                type="default"
                                style={{ height: '30px' }}
                                onClick={() => setIsGroupItems(!isGroupItems)}
                              >
                                Group Items
                              </Button> */}
                            </Space>
                          </Col>
                        </Row>
                        {/* <Divider style={{ margin: '10px 0 5px' }} /> */}
                        {/* //Heading for all items  */}
                        <Row
                          align="top"
                          gutter={10}
                          className="mb-10 "
                          style={{ background: '#F5F7FF', marginTop: '10px' }}
                        >
                          <Col xs={24} md={8} lg={6}>
                            <Form.Item
                              label={window.innerWidth > 991 ? 'Item Name' : ''}
                              style={{ paddingLeft: '5px' }}
                            />
                          </Col>
                          <Col xs={24} md={8} lg={3}>
                            <Form.Item label={window.innerWidth > 991 ? 'Selling Price' : ''} />
                          </Col>
                          <Col md={8} xs={24} lg={2}>
                            <Form.Item label={window.innerWidth > 991 ? 'Quantity' : ''} />
                          </Col>
                          <Col md={8} xs={24} lg={3}>
                            <Form.Item label={window.innerWidth > 991 ? 'Subtotal' : ''} />
                          </Col>
                          {company?.isGSTRegistered ? (
                            <>
                              <Col md={8} xs={24} lg={2}>
                                <Form.Item label={window.innerWidth > 991 ? 'Tax' : ''} />
                              </Col>
                              {selectedCustomer?.state && selectedCompany?.state === selectedCustomer?.state ? (
                                <>
                                  <Col xs={24} lg={2}>
                                    <Form.Item label={window.innerWidth > 991 ? 'CGST' : ''} />
                                  </Col>
                                  <Col xs={24} lg={2}>
                                    <Form.Item label={window.innerWidth > 991 ? 'SGST' : ''} />
                                  </Col>
                                </>
                              ) : (
                                <Col xs={24} md={8} lg={4}>
                                  <Form.Item label={window.innerWidth > 991 ? 'IGST' : ''} />
                                </Col>
                              )}
                            </>
                          ) : null}
                          <Col md={8} xs={24} lg={3}>
                            <Form.Item label={window.innerWidth > 991 ? 'Total' : ''} />
                          </Col>
                        </Row>
                        {/* //mapping items  */}
                        {fields.map(({ key, name, ...restField }, i) => (
                          <ItemFields
                            index={i}
                            key={key}
                            name={name}
                            restField={restField}
                            remove={remove}
                            form={form}
                          />
                        ))}
                      </>
                    )}
                  </Form.List>
                </Col>

                <Col span={24}>
                  <Divider dashed style={{ margin: '0px 0 15px' }} />
                  <div className="total_display_parent">
                    <div className="total_display_1">
                      {/* <Form.Item
                        name="discount"
                        label="Discount in Rupees"
                        rules={[{ pattern: '^[0-9]*$', message: 'Discount should be digit only!' }]}
                      >
                        <Input
                          style={{ paddingLeft: '10px' }}
                          prefix="₹"
                          placeholder="Discount"
                          onKeyPress={event => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={() => getTotal()}
                        />
                      </Form.Item> */}
                      <div className="d-flex ">
                        <div className="d-flex align-center-v">
                          <span className="text-sm" style={{ width: '140px' }}>
                            Apply Discount (%) Or Direct Off To All items?
                          </span>
                          <div
                            className="d-flex align-center-v"
                            style={{ padding: '2px', border: '2px solid #E9EBF6', borderRadius: '4px' }}
                          >
                            <div style={{ background: '#DEE2F7', borderRadius: '4px' }}>
                              <div className="custom-checkbox-container" style={{ padding: '5px 5px' }}>
                                <input type="checkbox" className="custom-checkbox" />
                                <span className="custom-label">%</span>
                              </div>
                            </div>

                            <Form.Item
                              style={{ border: 'none', width: '100px' }}
                              className="discount_input"
                              name="discount"
                              rules={[{ pattern: '^[0-9]*$', message: 'Discount should be digit only!' }]}
                            >
                              <Input
                                // style={{ paddingLeft: '10px' }}
                                // prefix="₹"
                                placeholder="Discount"
                                onKeyPress={event => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={() => getTotal()}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="total_display2">
                      <ul>
                        <li>
                          <span>Discount:</span>{' '}
                          {subTotalAmount.toLocaleString('en-IN', {
                            style: 'currency',
                            currency: selectedCurrency ? selectedCurrency : 'INR',
                          })}
                        </li>
                        <li>
                          <span>Subtotal:</span>{' '}
                          {subTotalAmount.toLocaleString('en-IN', {
                            style: 'currency',
                            currency: selectedCurrency ? selectedCurrency : 'INR',
                          })}
                        </li>
                        {company && company.isGSTRegistered ? (
                          <li>
                            <span>Tax:</span>{' '}
                            {totalTaxAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                          </li>
                        ) : null}
                        <li>
                          <span>Grand Total:</span>{' '}
                          {grandTotal.toLocaleString('en-IN', {
                            style: 'currency',
                            currency: selectedCurrency ? selectedCurrency : 'INR',
                          })}
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Cards>

            <Row gutter={18} className="custom_btn items_NewUi">
              <Col span={24} md={12} lg={8}>
                <Cards headless>
                  <Row gutter={0}>
                    <Col span={24}>
                      <Space align="end" className="input_btn_wrap _single">
                        <Form.Item name="termsAndConditions" label="Terms And Conditions">
                          <Select
                            allowClear
                            showSearch
                            suffixIcon={<CaretDownOutlined />}
                            placeholder="Select Options"
                            onChange={handleTermsChange}
                          >
                            {termsConditions?.map(t => (
                              <Option key={t._id} value={t._id}>
                                {t.title}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Tooltip title="Edit terms and condition">
                          <Button
                            size="default"
                            transparented="true"
                            style={{
                              background: '#E9FEF0',
                              color: '#47B66C',
                              border: '1px solid #47B66C',
                            }}
                            onClick={() => setshowTermsAndConditionsPopup(true)}
                          >
                            <EditOutlined />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Add terms and condition">
                          <Button
                            style={{
                              background: '#F2F8FF',
                              color: '#3C92ED',
                              border: '1px solid #3C92ED',
                            }}
                            transparented="true"
                            size="default"
                            onClick={() => setshowTermsAndConditionsPopup(true)}
                          >
                            <div className="flex align-center-v justify-content-center">
                              <FeatherIcon icon="plus" size="16px" />
                            </div>
                          </Button>
                        </Tooltip>
                      </Space>
                    </Col>
                    {/* <Col span={24}>
                      <TextEditor marginTop="10px" editorData={termsData} setDetails={setTermsContent} />
                    </Col> */}
                  </Row>
                </Cards>
              </Col>

              <Col span={24} md={12} lg={8}>
                <Cards headless>
                  <Row gutter={0}>
                    <Col span={24}>
                      <Space align="end" className="input_btn_wrap _single">
                        <Form.Item name="note" label="Note">
                          <Select
                            allowClear
                            showSearch
                            suffixIcon={<CaretDownOutlined />}
                            placeholder="Select Options"
                            onChange={handleNoteChange}
                          >
                            {notes.map(note => (
                              <Option key={note._id} value={note._id}>
                                {note.title}
                                {/* - {note.note} */}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Tooltip title="Edit Notes">
                          <Button
                            size="default"
                            transparented="true"
                            style={{
                              width: '100%',
                              background: '#E9FEF0',
                              color: '#47B66C',
                              border: '1px solid #47B66C',
                            }}
                            onClick={() => setshowNotePopup(true)}
                          >
                            <EditOutlined />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Add Notes">
                          <Button
                            style={{
                              background: '#F2F8FF',
                              color: '#3C92ED',
                              border: '1px solid #3C92ED',
                            }}
                            size="default"
                            transparented="true"
                            onClick={() => setshowNotePopup(true)}
                          >
                            <div className="flex align-center-v justify-content-center">
                              <FeatherIcon icon={'plus'} size={'16px'} />
                            </div>
                          </Button>
                        </Tooltip>
                      </Space>
                    </Col>
                    {/* <Col span={24}>
                      <TextEditor marginTop="10px" editorData={noteData} setDetails={setNotesContent} />
                    </Col> */}
                  </Row>
                </Cards>
              </Col>

              <Col span={24} md={12} lg={8}>
                <Cards headless>
                  {/* <ImageUploadComponent signature={signature} setSignature={setSignature} company={company} /> */}
                  {/* <Col span={24}>
                    <h4 style={{ color: '#237CFF', fontWeight: '600' }} className="mb-0">
                      REMARK
                    </h4>
                  </Col> */}
                  <Col span={24}>
                    <Form.Item name="remark" label="Remarks">
                      <Input placeholder="Add Remarks" autosize="true" />
                      {/* <TextEditor marginTop="10px" /> */}
                    </Form.Item>
                  </Col>
                </Cards>
              </Col>

              <Col lg={12} xs={24}>
                <Form.Item name="generateStatus">
                  <Input type="hidden" />
                </Form.Item>
              </Col>
            </Row>

            <Cards headless>
              <Row gutter={18}>
                <Col span={24}>
                  <h4
                    style={{
                      color: '#237CFF',
                      fontWeight: '600',
                      marginBottom: '15px',
                      paddingBottom: '5px',
                      borderBottom: '1px solid #f1f1f1',
                    }}
                  >
                    TDS
                  </h4>
                </Col>
                <Col span={24} md={5}>
                  <Form.Item name="isTdsDeducted" valuePropName="checked">
                    <div className="custom-checkbox-container" style={{ padding: '5px 5px' }}>
                      <input type="checkbox" className="custom-checkbox" />
                      <span className="custom-label">Is TDS deduct on invoice ?</span>
                    </div>
                  </Form.Item>
                </Col>
                {isTdsDeductedWatch && (
                  <>
                    <Col span={24} md={4}>
                      <Form.Item name="tdsPercentage" label="Add Percentage">
                        <Input
                          suffix="%"
                          max={100}
                          maxLength={3}
                          min={0}
                          onKeyPress={event => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          onChange={e => handleTdsChange(e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={4}>
                      <Form.Item name="tdsAmount" label="TDS Amount">
                        <Input
                          onKeyPress={event => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} md={4}>
                      <Form.Item name="clientPayableAmount" label="Client Payable Amount">
                        <Input
                          onKeyPress={event => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Divider />

                    <Col span={24} md={5}>
                      <Form.Item name="isTdsPaid" valuePropName="checked">
                        <div className="custom-checkbox-container" style={{ padding: '5px 5px' }}>
                          <input type="checkbox" className="custom-checkbox" />
                          <span className="custom-label">Is TDS paid</span>
                        </div>
                      </Form.Item>
                    </Col>
                    {isTdsPaidWatch && (
                      <>
                        <Col span={24} md={4}>
                          <Form.Item name="tdsPaidAmount" label="Paid Amount">
                            <Input
                              onKeyPress={event => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={e => handleTdsPaid(e.target.value)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24} md={4}>
                          <Form.Item name="tdsDueAmount" label="Due Amount">
                            <Input
                              onKeyPress={event => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              readOnly
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </>
                )}
              </Row>
            </Cards>

            {/* <Cards headless>
            <Row gutter={15}>
              <Col span={12}>
                <h2 className='mb-0'>{invoiceNumber ? `# ${invoiceNumber}` : '- ---'} </h2>
              </Col>
              {
                (company && !company.isGSTRegistered) ?
              <Col span={12}>
                <h4 className='text-right'>
                  Foreign Customer {" "}
                  <Switch onChange={handleForeignCustomerToggle} />
                </h4>
              </Col> : ''
              }
              <Divider style={{marginTop: '10px'}} />
              <Col xs={24} md={4} lg={4} className="mb-10">
                <label htmlFor="companyImage" className="change-company-profile">
                  <span className="change-img-btn">
                    <FeatherIcon icon="edit" size={'15px'} />
                  </span>
                  {company?.companyImage ? (
                    <img
                      style={{ objectFit: 'scale-down', border: '1px solid #E3E6EF', borderRadius: '4px' }}
                      width="100%"
                      height="85"
                      src={`${process.env.REACT_APP_BACKEND_URL}/uploads/company/companyImage/${company?.companyImage}`}
                      alt={company?.companyName}
                    />
                  ) : (
                    <img
                      style={{ objectFit: 'scale-down', border: '1px solid #E3E6EF', borderRadius: '4px' }}
                      width="100%"
                      height="85"
                      src={`https://ui-avatars.com/api/?name=${company?.companyName}&background=237CFF&color=fff`}
                      alt={company?.companyName}
                    />
                  )}
                </label>
                <input
                  type="file"
                  id="companyImage"
                  accept="image/*"
                  onChange={updateCompanyImage}
                  name="companyImage"
                  style={{ display: 'none' }}
                />
              </Col>
              <Col xs={24} md={1} lg={2}></Col>
              <Col xs={24} md={6} lg={6} className="mb-10">
                <Form.Item name="status" label="Status">
                  <Select placeholder="Select Status" onChange={handleStatusChange}>
                    <Option value="paid">Paid</Option>
                    <Option value="unpaid">Unpaid</Option>
                    <Option value="draft">Draft</Option>
                    <Option value="partially-paid">Partially Paid</Option>
                    <Option value="cancel">Cancel</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={6} lg={6} className="mb-10">
                <Form.Item
                  name="date"
                  label="Invoice date"
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <DatePicker style={{ width: '100%', minWidth: 'unset' }} placeholder="Invoice date" />
                </Form.Item>
              </Col>
              <Col xs={24} md={6} lg={6} className="mb-10">
                <Form.Item
                  name="dueDate"
                  label="Invoice due date"
                  dependencies={['date']}
                  rules={[
                    { required: true, message: 'This field is required' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || moment(getFieldValue('date')).isBefore(moment(value))) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Invoice due date can not be less than invoice date!'));
                      },
                    }),
                  ]}
                >
                  <DatePicker style={{ width: '100%', minWidth: 'unset' }} placeholder="Select invoice due date" />
                </Form.Item>
              </Col>
              {
                isForeignCustomer ? 
                <Col span={24} md={12} className='mb-10'>
                  <Row gutter={10}>
                  <Col xs={16} sm={18}>
                      <Form.Item
                        name="customer"
                        label="Choose customer"
                        rules={[{ required: true, message: 'This field in required' }]}
                      >
                        <SearchForeignCustomer />
                      </Form.Item>
                    </Col>
                    <Col xs={3} lg={2} style={{ marginTop: '40px' }}>
                      <Tooltip title="Add Customer">
                        <Button
                          type="info"
                          size="default"
                          style={{ width: '100%' }}
                          onClick={() => setShowForeignCustomerPopup(true)}
                        >
                          <div className="flex align-center-v justify-content-center">
                            <FeatherIcon icon={'plus'} size={'18px'} />
                          </div>
                        </Button>
                      </Tooltip>
                    </Col>
                    <Col xs={3} style={{ marginTop: '40px' }}>
                      <Tooltip title="Customer list">
                        <Button
                          type="info"
                          size="default"
                          style={{ paddingTop: '1px !important', marginLeft: '5px', width: '100%' }}
                          onClick={() => {
                            window.open('/customers/foreign/list', '_blank');
                          }}
                          target="_blank"
                        >
                          <FontAwesome name="address-book" style={{ fontSize: '20px' }} />
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
                :
                <Col xs={24} md={12} className="mb-10">
                  <Row gutter={10}>
                    <Col xs={16} sm={18}>
                      <Form.Item
                        name="customer"
                        label="Choose customer"
                        rules={[{ required: true, message: 'This field in required' }]}
                      >
                        <SearchCustomer />
                      </Form.Item>
                    </Col>
                    <Col xs={3} lg={2} style={{ marginTop: '40px' }}>
                      <Tooltip title="Add Customer">
                        <Button
                          type="info"
                          size="default"
                          style={{ width: '100%' }}
                          onClick={() => setShowCustomerPopup(true)}
                        >
                          <div className="flex align-center-v justify-content-center">
                            <FeatherIcon icon={'plus'} size={'18px'} />
                          </div>
                        </Button>
                      </Tooltip>
                    </Col>
                    <Col xs={3} style={{ marginTop: '40px' }}>
                      <Tooltip title="Customer list">
                        <Button
                          type="info"
                          size="default"
                          style={{ paddingTop: '1px !important', marginLeft: '5px', width: '100%' }}
                          onClick={() => {
                            window.open('/customers/list', '_blank');
                          }}
                          target="_blank"
                        >
                          <FontAwesome name="address-book" style={{ fontSize: '20px' }} />
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
              }
              <Col xs={24} md={6} className="mb-10">
                <Form.Item 
                  name='poNo'
                  label='PO NO. / WO NO.'
                >
                  <Input placeholder='Enter PO NO. / WO NO.'/>
                </Form.Item>
              </Col>
              <Col xs={24} md={6} className="mb-10">
                <Row gutter={10} align="middle">
                  <Col xs={20}>
                    <Form.Item
                      name="bankDetails"
                      label="Bank Details"
                      rules={[{ required: true, message: 'This field in required' }]}
                    >
                      <Select className="sDash_fullwidth-select" placeholder="Select Bank Account">
                        {bankDetails.map((bankDetail, i) => (
                          <Option key={i + 1} value={bankDetail._id}>
                            {bankDetail.bankName} - {bankDetail.accountNo} - {bankDetail.accountHolderName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={4} style={{ marginTop: '37px' }}>
                    <Tooltip title="Add Bank Details">
                      <Button size="default" type="info" onClick={() => setshowAddBankDetailPopup(true)}>
                        <div className="flex align-center-v justify-content-center">
                          <FeatherIcon icon={'plus'} size={'16px'} />
                        </div>
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
              </Col>

              {
                (selectedCustomer && (selectedCustomer?.alternateAddress?.length > 0)) &&
                <>
                  <Col span={24} md={6} className='mb-10'>
                    <Form.Item name="displayAddress" label="Address">
                        <Select placeholder="Choose Address" onChange={updateSelectedValue}>
                          <Option value={selectedCustomer?.addressLabel}>{selectedCustomer?.addressLabel}</Option>
                          {
                            selectedCustomer?.alternateAddress?.map(address => (
                              <Option key={address?._id} value={address.addressLabel}>{address?.addressLabel}</Option>
                            ))
                          }
                        </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={18} className='mb-10'>
                    <Form.Item label="Selected Address">
                      <Input disabled readOnly value={selectedAddress} />
                    </Form.Item>
                  </Col>
                </>
              }

              <Divider
                style={{ margin: '0px 0px 20px', backgroundColor: '#fff', borderTop: '1px dashed #26269642' }}
              />

              <Col xs={24} className="mb-10">
                <Form.List name="items">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, i) => (
                        <ItemFields
                          index={i}
                          key={key}
                          name={name}
                          restField={restField}
                          remove={remove}
                          form={form}
                        />
                      ))}
                      <Row justify="end" gutter={5}>
                        <Col>
                          <Form.Item>
                            <Button
                              type="info"
                              className="w-100"
                              size="default"
                              onClick={() => setshowItemPopup(true)}
                            >
                              <div className="flex align-center-v justify-content-center">
                                <FeatherIcon icon={'plus'} size={'16px'} />
                                <span>Add a new item</span>
                              </div>
                            </Button>
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                              Add More Items
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                </Form.List>
              </Col>
              <Divider style={{ backgroundColor: '#fff', borderTop: '1px dashed #26269642', margin: '10px 0' }} /> */}

            {/* <Col xs={24} md={showPartiallyPaid ? 6 : 12} className="mb-10">
                <Form.Item
                  name="discount"
                  label="Discount in Rupees"
                  rules={[
                    { pattern: '^[0-9]*$', message: 'Discount should be digit only!' },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const items = getFieldValue('items');
                        let amount = 0;
                        items.map(item => {
                          if(item.displayTotal && value) {
                            amount += Number(item.displayTotal);
                            setGrandTotal(amount - value);
                          }
                        });
                        if (items) {
                          const subtotal = items.reduce((a, b) => {
                            if (typeof b.total !== 'undefined') {
                              return a + parseFloat(b.total);
                            }
                            return 0;
                          }, 0);
                          if (!value || subtotal > value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Discount must be less than subtotal of items.'));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    style={{ paddingLeft: '10px' }}
                    prefix="₹"
                    placeholder="Discount"
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col> */}
            {/* {
                showPartiallyPaid &&
                <Col xs={24} md={6} className="mb-10">
                  <Form.Item
                    name="partiallyPaidAmount"
                    label="Partially Paid Amount"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <Input 
                    style={{ paddingLeft: '10px' }}
                      prefix="₹"
                      placeholder='Enter amount'
                      onKeyPress={event => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              }
              <Col xs={24} md={showPartiallyPaid ? 12 : 24} style={{ textAlign: 'end' }} className="mb-10">
                <div>
                  <h3 style={{ marginTop: '25px' }}>
                    <span style={{ display: 'inline-block', fontWeight: '700', width: '100px', marginRight: '20px' }}>
                      Total:
                    </span>
                    {grandTotal.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                  </h3>
                  {
                    (company && company.isGSTRegistered) ?
                    <h5>
                      <span style={{ display: 'inline-block', fontWeight: '700', width: '100px', marginRight: '20px' }}>
                        Total Tax:
                      </span>
                      {totalTaxAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                    </h5>
                    :  null

                  }
                </div>
              </Col>
              <Divider style={{ backgroundColor: '#fff', borderTop: '1px dashed #26269642', margin: '10px 0' }} />

              <Col xs={24} md={12} lg={10} className="mb-10">
                <Row gutter={10}>
                  <Col xs={20}>
                    <Form.Item
                      name="termsAndConditions"
                      label="Terms And Conditions"
                      // rules={[{ required: true, message: 'This field in required' }]}
                    >
                      <Select showSearch placeholder="Select terms and conditions" onChange={handleTermsChange}>
                        {termsConditions?.map(t => (
                          <Option key={t._id} value={t._id}>
                            {t.title} */}
            {/* - {t.termAndCondition} */}
            {/* </Option>
                        ))}
                      </Select>
                    </Form.Item> */}
            {/* </Col>
                  <Col xs={4} lg={4} style={{ marginTop: '37px' }}>
                    <Tooltip title="Add terms and condition">
                      <Button
                        transparented="true"
                        type="info"
                        size="default"
                        style={{ width: '100%' }}
                        onClick={() => setshowTermsAndConditionsPopup(true)}
                      >
                        <div className="flex align-center-v justify-content-center">
                          <FeatherIcon icon={'plus'} size={'16px'} />
                        </div>
                      </Button>
                    </Tooltip>
                  </Col> */}
            {/* <Col xs={24}>
                    <TextEditor editorData={termsData} setDetails={setTermsContent} />
                  </Col>
                </Row>
              </Col> */}
            {/* <Col xs={24} md={12} lg={10} className="mb-10">
                <Row gutter={10} align="middle">
                  <Col xs={20}>
                    <Form.Item name="note" label="Note">
                      <Select showSearch placeholder="Select notes" onChange={handleNoteChange}>
                        {notes.map(note => (
                          <Option key={note._id} value={note._id}>
                            {note.title} */}
            {/* - {note.note} */}
            {/* </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={4} lg={4} style={{ marginTop: '35px' }}>
                    <Tooltip title="Add Notes">
                      <Button
                        size="default"
                        transparented="true"
                        type="info"
                        style={{ width: '100%' }}
                        onClick={() => setshowNotePopup(true)}
                      >
                        <div className="flex align-center-v justify-content-center">
                          <FeatherIcon icon={'plus'} size={'16px'} />
                        </div>
                      </Button>
                    </Tooltip>
                  </Col>
                  <Col xs={24}>
                    <TextEditor editorData={noteData} setDetails={setNotesContent} /> */}
            {/* </Col>
                </Row>
              </Col>
              <Col xs={24} md={12} lg={4} className="mb-10">
                <ImageUploadComponent signature={signature} setSignature={setSignature} company={company}/>
              </Col>

              <Col lg={12} xs={24}>
                <Form.Item name="generateStatus">
                  <Input type="hidden" />
                </Form.Item>
              </Col>
            </Row>
          </Cards> */}

            {/* <Row gutter={10}>
                <Col xs={24} lg={24}>
                </Col>
              </Row> */}

            {/* <Row gutter={10}>
                <Col md={24} lg={8}>
                  <Button
                    className="w-100"
                    htmlType="button"
                    type="primary"
                    loading={draftLoading}
                    size={'large'}
                    onClick={() => handleStatusSubmit('save')}
                  >
                    Save
                  </Button>
                </Col>
                <Col md={24} lg={8}>
                  <Button
                    className="btn-signin w-100"
                    htmlType="button"
                    type="primary"
                    size={'large'}
                    onClick={() => handleStatusSubmit('generate')}
                    loading={generateLoading}
                  >
                    Save & Print
                  </Button>
                </Col>
                <Col md={24} lg={8}>
                  <Button
                    className="btn-signin w-100"
                    htmlType="button"
                    type="primary"
                    size={'large'}
                    onClick={() => handleStatusSubmit('share')}
                    loading={shareLoading}
                  >
                    Save & Send
                  </Button>
                </Col>
              </Row> */}
          </Form>
        </BasicFormWrapper>
      </Main>
      <AddCustomerPopup show={showCustomerPopup} handleCancel={onCancelCustomerPopup} onSubmit={setCustomer} />
      <AddForeignCustomerPopup
        show={showForeignCustomerPopup}
        handleCancel={onCancelForeignCustomerPopup}
        onSubmit={setForeignCustomer}
      />
      <TermsAndConditionsPopup
        show={showTermsAndConditionsPopup}
        handleCancel={onCancelTermsConditionsPopup}
        onSubmit={setTermsCondition}
      />
      <AddItemPopup show={showItemPopup} handleCancel={onCancelItemPopup} onSubmit={() => setshowItemPopup(false)} />
      <AddBankDetailsPopUp
        show={showAddBankDetailPopup}
        handleCancel={onCancelBankDetailPopup}
        onSubmit={setBankDetail}
      />
      <AddNotePopup show={showNotePopup} handleCancel={onCancelNotesPopup} onSubmit={setNote} />
    </>
  );
};
const ImageUploadComponent = ({ signature, setSignature, company }) => {
  // const [signature, setSignature] = useState({});

  const handleSignUpload = file => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function() {
      file.url = reader.result;
    };
    setSignature([file]);
    return false;
  };

  return (
    <>
      <Form.Item
        name="signature"
        label="Signature"
        style={{ marginBottom: '6px' }}
        // rules={[
        //   () => ({
        //     validator(_, value) {
        //       if (company?.signature || value) {
        //         return Promise.resolve();
        //       }
        //       return Promise.reject(new Error('Please provide signature!'));
        //     },
        //   }),
        // ]}
      >
        <Upload
          multiple={false}
          listType="picture"
          className="sDash_upload-basic"
          beforeUpload={handleSignUpload}
          accept="image/*"
          fileList={signature}
          onRemove={() => {
            setSignature([]);
          }}
        >
          <span className="sDash_upload-text"> {signature.length === 0 ? 'Select File' : signature[0]?.name}</span>
          <Button
            htmlType="button"
            size="small"
            style={{ height: '30px', lineHeight: '30px', backgroundColor: '#EEF7FF', color: '#5598E8' }}
          >
            <span style={{ display: 'inline-block', marginBottom: '-2px' }}>Browse</span>
          </Button>
        </Upload>
      </Form.Item>
      {company?.signature ? (
        <Card headless="true" style={{ width: '100%', border: '1px solid #f1f1f1', marginTop: '0px' }}>
          <figure className="mb-10" style={{ width: 'auto', height: '100px' }}>
            <img
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              src={`${process.env.REACT_APP_BACKEND_URL}/uploads/company/signature/${company?.signature}`}
              alt=""
            />
          </figure>
        </Card>
      ) : (
        <Card headless="true" style={{ width: '100%', border: '1px solid #f1f1f1', marginTop: '0px' }}>
          <Empty className="mb-0" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Card>
      )}
      {/* <div style={{ border: '1px solid #f0f0f0', borderRadius: '10px' }}>
        <Cards title="Uploaded Signature" size="default">
          {company?.signature ? (
            <figure className="mb-0" style={{ width: '100%' }}>
              <img
                style={{ width: '100%', maxHeight: '400px', objectFit: 'cover', borderRadius: '6px' }}
                src={`${process.env.REACT_APP_BACKEND_URL}/uploads/company/signature/${company?.signature}`}
                alt=""
              />
            </figure>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Cards>
      </div> */}
    </>
  );
};

AddInvoices.propTypes = {
  match: propTypes.object,
};

export default AddInvoices;
