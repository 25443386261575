import React, { Suspense, useEffect } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from './dashboard';
import Company from './company';
import CompanyDetails from './companyDetails.js';
import Customers from './customers';
import Purchase from './purchase';
import Vendors from './vendors';
import Items from './items';
import Invoice from './invoice';
import Proforma from './proforma';
import CreditNote from './creaditNote';
import PurchaseOrder from './purchaseOrder';
import Task from './task';
import withAdminLayout from '../../layout/withAdminLayout';
import { fetchUser } from '../../redux/custom/actionCreator';
import AddCompany from './profile/AddCompany';
import AllCompanies from './profile/AllCompanies.js';
import pricingPlan from './profile/PricingPlan';
import AdminAccount from './profile/AdminAccount';
import GstIn from './GstIn';
import Support from './support/Support';
import LandingPage2 from './LandingPage2';

const Admin = () => {
  const dispatch = useDispatch();
  const {isStepsCompleted, user }= useSelector(state => ({
    isStepsCompleted: state.custom.stepsCompleted,
    user: state.custom.user
  }))
  const { path } = useRouteMatch();
  useEffect(() => {
    dispatch(fetchUser());
  }, []);
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        {
          !user ?  
          <div className="spin">
            <Spin />
          </div>
          :
          <>
          <Route path={path} component={isStepsCompleted  ? Dashboard :  LandingPage2} exact />
          <Route path={`${path === '/' ? '' : path}/company-update`} component={CompanyDetails} />
          <Route path={`${path === '/' ? '' : path}/company`} component={Company} />
          <Route path={`${path === '/' ? '' : path}/customers`} component={Customers} />
          <Route path={`${path === '/' ? '' : path}/items`} component={Items} />
          <Route path={`${path === '/' ? '' : path}/invoices`} component={Invoice} />
          <Route path={`${path === '/' ? '' : path}/quotation`} component={Proforma} />
          <Route path={`${path === '/' ? '' : path}/purchase-order`} component={PurchaseOrder} />
          <Route path={`${path === '/' ? '' : path}/credit-note`} component={CreditNote} />
          <Route path={`${path === '/' ? '' : path}/task`} component={Task} />
          <Route path={`${path === '/' ? '' : path}/profile`} component={AdminAccount} />
          <Route path={`${path === '/' ? '' : path}/add-company`} component={AddCompany} />
          <Route path={`${path === '/' ? '' : path}/all-company`} component={AllCompanies} />
          <Route path={`${path === '/' ? '' : path}/pricing-plan`} component={pricingPlan} />
          <Route path={`${path === '/' ? '' : path}/gst-in`} component={GstIn}/> 
          <Route path={`${path === '/' ? '' : path}/purchase`} component={Purchase}/> 
          <Route path={`${path === '/' ? '' : path}/vendors`} component={Vendors}/> 
          
          </>

        }
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
