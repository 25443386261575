import React, { lazy } from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import NewUI2 from './invoice/NewUI2.js';

const InvoiceList = lazy(() => import('./invoice/AllInvoices'));
const AddInvoice = lazy(() => import('./invoice/AddInvoices'));
const NewUi = lazy(() => import('./invoice/NewUi'));
const EditInvoice = lazy(() => import('./invoice/EditInvoice'));
const RemoveInvoice = lazy(() => import('./invoice/DeletedInvoices.js'))

const AddRecurringPayment = lazy(() => import('./recurringPayment/AddRecurringPayment.js'))
const AllRecurringPayments = lazy(() => import('./recurringPayment/AllRecurringPayments.js'))
const EditRecurringPayment = lazy(() => import('./recurringPayment/EditRecurringPayment.js'))

function Invoice() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path === '/' ? '' : path}/list`} component={InvoiceList} />
      {/* <Route path={`${path === '/' ? '' : path}/test`} component={NewUI2} /> */}
      <Route path={`${path === '/' ? '' : path}/add`} component={AddInvoice} />
      <Route path={`${path === '/' ? '' : path}/deleted`} component={RemoveInvoice} />
      <Route path={`${path === '/' ? '' : path}/recurring-payments/add`} component={AddRecurringPayment} />
      <Route path={`${path === '/' ? '' : path}/recurring-payments/list`} component={AllRecurringPayments} />
      <Route path={`${path === '/' ? '' : path}/recurring-payments/:id/edit`} component={EditRecurringPayment} />
      {/* <Route path={`${path === '/' ? '' : path}/new`} component={NewUi} /> */}
      <Route path={`${path === '/' ? '' : path}/:id/edit`} component={EditInvoice} />
    </Switch>
  );
}

export default Invoice;
