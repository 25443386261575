import { Button, Col, Row } from 'antd'
// import Title from 'antd/lib/skeleton/Title'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import DataService from '../../config/dataService/dataService'
import { fetchUser } from '../../redux/custom/actionCreator'
import {LockOutlined, CheckCircleOutlined, RightOutlined} from '@ant-design/icons'

 class LandingPage2 extends Component {
    constructor (props) {
        super(props)
        this.state ={
            next: false
        }
        this.handleSkip = this.handleSkip.bind(this)
    }

    async handleSkip(){
        try{
            await DataService.skipStep()
            this.props.fetchUserData()
        }catch(error){
            console.log(error)
        }
    }
  render() {
    const {user} = this.props
    return (
        <>
        {
            user && (!this.state.next &&  (!user.isCompanyEdited && !user.isProductAdded && !user.isCustomerAdded  && !user.isBankDetailsAdded))?
            <>
            <section className="landing-page">
                <Row
                    align='middle'
                    justify='center'
                >
                    <Col span={24}>
                       <div className='landing-page-screen'>
                            <img src="/landing-page-empty.png" alt="" />
                            <div className='_landing-content'>
                                <h2>Welcome on One Invoice, lets get started</h2>
                                <p>
                                    Telling us a bit about yourself to get the best experience, this will take a minute or two.
                                </p>
                                <Button onClick={() => this.setState({next:true})} size='large' type="primary">GET STARTED</Button>
                            </div>
                       </div>
                    </Col>
                </Row>
            </section>
        </>
            :
            <>
            {
                user && !(user.isCompanyEdited && user.isProductAdded && user.isCustomerAdded  && user.isBankDetailsAdded) &&
    
                <>
                    <section className='starter-screen'>
                        <Row justify='center'>
                            <Col span={22} md={18}>
                                <h2>
                                🚀 Let's get you set up with <span>One Invoice</span>
                                </h2>
                            </Col>
                            <Col span={22} md={18}>
                                {
                                    !user.isCompanyEdited ? 
                                    <NavLink to={'/company/settings/account'}>
                                        <div className="starter-select-box">
                                            <div className="count">
                                                <span>1</span>
                                            </div>
                                            <div className="_content">
                                                <div>
                                                    <p>Edit Your Company Information</p>
                                                    <span>Fill in your information to complete your account</span>
                                                </div>
                                                <div className="btn-wrap">
                                                    <button>Edit Company</button>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                    :
                                    <div className="starter-select-box _complete">
                                            <div className="count">
                                                <span>
                                                <CheckCircleOutlined />
                                                </span>
                                            </div>
                                            <div className="_content">
                                                <div>
                                                    <p>Edit Your Company Information</p>
                                                    <span>Fill in your information to complete your account</span>
                                                </div>
                                                <div className="btn-wrap">
                                                    <button style={{backgroundColor: '#aaa', pointerEvents: 'none'}} disabled>Edit Company</button>
                                                </div>
                                            </div>
                                    </div>   
                                }
                            </Col>
                            <Col span={22} md={18}>
                                {
                                    !user.isBankDetailsAdded ?
                                    <NavLink className={user.isCompanyEdited ? '' : '_lock-link'} to={'/company/settings/bank-details/add'}>
                                        <div className="starter-select-box">
                                            {
                                                user.isCompanyEdited ?
                                                <div className="count">
                                                    <span>2</span>
                                                </div>:
                                                <div className="lock">
                                                    <span>
                                                    <LockOutlined />
                                                    </span>
                                                </div>

                                            }
                                            <div className="_content">
                                                <div>
                                                    <p>Add Your Bank Details</p>
                                                    <span>Fill in your information to complete your account</span>
                                                </div>
                                                <div className="btn-wrap">
                                                    <button className='s-btn'>Add Bank Details</button>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                    :
                                    <div className="starter-select-box _complete">
                                            <div className="count">
                                                <span><CheckCircleOutlined /></span>
                                            </div>
                                            <div className="_content">
                                                <div>
                                                    <p>Add Your Bank Details</p>
                                                    <span>Fill in your information to complete your account</span>
                                                </div>
                                                <div className="btn-wrap">
                                                    <button style={{backgroundColor: '#aaa', pointerEvents: 'none'}}>Add Bank Details</button>
                                                </div>
                                            </div>
                                    </div>  
                                }
                            </Col>
                            <Col span={22} md={18}>
                                {
                                    !user.isProductAdded ?
                                    <div>
                                        <div className={`starter-select-box ${user.isBankDetailsAdded ? '' : '_lock-link'}`}>
                                            {
                                            user.isBankDetailsAdded ?
                                            <div className="count">
                                                <span>3</span>
                                            </div>:
                                            <div className="lock">
                                                <span>
                                                <LockOutlined />
                                                </span>
                                            </div>
                                            }
                                            <div className="_content">
                                                <div>
                                                    <p>Add Item</p>
                                                    <span>Fill in your information to complete your account</span>
                                                </div>
                                                <div className="btn-wrap">
                                                <NavLink to={'/items/add'}>
                                                    <button className='s-btn'>Add Item</button>
                                                    </NavLink>
                                                    <button onClick={this.handleSkip} style={{backgroundColor: '#FFF', color: '#aaa'}}>Skip <RightOutlined color='#aaa' style={{fontSize: '12px'}} /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="starter-select-box _complete">
                                            <div className="count">
                                                <span><CheckCircleOutlined /></span>
                                            </div>
                                            <div className="_content">
                                                <div>
                                                    <p>Add Item</p>
                                                    <span>Fill in your information to complete your account</span>
                                                </div>
                                                <div className="btn-wrap">
                                                    <button style={{backgroundColor: '#aaa', pointerEvents: 'none'}}>Add Item</button>
                                                </div>
                                            </div>
                                    </div> 
                                }
                            </Col>
                            <Col span={22} md={18}>
                                {
                                    !user.isCustomerAdded ?
                                        <div className={`starter-select-box ${user.isProductAdded ? '' : '_lock-link'}`}>
                                            {
                                                user.isProductAdded ?
                                                <div className="count">
                                                    <span>4</span>
                                                </div>: 
                                                <div className="lock">
                                                    <span><LockOutlined /></span>
                                                </div> 
                                            }
                                            <div className="_content">
                                                <div>
                                                    <p>Add Customer</p>
                                                    <span>Fill in your information to complete your account</span>
                                                </div>
                                                <div className="btn-wrap">
                                                    <NavLink to={'/customers/add'}>
                                                        <button className='s-btn'>Add Customer</button>
                                                    </NavLink>
                                                    <button onClick={this.handleSkip} style={{backgroundColor: '#FFF', color: '#aaa'}}>Skip <RightOutlined color='#aaa' style={{fontSize: '12px'}} /></button>
                                                </div>
                                            </div>
                                        </div>
                                    :
                                    <div className="starter-select-box _complete">
                                            <div className="count">
                                                <span>
                                                <CheckCircleOutlined />
                                                </span>
                                            </div>
                                            <div className="_content">
                                                <div>
                                                    <p>Add Customer</p>
                                                    <span>Fill in your information to complete your account</span>
                                                </div>
                                                <div className="btn-wrap">
                                                    <button style={{backgroundColor: '#aaa', pointerEvents: 'none'}}>Add Customer</button>
                                                </div>
                                            </div>
                                    </div> 
                                }
                            </Col>
                        </Row>
                    </section>
                    
                </>  
            }
            </>
        }

        
        </>
    )
  }
}

const mapStateToProps = state => {

    const {user, selectedCompany} = state.custom;

    if(selectedCompany?.companyName === `${user?.email}'s company`){
        console.log(window.location.href)
        window.location = `${window.location.href}company-update`
    }

    return{
        company: state.custom.selectedCompany,
        user: state.custom.user
    }

}

const mapDispatchToProps = (dispatch) => {
return {
    fetchUserData: () => dispatch(fetchUser())
}
}
export default connect(mapStateToProps, mapDispatchToProps)(LandingPage2)
