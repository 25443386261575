import React, { lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const VendorsList = lazy(() => import('./vendors/VendorsList'));
const AddVendor = lazy(() => import('./vendors/AddVendor'));
const EditVendor = lazy(() => import('./vendors/EditVendor'));

const Vendors = () => {
    const { path } = useRouteMatch();
  return (
    <Switch>
        <Route exact path={`${path === '/' ? '' : path}/list`} component={VendorsList} />
        <Route exact path={`${path === '/' ? '' : path}/add`} component={AddVendor} />
        <Route exact path={`${path === '/' ? '' : path}/:id/edit`} component={EditVendor} />
    </Switch>
  );
};

export default Vendors;
