import React, { lazy } from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

const AddPurchaseOrder = lazy(() => import('./purchaseOrder/AddPurchaseOrder'));
const AllPurchaseOrder = lazy(() => import('./purchaseOrder/AllPurchaseOrder'));
const EditPurchaseOrder = lazy(() => import('./purchaseOrder/EditPurchaseOrder'));

function PurchaseOrder() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path === '/' ? '' : path}/add`} component={AddPurchaseOrder} />
      <Route exact path={`${path === '/' ? '' : path}/list`} component={AllPurchaseOrder} />
      <Route exact path={`${path === '/' ? '' : path}/:id/edit`} component={EditPurchaseOrder} />
    </Switch>
  );
};

export default PurchaseOrder;
