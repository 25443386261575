import React from 'react'
import { useSelector } from 'react-redux'
import DataService from '../../../config/dataService/dataService'
import { useEffect } from 'react'
import { useState } from 'react'
import { PageHeader } from '../../../components/page-headers/page-headers'
import { Button } from '../../../components/buttons/buttons';
import FeatherIcon from 'feather-icons-react';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { Main, TableWrapper } from '../../../container/styled'
import { UserTableStyleWrapper } from '../settings/style'
import { Table, Tooltip, notification, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;
const lodash = require('lodash');

const AllCompanies = () => {

    const [companies, setCompanies] = useState([]);
    const [searchText, setSearchText] = useState('');

    const fetchCompanies = async() => {
        try {
            
            const res = await DataService.fetchCompanyByUser(searchText)
            console.log(res);
            setCompanies(res);

        } catch (error) {
            console.log(error)
        }
    }

    const deleteCompany = async id => {
        try {
            
            await DataService.deleteCompany(id);
            notification.success({message: 'Company deleted successfully.'})
            fetchCompanies()

        } catch (error) {
            console.log(error)
            notification.error({message: 'Unable to delete company.'})
        }
    }

    const showDeleteConfirm = id => {
        confirm({
            title: 'Are you sure you want to delete this company?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteCompany(id)
            }
        })
    }

    const companiesTableColumn = [
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'GST',
            dataIndex: 'GST',
            key: 'GST',
            render: data => <span>{data ? data : '-'}</span>
        },
        {
            title: 'Actions',
            key: 'action',
            render: data => (
                <div className='table-actions' style={{textAlign: 'left'}}>
                    {/* <Tooltip placement="top" title='Edit'>
                        <Link className="btn-icon" type="info" to={`/invoices/${data._id}/edit`} shape="circle">
                            <FeatherIcon icon="edit" size={16} />
                        </Link>
                    </Tooltip> */}
                    <Tooltip placement="top" title='Delete'>
                        <Button className="btn-icon" type="danger" onClick={() => showDeleteConfirm(data._id)} shape="circle">
                            <FeatherIcon icon="trash-2" style={{ color: '#e52525' }} size={16} />
                        </Button>
                    </Tooltip>
                </div>
            )
        },
    ]

    const debounceFunc = lodash.debounce(function(data) {
        setSearchText(data);
    },1000)

    const handleSearch = e => {
        debounceFunc(e.target.value)
    }

    useEffect(() => {
        fetchCompanies()
    },[searchText])

  return (
    <>
        <PageHeader
            ghost
            title="All Companies"
            buttons={[
                <div key="1" className="page-header-actions">
                    <Button size="small" type="success" href="/add-company">
                        <FeatherIcon icon="plus" size={14} />
                        Add New Company
                    </Button>
                </div>
            ]}
        />
    <Main style={{ minHeight: '77.5vh' }}>
        <Cards
            headless
            isTable
            searchText="Search by company name"
            onChange={handleSearch}
            // loading={loading}
        >
            <UserTableStyleWrapper>
                <TableWrapper className='table-responsive'>
                    <Table 
                        className='custom_style_table' 
                        dataSource={companies} 
                        columns={companiesTableColumn}
                        pagination={false}
                        rowKey={data => data._id} 
                    />

                </TableWrapper>
            </UserTableStyleWrapper>
        </Cards>
    </Main>
    </>
  )
}

export default AllCompanies