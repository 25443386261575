import { Button, Checkbox, Col, Form, Input, Modal, notification, Radio, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
// import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { BasicFormWrapper } from '../../../container/styled';
import DataService from '../../../config/dataService/dataService';

const { Option } = Select;
function AddItemPopup({ show, handleCancel, onSubmit }) {
  const { selectedCompany } = useSelector(state => ({
    selectedCompany: state.custom.selectedCompany
  }));
  const [form] = Form.useForm();
  const [taxes, setTaxes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  // const [showTaxFields, setShowTaxFields] = useState(false);
  const [company, setCompany ] = useState(null)

  const handleSubmit = async data => {
    try {
      await DataService.AddItem({ company: selectedCompany._id, ...data });
      onSubmit();
      form.resetFields();
      notification.success({ message: 'Item added successfully.' });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = () => {
    const includingTax = form.getFieldValue('includingTax');
    let tax = form.getFieldValue('tax');
    tax = taxes.find(t => t._id === tax);
    const totalAmount = form.getFieldValue('totalAmount');
    // setShowTaxFields(includingTax);
    if (typeof includingTax !== 'undefined' && includingTax) {
      if (totalAmount && tax) {
        const taxAmount = parseFloat(((totalAmount * tax.percentage) / (100 + tax.percentage)).toFixed(2));
        const sellingPrice = parseFloat(totalAmount) - taxAmount;
        form.setFieldsValue({
          taxAmount,
          sellingPrice,
        });
      } else {
        form.setFieldsValue({
          taxAmount: '',
          sellingPrice: '',
        });
      }
    } else if (totalAmount && tax) {
      const taxAmount = parseFloat(((totalAmount * tax.percentage) / 100).toFixed(2));
      const sellingPrice = parseFloat(totalAmount) + taxAmount;
      form.setFieldsValue({
        taxAmount,
        sellingPrice,
      });
    } else {
      form.setFieldsValue({
        taxAmount: '',
        sellingPrice: '',
      });
    }
  };

  const fetchSubCategories = async (category) => {
    try {
      const res = await DataService.fetchAllSubCategories({ company: selectedCompany._id, category });
      setSubCategories(res);
    } catch (error) {
      console.log(error);
    }
  };


  const fetchCompany = async () => {
    try {
      const res = await DataService.fetchCompany(selectedCompany._id);
      setCompany(res.company);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchTaxes = async () => {
      try {
        const allTaxes = await DataService.getTaxes();
        setTaxes(allTaxes);
      } catch (error) {
        console.log(error);
      }
    };
    if (selectedCompany) {

      const fetchCategories = async () => {
        try {
          const res = await DataService.fetchAllCategories({ company: selectedCompany._id });
          setCategories(res.categories);
        } catch (error) {
          console.log(error);
        }
      };

      fetchTaxes();
      fetchCategories();
      fetchCompany()
    }
  }, [selectedCompany]);


  const handleSelect = (val) => {
    form.resetFields(['subCategory'])
    fetchSubCategories(val)
  }
  return (
    <Modal type="primary" title="Add Item" open={show} footer={null} onCancel={handleCancel} width={ window.innerWidth > 1024 ? '60%' : '90%'} centered  bodyStyle={{
      padding: '10px'
    }}>
      <div className="project-modal">
        <BasicFormWrapper>
          <Form form={form} onFinish={handleSubmit} name="multi-form" layout="vertical" size='small'>
            <Row gutter={10} align="middle">
              <Col sm={12} xs={24} className="mb-10">
                <Form.Item
                  name="itemType"
                  label="Item Type"
                  className='add-modal'
                  rules={[{ required: true, message: 'This is required field' }]}
                >
                  <Radio.Group>
                    <Row>
                      <Col sm={12} xs={24}>
                        <Radio value="goods"> Goods </Radio>
                      </Col>
                      <Col sm={12} xs={24}>
                        <Radio value="service"> Service </Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col sm={12} xs={24} className="mb-10">
                <Form.Item
                  name="title"
                  label="Item Title"
                  className='add-modal'
                  rules={[{ required: true, message: 'This is required field' }]}
                >
                  <Input placeholder="Item Title" />
                </Form.Item>
              </Col>
              <Col sm={12} xs={24} className="mb-10">
                <Form.Item name="category" label="Category"  className='add-modal' placeholder="Category">
                  <Select className="sDash_fullwidth-select" placeholder="Category" onSelect={handleSelect}>
                    {categories.map((c, index) => (
                      <Option key={index} value={c._id}>
                        {c.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={12} xs={24} className="mb-10">
                <Form.Item name="subCategory" label="Sub Category" className='add-modal' onChange={handleChange}>
                  <Select className="sDash_fullwidth-select" placeholder="Sub Category">
                    {subCategories.map(sc => (
                      <Option key={sc._id} value={sc._id}>
                        {sc.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={12} xs={24} md={8} className="mb-10">
                <Form.Item name="description" className='add-modal' label="Description">
                  <Input placeholder="Enter Description" />
                </Form.Item>
              </Col>
              <Col sm={12} xs={24} md={8} className="mb-10">
                <Form.Item
                  name="quantity"
                  label="Quantity"
                  className='add-modal'
                  rules={[{ pattern: '^[0-9]*$', message: 'Quantity should be digit only!' }]}
                >
                  <Input
                    placeholder="Enter Quantity"
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              {
                   (company && company.isGSTRegistered) ?
              <Col sm={12} xs={24} md={8} className="mb-10">
                <Form.Item
                  name="HSN_SAC"
                  label="HSN/SAC"
                  className='add-modal'
                  rules={[
                    { required: true, message: 'This is required field' },
                    { pattern: '^[0-9]*$', message: 'HSN/SAC should be digit only!' },
                  ]}
                >
                  <Input
                    placeholder="Enter HSN/SAC"
                    maxLength={8}
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              :
              <Col sm={12} xs={24} md={8} className="mb-10">
                <Form.Item
                  name="HSN_SAC"
                  label="HSN/SAC"
                  className='add-modal'
                  rules={[
                    { pattern: '^[0-9]*$', message: 'HSN/SAC should be digit only!' },
                  ]}
                >
                  <Input
                    placeholder="Enter HSN/SAC"
                    maxLength={8}
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
              </Col>
}
              {
                   (company && company.isGSTRegistered) ?
                   <>
              <Col xs={24} className="mb-10">
                <Form.Item name="includingTax" valuePropName="checked">
                  <Checkbox onChange={handleChange}>Tax Inclusive?</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={12} className="mb-10">
                <Form.Item name="tax" label="Tax" className='add-modal' rules={[{ required: true, message: 'This is required field' }]}>
                  <Select size="small" className="sDash_fullwidth-select" placeholder="TAX" onChange={handleChange}>
                    {taxes.map(t => (
                      <Option key={t._id} value={t._id}>
                        {t.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col sm={12} xs={24} className="mb-10">
                <Form.Item
                  name="totalAmount"
                  label="Total Amount"
                  className='add-modal'
                  rules={[
                    { required: true, message: 'This is required field' },
                    { pattern: '^[0-9]*(\.?[0-9]*)$', message: 'Total Amount should be digit only!' },
                  ]}
                >
                  <Input placeholder="Enter Total Amount" onChange={handleChange} />
                </Form.Item>
              </Col>
              <Col sm={12} xs={24} className="mb-10">
                <Form.Item
                  name="taxAmount"
                  className='add-modal'
                  label="Tax Amount"
                  rules={[{ required: true, message: 'This is required field' }]}
                >
                  <Input placeholder="Enter Tax Amount" disabled />
                </Form.Item>
              </Col>
              </>:
              <Col sm={12} xs={24} className="mb-10">
                <Form.Item
                  name="sellingPrice"
                  className='add-modal'
                  label="Selling Price"
                  rules={[{ required: true, message: 'This is required field' }]}
                >
                  <Input placeholder="Enter Selling Price" />
                </Form.Item>
              </Col>
              }
            </Row>
            <Row>
              <Col sm={12} xs={24}>
                <div className="sDash_form-action">
                  <Button className="btn-signin" htmlType="submit" type="primary" size="default">
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </BasicFormWrapper>
      </div>
    </Modal>
  );
}

AddItemPopup.propTypes = {
  show: propTypes.bool,
  handleCancel: propTypes.func,
  onSubmit: propTypes.func,
};

export default AddItemPopup;
