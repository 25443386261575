import { Button, Col, Form, Input, Modal, notification, Row } from 'antd';
import React from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { BasicFormWrapper } from '../../../container/styled';
import DataService from '../../../config/dataService/dataService';
import { useState } from 'react';
import { TextEditor } from '../invoice/components.js/TextEditor';

function AddNotePopup({ show, handleCancel, onSubmit }) {
  const [form] = Form.useForm();
  const { selectedCompany } = useSelector(state => ({
    selectedCompany: state.custom.selectedCompany,
  }));
  const [noteContent, setNoteContent] = useState(null);

  const handleFinish = async values => {
    try {
      values.note = noteContent;
      values.company = selectedCompany._id;
      const res = await DataService.addNote(values);
      notification.success({ message: 'Note added successfully' });
      form.resetFields();
      onSubmit(res);
    } catch (error) {
      console.log(error);
      notification.error({message: error.response.data})
    }
  };

  return (
    <Modal
      type="primary"
      title="Add Note"
      open={show}
      footer={null}
      onCancel={handleCancel}
      width={window.innerWidth > 1024 ? '40%' : '90%'}
      centered
      bodyStyle={{
        padding: '10px'
      }}
    >
      <div className="project-modal">
        <BasicFormWrapper>
          <Form type="vertical" form={form} onFinish={handleFinish} name="multi-form" layout="vertical" size='small'>
            <Row gutter={10}>
              <Col xs={24} className="mb-10">
                <Form.Item
                  name="title"
                  className="add-modal"
                  label="Title"
                  rules={[{ required: true, message: 'This is required field' }]}
                >
                  <Input placeholder="Title" maxLength={100} />
                </Form.Item>
              </Col>
              {/* <Col xs={24} className="mb-10">
                <Form.Item name="note" className='add-modal' label="Note" rules={[{ required: true, message: 'This is required field' }]}>
                  <Input placeholder="Note" maxLength={100} />
                </Form.Item>
              </Col> */}
              <Col xs={24} className="mb-25">
                <label>Note</label>
                <TextEditor marginTop="10px" height={300} setDetails={setNoteContent} />
              </Col>
              <Col xs={24}>
                <Button type="primary" className="btn-signin" htmlType="submit" size="large">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </BasicFormWrapper>
      </div>
    </Modal>
  );
}

AddNotePopup.propTypes = {
  show: propTypes.bool,
  handleCancel: propTypes.func,
  onSubmit: propTypes.func,
};

export default AddNotePopup;
