import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Form, Input, Button, Col, Row,  notification, List, Switch } from 'antd';
// import { useDispatch} from 'react-redux';
import FeatherIcon from 'feather-icons-react';

import { AuthWrapper } from './style';
// import { Checkbox } from '../../../../components/checkbox/checkbox';
import Heading from '../../../../components/heading/heading';
import DataService from '../../../../config/dataService/dataService';

const SignUp = () => {
  const history = useHistory();
  const [loading, setLoading] = useState();
  // const [err, setError] = useState('');
  const [resErr, setResErr] = useState([]);
  const [showGST,setShowGST] = useState(false);
  // const { err, isLoading } = useSelector(state => {
  //   return {
  //     err: state.auth.error,
  //     isLoading: state.auth.loading,
  //   };
  // });
  // const dispatch = useDispatch();
  // const [state, setState] = useState({
  //   values: null,
  //   // checked: null,/
  // });
  const handleSubmit = async values => {
    // setState({ ...state, values });
    // dispatch({ type: 'LOGIN_BEGIN' });
    setLoading(true);
    try {
      await DataService.register(values);
      // dispatch({ type: 'LOGIN_SUCCESS' });
      notification.success({
        message: 'Registered successful! Email verification link successfully sent to your email address!',
      });
      history.push('/');
      setLoading(false);
      // setError('');
      setResErr([])
    } catch (error) {
      console.log(error);
      setLoading(false);
      setResErr(error.response.data)
      // setError(error.response.data);
      // dispatch({ type: 'LOGIN_ERR', err: error.response.data });
    }
  };

  // const onChange = checked => {
  //   setState({ ...state, checked });
  // };

  return (
    <AuthWrapper>
      
      <div style={{display: 'flex' , alignItems: 'flex-start', justifyContent:'space-between'}}>
          <figure>
            <img style={{ width: window.innerWidth < 576 ? '100px' : '200px', display: window.innerWidth > 991 ? 'none' : '' }} src={require('../../../../static/img/one-invoice-logo.svg')} alt="" />
          </figure>
          {/* <p className="auth-notice" style={{display: window.innerWidth < 576 ? 'none' : ''}}>
            Already have an account? <NavLink to="/">Sign In</NavLink>
          </p> */}
      </div>
      {/* {err && <Alert message={err} type="error" style={{ marginBottom: '30px' }} />} */}
      <div className="auth-contents" style={{marginTop: window.innerWidth < 991 ? '60px' : '10px',  backgroundColor:'#fff', padding: '10px', borderRadius: '6px'}}>
        <Form name="register" style={{width: window.innerWidth < 991 ? '95%' : '45%'}} onFinish={handleSubmit} layout="vertical" size='small'>
          <Heading as="h3" className="text-center">
            Create Your <span className="color-secondary">Account</span>
          </Heading>
          <Row gutter={6}>
            <Col xs={24} md={12} span={24}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[{ required: true, message: 'Please input your First name!' }]}
                className='mb-0'
              >
                <Input placeholder="First name" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} span={24}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[{ required: true, message: 'Please input your Last name!' }]}
                className='mb-0'
              >
                <Input placeholder="Last name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={6}>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Email Address"
                rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                className='mb-0'
              >
                <Input placeholder="name@example.com" />
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item
                name="phone"
                label="Phone number"
                className='mb-0'
                rules={[
                  { required: true, message: 'Please input your phone number!' },
                  {
                    pattern: '^[0-9]*$',
                    message: 'Phone number must be number!',
                  },
                ]}
              >
                <Input placeholder="Phone number" maxLength={10} />
              </Form.Item>
            </Col> */}
          </Row>
          
          <Row gutter={6}>
            <Col span={24}>
              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }, {min: 8, message:"Password must be minimum 8 characters."}]}
              >
                <Input.Password autoComplete='new-password' placeholder="Password" />
              </Form.Item>
            </Col>
            {/* <Col md={12} span={24}>
              <Form.Item
                label="Confirm Password"
                name="confirm"
                rules={[
                  { required: true, message: 'Please input confirm password!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            </Col> */}
          </Row>

          {/* <Form.Item
            name="companyName"
            label="Company Name"
            rules={[{ required: true, message: 'Please input your company name!' }]}
          >
            <Input placeholder="Company Name" />
          </Form.Item>
          <Form.Item label="Is your company GST Registered?" valuePropName="checked" name='isGSTRegistered'>
            <Switch onChange={(val) => setShowGST(val) }/>
          </Form.Item>
          {
            showGST ? 
            <Form.Item
              name="GST"
              label="GST Number"
              rules={[
                { required: true, message: 'Please input your company GST.' },
                {
                  message: 'Enter Valid GST Number',
                  pattern: '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$',
                },
              ]}
            >
              <Input placeholder="GST Number" />
            </Form.Item>:null
          } */}
         
          {
            resErr.length > 0 &&
            <List 
              bordered
              style={{marginBottom: '20px', backgroundColor:'#ff4c4c1a'}}
              dataSource={resErr}
              renderItem={(item) => (
                <List.Item style={{color: 'red'}}>
                <FeatherIcon size={10} icon="x" /> {item}
              </List.Item>
              )}
            />
          }
          <Form.Item>
            <Button className="btn-create" style={{width:'100%'}} htmlType="submit" type="primary" size="default">
              {loading ? 'Loading...' : 'Create Account'}
            </Button>
          </Form.Item>
          <p className="auth-notice" style={{textAlign: 'center'}}>
          Already have an account? <NavLink to="/login">Sign In</NavLink>
          </p>
        </Form>
      </div>
    </AuthWrapper>
  );
};

export default SignUp;
