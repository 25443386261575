import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Modal, notification, Pagination, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { UserTableStyleWrapper } from '../settings/style';
import { Main, TableWrapper } from '../../../container/styled';
import { Cards } from '../../../components/cards/frame/cards-frame';
import DataService from '../../../config/dataService/dataService';
import { Button } from '../../../components/buttons/buttons';
import { PageHeader } from '../../../components/page-headers/page-headers';

const lodash = require('lodash');

const { confirm } = Modal;

const SubCategoryListTable = () => {

  const dispatch = useDispatch();
  const pageTitle = useSelector(state => state.custom.pageTitle)

  const setMeta = () => {
    dispatch({type: 'SET_PAGETITLE', pageTitle: 'All Sub Categories'})
  }

  const { selectedCompany } = useSelector(state => {
    return {
      selectedCompany: state.custom.selectedCompany,
    };
  });
  const itemsPerPage = 3;
  const [pageno, setPageno] = useState(1);
  // const [itemsPerPage, setItemsPerPage] = useState(3);
  const [subcategories, setSubCategories] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [searchText, setSearchText] = useState('');

  // users.map(user => {
  //   const { id, name, designation, img, status } = user;

  //   return customerTableData.push({
  //     key: id,
  //     user: (
  //       <div className="user-info">
  //         {/* <figure>
  //           <img style={{ width: '40px' }} src={require(`./${img}`)} alt="" />
  //         </figure> */}
  //         <figcaption>
  //           <Heading className="user-name" as="h6">
  //             {name}
  //           </Heading>
  //           <span className="user-designation">San Francisco, CA</span>
  //         </figcaption>
  //       </div>
  //     ),
  //     email: 'john@gmail.com',
  //     company: 'Business Development',
  //     position: designation,
  //     joinDate: 'January 20, 2020',
  //     status: <span className={`status-text ${status}`}>{status}</span>,
  //     action: (
  //       <div className="table-actions">
  //         <>
  //           <Button className="btn-icon" type="primary" to="#" shape="circle">
  //             <FeatherIcon icon="eye" size={16} />
  //           </Button>
  //           <Button className="btn-icon" type="info" to="#" shape="circle">
  //             <FeatherIcon icon="edit" size={16} />
  //           </Button>
  //           <Button className="btn-icon" type="danger" to="#" shape="circle">
  //             <FeatherIcon icon="trash-2" size={16} />
  //           </Button>
  //         </>
  //       </div>
  //     ),
  //   });
  // });
  const deleteCategory = async id => {
    try {
      await DataService.deleteSubCategory({ id, company: selectedCompany._id });
      notification.success({ message: 'Category deleted successfully.' });
      fetchSubCategories();
    } catch (error) {
      console.log(error);
      notification.error({ message: 'Unable to delete customer.' });
    }
  };
  const showDeleteConfirm = id => {
    confirm({
      title: 'Are you sure delete this category?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',

      onOk() {
        deleteCategory(id);
      },
    });
  };

  const categoryTableColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: data => <p>{moment(data).format('Do MMM YYYY, hh:mm a')}</p>,
    },
    {
      title: 'Actions',
      key: 'action',
      width: '90px',
      render: data => (
        <div className="table-actions">
        <>
            {/* <Link to={`/admin/items/sub-category/${data._id}/edit`}>Edit</Link>
            <Button onClick={() => showDeleteConfirm(data._id)}>Delete</Button> */}
              <Tooltip title='Edit' placement='top'>
                <Link className="btn-icon" type="info" to={`/items/sub-category/${data._id}/edit`} shape="circle">
                  <FeatherIcon icon="edit" size={16} />
                </Link>
              </Tooltip>
              <Tooltip title='Delete' placement='top'>
                <Button className="btn-icon" type="danger" onClick={() => showDeleteConfirm(data._id)} shape="circle">
                  <FeatherIcon icon="trash-2" style={{color: '#e52525'}} size={16} />
                </Button>
              </Tooltip>
        </>
          </div>
      ),
    },
  ];

  const debounceFunc = lodash.debounce(function(data) {
    setSearchText(data);
  }, 1000);

  const handleChange = e => {
    setPageno(1);
    debounceFunc(e.target.value);
  };

  const fetchSubCategories = async () => {
    try {
      const res = await DataService.fetchSubCategories({
        company: selectedCompany._id,
        pageno,
        itemsPerPage,
        searchText,
      });
      setSubCategories(res.subCategories);
      setTotalItems(res.noOfSubCategories);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      fetchSubCategories();
    }
  }, [selectedCompany, pageno, itemsPerPage, searchText]);

  useEffect(() => {
    setMeta()
    document.title = 'One Invoice - All Sub Categories'
  },[])


  return (
    <>
      <PageHeader
        ghost
        title="Sub Category List"
        buttons={[
          <div key="1" className="page-header-actions">
            <Button size="small" type="success" href="/app/items/sub-category/add">
              <FeatherIcon icon="plus" size={14} />
              Add Sub category
            </Button>
          </div>,
        ]}
      />
      <Main style={{ minHeight: '77.5vh' }}>
        <Cards headless searchText="Search Sub Category" isStatus={false} onChange={handleChange} isTable>
          <UserTableStyleWrapper>
            <TableWrapper className="table-responsive">
              <Table
                className="mb-20 custom_style_table"
                dataSource={subcategories}
                columns={categoryTableColumns}
                rowKey={data => data._id}
                pagination={false}
                // pagination={{
                //   defaultPageSize: itemsPerPage,
                //   total: totalItems,
                //   onChange: (page, pageSize) => {
                //     setPageno(page);
                //     setItemsPerPage(pageSize);
                //   },
                //   showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                // }}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                <Pagination
                  current={pageno}
                  pageSize={itemsPerPage}
                  total={totalItems}
                  onChange={(pageNumber, pageSize) => setPageno(pageNumber)}
                  showTotal={total => `Total ${total} items`}
                />
              </div>
            </TableWrapper>
          </UserTableStyleWrapper>
        </Cards>
      </Main>
    </>
  );
};

export default SubCategoryListTable;
