import { Button, Card, Col, Form, Input, notification, Row } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Heading from '../../../components/heading/heading';
import DataService from '../../../config/dataService/dataService';
import { BasicFormWrapper } from '../../../container/styled';

const Account = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch()

  const fetchProfile = async () => {
    const res = await DataService.fetchProfile();
    
    form.setFieldsValue({
      firstName: res.firstName,
      lastName: res.lastName,
      phone: res.phone,
      email: res.email,
    });
  };

  const handleFinish = async values => {
    try {
      const res = await DataService.updateProfile({ ...values });
      notification['success']({ message: res });
    } catch (error) {
      console.log(error);
      notification['error']({ message: error.response.data });
    }
  };

  useEffect(() => {
    fetchProfile();
    dispatch({type: 'SET_ADMINSCREEN', adminScreen: true})
    dispatch({type: 'SET_PAGETITLE', pageTitle: 'Profile'})
    document.title = 'One Invoice - Profile'
    return () => {
      dispatch({type: 'SET_ADMINSCREEN', adminScreen: false})
    }
  }, [dispatch]);

  return (
      <Card style={{margin: window.innerWidth > 768 ?'50px auto' : '70px 10px 20px', maxWidth: window.innerWidth > 768 ? '600px' : '100%', }}>
        <div className="author-info" style={{ borderBottom: '1px solid #F1F2F6', paddingBottom: '20px' }}>
          <figcaption>
            <div className="info">
              <Heading className="mb-4" as="h5">
                Profile
              </Heading>
              <p style={{ color: '#9299B8', marginBottom: 0 }}>Update your Profile</p>
            </div>
          </figcaption>
        </div>
        <BasicFormWrapper>
          <Form form={form} layout="vertical" className="mt-20" name="multi-form" onFinish={handleFinish}>
            <Row gutter={30}>
              <Col xs={24} className="mb-25">
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[{ required: true, message: 'This is required field' }]}
                >
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col xs={24} className="mb-25">
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[{ required: true, message: 'This is required field' }]}
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>
              <Col xs={24} className="mb-25">
                <Form.Item name="email" label="Email">
                  <Input placeholder="Email" disabled readOnly />
                </Form.Item>
              </Col>
              <Col xs={24} className="mb-25">
                <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'This is required field' }]}>
                  <Input 
                    placeholder="Phone" 
                    minLength={10}
                    maxLength={10}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }} 
                    />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <Button type="primary" className="btn-signin" htmlType="submit" size="large">
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </BasicFormWrapper>
      </Card>
  );
};

export default Account;
