import fetch from './fetchInterceptor';

const DataService = {};

DataService.login = data => {
  return fetch({
    url: '/login',
    method: 'POST',
    headers: {
      'public-request': true,
    },
    data,
  });
};

DataService.register = data => {
  return fetch({
    url: '/register',
    method: 'POST',
    headers: {
      'public-request': true,
    },
    data,
  });
};

DataService.updateCompanyDetails = data => {
  return fetch({
    url: '/update-company-details',
    method: 'PUT',
    headers: {
      'public-request': true,
    },
    data,
  });
};

DataService.forgotPassword = data => {
  return fetch({
    url: '/forgot-password',
    method: 'POST',
    headers: {
      'public-request': true,
    },
    data,
  });
};

DataService.verifyEmail = data => {
  return fetch({
    url: `/email-verify/${data}`,
    method: 'GET',
    headers: {
      'public-request': true,
    },
  });
};

DataService.resetPassword = details => {
  return fetch({
    url: `/password/reset/${details.token}`,
    method: 'PUT',
    data: details.data,
    headers: {
      'public-request': true,
    },
  });
};

DataService.fetchUser = () => {
  return fetch({
    url: '/profile',
    method: 'GET',
  });
};

DataService.skipStep = () => {
  return fetch({
    url: '/step/skip',
    method: 'GET',
  });
};

DataService.fetchCompanyByUser = search => {
  search = search ? search : '';
  return fetch({
    url: `/company/all?search=${search}`,
    method: 'GET',
  });
};

DataService.fetchStates = () => {
  return fetch({
    url: '/state',
  });
};

// Dashbaord

DataService.fetchStats = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/dashbaord?${searchParams}`,
    method: 'GET',
  });
};
// Customer

DataService.AddCustomer = data => {
  return fetch({
    url: '/customer/add',
    method: 'POST',
    data,
  });
};

DataService.EditCustomer = data => {
  return fetch({
    url: `/customer/${data.customer}/edit`,
    method: 'PUT',
    data: data.values,
  });
};

DataService.fetchCustomerDetails = ({ id, invoicePage }) => {
  return fetch({
    url: `/customer/${id}/details?page=${invoicePage}`,
    method: 'GET',
  });
};

DataService.fetchCustomers = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/customer/list?${searchParams}`,
    method: 'GET',
  });
};

DataService.exportCustomers = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/customer/export?${searchParams}`,
    method: 'GET',
  });
};

DataService.exportCustomersInvoices = ({ company, ...rest }) => {
  return fetch({
    url: `/customer/export-invoices?company=${company}`,
    method: 'POST',
    data: rest,
  });
};

DataService.importCustomers = data => {
  return fetch({
    url: '/customer/import',
    method: 'POST',
    data,
  });
};
DataService.fetchCustomer = id => {
  return fetch({
    url: `/customer/${id}/edit`,
    method: 'GET',
  });
};

DataService.deleteCustomer = id => {
  return fetch({
    url: `/customer/${id}/delete`,
    method: 'DELETE',
  });
};

DataService.searchCustomer = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/customer/search?${searchParams}`,
    method: 'GET',
  });
};

DataService.AddForeignCustomer = data => {
  return fetch({
    url: '/customer/foreign/add',
    method: 'POST',
    data,
  });
};

DataService.fetchForeignCustomers = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/customer/foreign/list?${searchParams}`,
    method: 'GET',
  });
};

DataService.deleteForeignCustomer = id => {
  console.log(id);
  return fetch({
    url: `/customer/${id}/foreign/delete`,
    method: 'DELETE',
  });
};

DataService.exportForeignCustomers = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/customer/foreign/export?${searchParams}`,
    method: 'GET',
  });
};

DataService.importForeignCustomers = data => {
  return fetch({
    url: '/customer/foreign/import',
    method: 'POST',
    data,
  });
};

DataService.fetchForeignCustomerDetails = id => {
  return fetch({
    url: `/customer/${id}/foreign/details`,
    method: 'GET',
  });
};

DataService.fetchForeignCustomer = id => {
  return fetch({
    url: `/customer/${id}/foreign/edit`,
    method: 'GET',
  });
};

DataService.EditForeignCustomer = data => {
  return fetch({
    url: `/customer/${data.customer}/foreign/edit`,
    method: 'PUT',
    data: data.values,
  });
};

DataService.searchForeignCustomer = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/customer/foreign/search?${searchParams}`,
    method: 'GET',
  });
};

// Category
DataService.AddCategory = data => {
  return fetch({
    url: '/category/add',
    method: 'POST',
    data,
  });
};

DataService.fetchCategories = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/category/list?${searchParams}`,
  });
};

DataService.updateProfile = data => {
  return fetch({
    url: `/profile/edit`,
    data,
    method: 'POST',
  });
};

DataService.fetchCompanies = queryParams => {
  return fetch({
    url: `/company?pageno=${queryParams.pageNo}&&itemsPerPage=${queryParams.itemsPerPage}`,
    method: 'GET',
  });
};

DataService.fetchAllCategories = data => {
  return fetch({
    url: `/category/list/all?company=${data.company}`,
    method: 'GET',
  });
};

DataService.fetchCategory = id => {
  return fetch({
    url: `/category/${id}/edit`,
    method: 'GET',
  });
};

DataService.editCategory = data => {
  return fetch({
    url: `/category/${data.id}/edit`,
    method: 'PUT',
    data: {
      ...data.values,
    },
  });
};

DataService.deleteCategory = id => {
  return fetch({
    url: `/category/${id}/delete`,
    method: 'DELETE',
  });
};

// SubCategory
DataService.fetchSubCategories = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/sub-category/list?${searchParams}`,
    method: 'GET',
  });
};

DataService.AddSubCategory = data => {
  return fetch({
    url: '/sub-category/add',
    method: 'POST',
    data,
  });
};

// Terms And Conditions
DataService.fetchAllTermsAndConditions = data => {
  return fetch({
    url: `/terms-conditions/list/all?company=${data.company}`,
  });
};
DataService.fetchTermsAndConditions = data => {
  return fetch({
    url: `/terms-conditions/all?company=${data.company}&&page=${data.page}`,
  });
};

DataService.addTermsAndConditions = data => {
  return fetch({
    url: '/terms-conditions/add',
    method: 'POST',
    data,
  });
};

DataService.editTermsAndCondition = details => {
  return fetch({
    url: `/terms-conditions/${details.id}/edit`,
    method: 'PUT',
    data: details.data,
  });
};

DataService.getTermAndCondition = id => {
  return fetch({
    url: `/terms-condition/${id}`,
    method: 'GET',
  });
};

DataService.deleteTerms = id => {
  return fetch({
    url: `/terms-conditions/${id}/delete`,
    method: 'DELETE',
  });
};

DataService.editSubCategory = data => {
  return fetch({
    url: `/sub-category/${data.id}/edit`,
    method: 'PUT',
    data,
  });
};

DataService.fetchSubCategory = data => {
  return fetch({
    url: `/sub-category/${data.id}/edit?company=${data.company}`,
    method: 'GET',
  });
};

DataService.deleteSubCategory = data => {
  return fetch({
    url: `/sub-category/${data.id}/delete?company=${data.company}`,
    method: 'DELETE',
  });
};

DataService.fetchAllSubCategories = data => {
  return fetch({
    url: `/sub-category/list/all?company=${data.company}&category=${data.category}`,
    method: 'GET',
  });
};

// Tax
DataService.getTaxes = () => {
  return fetch({
    url: `/tax`,
    method: 'GET',
  });
};

// Item

DataService.AddItem = data => {
  return fetch({
    url: '/item/add',
    method: 'post',
    data,
  });
};

DataService.EditItem = data => {
  return fetch({
    url: `/item/${data.id}/edit`,
    method: 'PUT',
    data: data.values,
  });
};

DataService.deleteItem = id => {
  return fetch({
    url: `/item/${id}/delete`,
    method: 'DELETE',
  });
};

DataService.fetchItem = id => {
  return fetch({
    url: `/item/${id}/edit`,
    method: 'get',
  });
};
DataService.getItemsForOptions = data => {
  return fetch({
    url: `/item/options/list`,
    method: 'POST',
    data,
  });
};

DataService.listItems = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/item/list?${searchParams}`,
    method: 'GET',
  });
};

DataService.searchItem = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/item/search?${searchParams}`,
    method: 'GET',
  });
};

DataService.exportItems = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/item/export?${searchParams}`,
    method: 'GET',
  });
};

DataService.importItems = data => {
  return fetch({
    url: '/item/import',
    method: 'POST',
    data,
  });
};

// Company

DataService.fetchBankDetails = data => {
  return fetch({
    url: `/bank-details?company=${data.company}`,
    method: 'GET',
  });
};

DataService.allGroupNames = data => {
  return fetch({
    url: `/all-group-names?company=${data.company}`,
    method: 'GET',
  });
};

DataService.addBankDetails = data => {
  return fetch({
    url: '/bank/add',
    data,
    method: 'POST',
  });
};

DataService.getBankDetailsList = queryParams => {
  return fetch({
    url: `/banks?company=${queryParams.company}&pageno=${queryParams.pageNo}&itemsPerPage=${queryParams.itemsPerPage}`,
    method: 'GET',
  });
};

DataService.editBankDetails = details => {
  return fetch({
    url: `/bank-details/${details.id}/edit`,
    method: 'PUT',
    data: details.data,
  });
};

DataService.getBankDetail = id => {
  return fetch({
    url: `/bank-detail/${id}`,
    method: 'GET',
  });
};

DataService.deleteBankDetails = id => {
  return fetch({
    url: `/bank-details/${id}/delete`,
    method: 'DELETE',
  });
};

DataService.fetchProfile = () => {
  return fetch({
    url: '/profile',
    method: 'GET',
  });
};

DataService.updateCompanyPaidUnpaidStatus = data => {
  return fetch({
    url: '/update-status',
    method: 'PUT',
    data,
  });
};

DataService.updateDigitalSignStatus = data => {
  return fetch({
    url: '/update-digital-sign-status',
    method: 'PUT',
    data,
  });
};

DataService.updateUseDollarAsCurrency = data => {
  return fetch({
    url: '/update-currency',
    method: 'PUT',
    data,
  });
};

DataService.updateHSNLocation = data => {
  return fetch({
    url: '/update-hsn-location',
    method: 'PUT',
    data,
  });
};

// Invoice
DataService.createInvoice = data => {
  return fetch({
    url: `/invoice/create`,
    method: 'POST',
    data,
  });
};

DataService.editInvoice = data => {
  return fetch({
    url: `/invoice/${data.id}/edit`,
    method: 'PUT',
    data: data.formData,
  });
};

DataService.deleteInvoice = id => {
  return fetch({
    url: `/invoice/${id}/delete`,
    method: 'DELETE',
  });
};

DataService.fetchInvoice = id => {
  return fetch({
    url: `/invoice/${id}/edit`,
    method: 'GET',
  });
};

DataService.fetchInvoiceNumber = ({ company }) => {
  return fetch({
    url: `/invoice/invoice-number?company=${company}`,
    get: 'GET',
  });
};

DataService.shareInvoice = id => {
  return fetch({
    url: `/invoice/${id}/share`,
    method: 'GET',
  });
};
DataService.generateInvoice = id => {
  return fetch({
    url: `/invoice/${id}/generate`,
    method: 'GET',
  });
};

DataService.listInvoices = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/invoice/list?${searchParams}`,
  });
};

DataService.listDeletedInvoices = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/invoice/deleted-list?${searchParams}`,
  });
};

DataService.exportInvoices = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/invoice/export?${searchParams}`,
    method: 'GET',
  });
};

DataService.GetInvoiceEmailContent = id => {
  return fetch({
    url: `/invoice/${id}/show-email`,
  });
};

DataService.duplicateInvoice = id => {
  return fetch({
    url: `/invoice/${id}/duplicate`,
    method: 'POST',
  });
};

// Proforma
DataService.listProforma = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/proforma-invoice/list?${searchParams}`,
  });
};

DataService.createProforma = data => {
  return fetch({
    url: `/proforma-invoice/create`,
    method: 'POST',
    data,
  });
};

DataService.editProforma = data => {
  return fetch({
    url: `/proforma-invoice/${data.id}/edit`,
    method: 'PUT',
    data: data.formData,
  });
};

DataService.fetchProforma = id => {
  return fetch({
    url: `/proforma-invoice/${id}/edit`,
    method: 'GET',
  });
};

DataService.deleteProforma = id => {
  return fetch({
    url: `/proforma-invoice/${id}/delete`,
    method: 'DELETE',
  });
};

DataService.shareProforma = id => {
  return fetch({
    url: `/proforma-invoice/${id}/share`,
    method: 'GET',
  });
};

DataService.GetProformaInvoiceEmailContent = id => {
  return fetch({
    url: `/proforma-invoice/${id}/show-email`,
  });
};

DataService.generateProforma = id => {
  return fetch({
    url: `/proforma-invoice/${id}/generate`,
    method: 'GET',
  });
};

DataService.convertToInvoice = data => {
  const urlSearchParams = new URLSearchParams(data);
  return fetch({
    url: `/proforma-invoice/convert-to-invoice?${urlSearchParams}`,
    method: 'GET',
  });
};

//Purchase Order

DataService.addPurchaseOrder = data => {
  return fetch({
    url: `/purchase-order/add`,
    method: 'POST',
    data,
  });
};

DataService.listPurchaseOrder = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/purchase-order/list?${searchParams}`,
  });
};

DataService.getPurchaseOrder = id => {
  return fetch({
    url: `/purchase-order/${id}/edit`,
    method: 'GET',
  });
};

DataService.editPurchaseOrder = data => {
  return fetch({
    url: `/purchase-order/${data.id}/edit`,
    method: 'PUT',
    data: data.formData,
  });
};

DataService.deletePurchaseOrder = id => {
  return fetch({
    url: `/purchase-order/${id}/delete`,
    method: 'DELETE',
  });
};

DataService.generatePurchaseOrder = id => {
  return fetch({
    url: `/purchase-order/${id}/generate`,
    method: 'GET',
  });
};

DataService.sharePurchaseOrder = id => {
  return fetch({
    url: `/purchase-order/${id}/share`,
    method: 'GET',
  });
};

DataService.convertPurchaseToInvoice = data => {
  const urlSearchParams = new URLSearchParams(data);
  return fetch({
    url: `/purchase-order/convert?${urlSearchParams}`,
    method: 'GET',
  });
};

DataService.GetPurchaseOrderEmailContent = id => {
  return fetch({
    url: `/purchase-order/${id}/show-email`,
  });
};

DataService.getAllBusinessTypes = () => {
  return fetch({
    url: '/business-types',
    method: 'GET',
  });
};

DataService.getAllIndustryTypes = () => {
  return fetch({
    url: '/industry-types',
    method: 'GET',
  });
};

DataService.addCompany = data => {
  return fetch({
    url: '/company/add',
    data,
    method: 'POST',
  });
};

DataService.fetchCompany = id => {
  return fetch({
    url: `/company/${id}`,
    method: 'GET',
  });
};

DataService.editCompany = formData => {
  return fetch({
    url: `/company/${formData.id}/edit`,
    data: formData.data,
    method: 'PUT',
  });
};

DataService.deleteSignature = data => {
  return fetch({
    url: `/company/${data.id}/delete-signature?img=${data.img}`,
    method: 'PUT',
  });
};
DataService.deleteCompanyImg = data => {
  return fetch({
    url: `/company/${data.id}/delete-companyImg?img=${data.img}`,
    method: 'PUT',
  });
};

// Notes
DataService.fetchAllNotes = data => {
  return fetch({
    url: `/note/list/all?company=${data.company}&&page=${data.page}`,
    method: 'GET',
  });
};
DataService.fetchNotes = data => {
  return fetch({
    url: `/note/all?company=${data.company}&&page=${data.page}`,
    method: 'GET',
  });
};

DataService.addNote = data => {
  return fetch({
    url: '/note/add',
    data,
    method: 'POST',
  });
};

DataService.getNote = id => {
  return fetch({
    url: `/note/${id}`,
    method: 'GEt',
  });
};

DataService.editNote = details => {
  return fetch({
    url: `/note/${details.id}/edit`,
    method: 'PUT',
    data: details.data,
  });
};

DataService.deleteNote = id => {
  return fetch({
    url: `/note/${id}/delete`,
    method: 'DELETE',
  });
};

// Sub user
DataService.addSubUser = data => {
  return fetch({
    url: '/sub-user/add',
    method: 'POST',
    data,
  });
};

DataService.getAllSubUsers = data => {
  return fetch({
    url: `/all-sub-users/${data.company}?page=${data.page}`,
    method: 'GET',
  });
};

DataService.fetchSubUser = id => {
  return fetch({
    url: `/sub-user/${id}`,
    method: 'GET',
  });
};

DataService.updateSubUser = details => {
  return fetch({
    url: `/sub-user/${details.id}/edit`,
    method: 'PUT',
    data: details.data,
  });
};

DataService.changePassword = details => {
  return fetch({
    url: `/sub-user/${details.id}/change-password`,
    method: 'PUT',
    data: details.data,
  });
};

DataService.deleteSubUser = id => {
  return fetch({
    url: `/sub-user/${id}/delete`,
    method: 'DELETE',
  });
};

DataService.notifications = data => {
  return fetch({
    url: `/notifications/${data.company}?page=${data.page}`,
    method: 'GET',
  });
};

DataService.fetchInvoiceTemplates = () => {
  return fetch({
    url: '/invoice-templates',
    method: 'GET',
  });
};

DataService.selectTemplate = data => {
  return fetch({
    url: `/update-template`,
    method: 'PUT',
    data,
  });
};

DataService.updateCompanyImage = data => {
  return fetch({
    url: `/update-company-image/${data.id}?updateIn=${data.updateIn}&documentId=${data.documentId}`,
    method: 'PUT',
    data: data.file,
  });
};

//Pricing Plan
DataService.getAllPlans = () => {
  return fetch({
    url: `/pricing-plan/all`,
    method: 'GET',
  });
};

//Credit note
DataService.createCreditNote = data => {
  return fetch({
    url: `/credit-note/add`,
    method: 'POST',
    data,
  });
};
DataService.listCreditNotes = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/credit-note/list?${searchParams}`,
  });
};

DataService.fetchCreditNote = id => {
  return fetch({
    url: `/credit-note/${id}/edit`,
    method: 'GET',
  });
};

DataService.editCreditNote = data => {
  return fetch({
    url: `/credit-note/${data.id}/edit`,
    method: 'PUT',
    data: data.formData,
  });
};

DataService.deleteCreditNote = id => {
  return fetch({
    url: `/credit-note/${id}/delete`,
    method: 'DELETE',
  });
};

DataService.downloadCreditNote = id => {
  return fetch({
    url: `/credit-note/${id}/download`,
    method: 'GET',
  });
};

DataService.GetCreditNoteEmailContent = id => {
  return fetch({
    url: `/credit-note/${id}/show-email`,
  });
};

DataService.shareCreditNote = id => {
  return fetch({
    url: `/credit-note/${id}/share`,
    method: 'GET',
  });
};

DataService.searchInvoice = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/invoice/search?${searchParams}`,
    method: 'GET',
  });
};

//reports
DataService.getTotalRevenue = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/report/total-revenue?${searchParams}`,
  });
};

DataService.totalRevenueByWeek = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/report/total-revenue-week?${searchParams}`,
  });
};

DataService.getSalesReport = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/report/sales?${searchParams}`,
  });
};

DataService.downloadSalesReport = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/report/sales/download?${searchParams}`,
  });
};

DataService.paidUpaidReport = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/report/paid-unpaid?${searchParams}`,
  });
};

//GSTIN
DataService.getGstInData = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/gst-in/list?${searchParams}`,
  });
};

DataService.exportGstInData = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/gst-in/export?${searchParams}`,
  });
};

DataService.createSupport = data => {
  return fetch({
    url: '/support/create',
    method: 'POST',
    data,
  });
};

DataService.createRecurringPayment = data => {
  return fetch({
    url: '/recurring-payment/add',
    method: 'POST',
    data,
  });
};

DataService.editRecurringPayment = ({ id, ...data }) => {
  return fetch({
    url: `/recurring-payment/${id}/edit`,
    method: 'PUT',
    data: data.values,
  });
};

DataService.fetchRecurringPayments = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/recurring-payment/list?${searchParams}`,
    method: 'GET',
  });
};

DataService.deleteRecurringPayment = id => {
  return fetch({
    url: `/recurring-payment/${id}/delete`,
    method: 'DELETE',
  });
};

DataService.fetchRecurringPayment = id => {
  return fetch({
    url: `/recurring-payment/${id}`,
    method: 'GET',
  });
};

DataService.deleteCompany = id => {
  return fetch({
    url: `/company/${id}/delete`,
    method: 'PUT',
  });
};

// Vendors
DataService.addVendor = data => {
  return fetch({
    url: '/vendor/add',
    method: 'POST',
    data,
  });
};

DataService.listVendors = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/vendor/list?${searchParams}`,
    method: 'GET',
  });
};

DataService.fetchVendor = id => {
  return fetch({
    url: `/vendor/${id}`,
    method: 'GET',
  });
};

DataService.editVendor = (id, data) => {
  // console.log({ id, data });
  return fetch({
    url: `/vendor/${id}`,
    method: 'PUT',
    data,
  });
};

DataService.searchVendor = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/vendor/search?${searchParams}`,
    method: 'GET',
  });
};

DataService.createPurchase = data => {
  return fetch({
    url: '/purchase/add',
    method: 'POST',
    data,
  });
};

DataService.exportPurchaseData = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/purchase/export?${searchParams}`,
    method: 'GET',
  });
};

DataService.listPurchases = data => {
  const searchParams = new URLSearchParams(data);
  return fetch({
    url: `/purchase/list?${searchParams}`,
    method: 'GET',
  });
};

DataService.getPurchase = id => {
  return fetch({
    url: `/purchase/${id}`,
    method: 'GET',
  });
};

DataService.updatePurchase = (id, data) => {
  return fetch({
    url: `/purchase/${id}`,
    method: 'PUT',
    data,
  });
};

DataService.deletePurchaseDoc = (id, file) => {
  return fetch({
    url: `/purchase/${id}/delete-doc`,
    method: 'PATCH',
    data: {
      filename: file,
    },
  });
};

export default DataService;
