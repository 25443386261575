import React, { lazy } from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';
import EditCreditNote from './creditNote/EditCreditNote';

const creditNoteList = lazy(() => import('./creditNote/AllCreditNote'));
const AddCreditNote = lazy(() => import('./creditNote/AddCreditNote'))

function CreditNote() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path === '/' ? '' : path}/list`} component={creditNoteList} />
      <Route exact path={`${path === '/' ? '' : path}/add`} component={AddCreditNote} />
      <Route exact path={`${path === '/' ? '' : path}/:id/edit`} component={EditCreditNote} />
    </Switch>
  );
};

export default CreditNote;
