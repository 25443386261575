import React from 'react'
import { Link } from 'react-router-dom'
// import { PageHeader } from '../../components/page-headers/page-headers'
import AuthLayout from '../../container/profile/authentication/Index'

const NotFound = () => {
  return (
    <>
        <div className="flex w-100 align-center-v justify-content-center" style={{ minHeight: '100vh', flexDirection: 'column' }}>
            <div style={{ textAlign: 'center' }}>
                <h2 style={{ fontSize: '46px', fontWeight: '700', marginBottom: '10px' }}>404</h2>
                <span style={{ display: 'block', fontSize: '20px' }}>Page not found</span>
                <img src="https://www.ideamagix.com/assets/images/404-page/source.gif" style={{ width: '100%', maxWidth: '600px' }} alt="" />
                <Link to={'/'} className='btn btn-addNew' type="primary" size="large" style={{ width: '100%' }}>
                    <div className="flex align-center-v justify-content-center mt-15">
                        <span>Go Back Home</span>
                    </div>
                </Link>
            </div>
        </div>
    </>
  )
}

export default  AuthLayout(NotFound)