import axios from 'axios';
import history from './history';
import { getItem, removeItem } from '../../utility/localStorageControl';

const fetch = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/`,
  timeout: 60000,
});

fetch.interceptors.request.use(
  config => {
    const jwtToken = getItem('token');
    if (jwtToken) {
      config.headers.Authorization = `Bearer ${jwtToken}`;
    }
    if (!jwtToken && !config.headers['public-request']) {
      history.push('/');
      window.location.reload();
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

fetch.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    // Remove token and redirect
    if (error.response?.status === 401) {
      removeItem('token');
      removeItem('company')
      history.push('/login');
      window.location.reload();
    }

    return Promise.reject(error);
  },
);

export default fetch;
