import React, { useEffect, useState } from 'react';
import { Row, Col, Badge } from 'antd';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main } from '../../../container/styled';
import { ListGroup, PricingCard } from '../settings/style';
import Heading from '../../../components/heading/heading';
import { Button } from '../../../components/buttons/buttons';
import { List } from '../../../components/pricing/pricing';
import { Cards } from '../../../components/cards/frame/cards-frame';
import { useDispatch, useSelector } from 'react-redux';
import DataService from '../../../config/dataService/dataService';


const pricingPlan = () => {
  const dispatch = useDispatch()
  const [plans, setPlans] = useState([])
  const {user} = useSelector((state) => {
    return {
      user: state.custom.user
    }
  })
  useEffect(()=> {
    dispatch({type: 'SET_ADMINSCREEN', adminScreen: true})
    dispatch({type: 'SET_PAGETITLE', pageTitle: 'Pricing Plan'})
    document.title = 'One Invoice - Pricing Plan'
    return () => {
      dispatch({type: 'SET_ADMINSCREEN', adminScreen: false})
    }
  },[])

  const getAllPlans = async () => {
    try{
      const plans = await DataService.getAllPlans()
      setPlans(plans)
    }catch(error){
      console.log(error)
    }
  }

  useEffect(() => {
    getAllPlans()
  },[])

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  var buttons=['primary', 'secondary', 'success']
  

  return (
    <Main style={{margin: window.innerWidth > 768 ?'50px' : '80px 10px 20px'}}>
      <Row gutter={25}>
        <Col xs={24}>
          <Cards headless>
            <Row gutter={25} >
              {
                plans.map((plan, i) => (
                <Col key={i} xxl={6} lg={8} sm={12} xs={24}>
                  <PricingCard style={{ marginBottom: 30 }}>
                    <Badge className="pricing-badge" type="dark">
                      {
                        plan.title
                      }
                    </Badge>
                    {
                      plan.free ?
                      <Heading className="price-amount" as="h3">
                        Free
                      </Heading>
                      :
                      <Heading as="h3">
                        <sup className="currency">₹</sup> {plan.pricePerMonth} <sub className="pricing-validity">Per month</sub>
                      </Heading>

                    }
                    <span className="package-user-type">{plan.noOfUsers === 1 ? "For Individuals" : `For ${plan.noOfUsers} User`}</span>
                    <ListGroup>
                      {
                        plan.features.map((feature, i) => (
                          <List key={i} text={feature} />
                        ))
                      }
                    </ListGroup>
                    {
                      user?.activePlan?.plan === plan._id ?
                      <Button size="default" type='white'>
                        Current Plan
                      </Button>
                      :
                      <Button size="default" type='primary'>
                        Comming Soon
                      </Button>
                    }

                  </PricingCard>
                </Col>
                ))
              }
            </Row>
          </Cards>
        </Col>
      </Row>
    </Main>
  )
}

export default pricingPlan