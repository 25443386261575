import { Button, Card, Col, Form, Input, Select, notification, Row, Checkbox, Upload, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Heading from '../../../components/heading/heading';
import DataService from '../../../config/dataService/dataService';
import { BasicFormWrapper } from '../../../container/styled';
import { fetchCompanies } from '../../../redux/custom/actionCreator';

const { Option } = Select;

const AddCompany = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const history = useHistory();
  const [busTypes, setBusTypes] = useState([]);
  const [indTypes, setIndTypes] = useState([]);
  const [companyImage, setCompanyImage] = useState({});
  const [signature, setSignature] = useState({});
  const [states, setStates] = useState([]);
  const [showGST,setShowGST] = useState(false);

  const handleFinish = async values => {
    try {
      const formData = new FormData();
      if (typeof(values.businessType) !== 'undefined') {
        formData.append('businessType', values.businessType);
      }
      if (typeof(values.industryType) !== 'undefined') {
        formData.append('industryType', values.industryType);
      }
      if (typeof(values.website) !== 'undefined') {
        formData.append('website', values.website);
      }
      if(typeof(values.CIN) !== 'undefined'){
        formData.append('CIN', values.CIN)
      }
      if(typeof(values.TAN) !== 'undefined'){
        formData.append('TAN', values.TAN)
      }
      if(values.facebook){
        formData.append('facebook', values.facebook)
      }
      if(values.twitter){
        formData.append('twitter', values.twitter)
      }
      if(values.linkedin){
        formData.append('linkedin', values.linkedin)
      }
      if(values.instagram){
        formData.append('instagram', values.instagram)
      }
      if(values.companyColor){
        formData.append('companyColor', values.companyColor)
      }
      formData.append('companyName', values.companyName);
      formData.append('phone', values.phone);
      formData.append('email', values.email);
      formData.append('billingAddress', values.billingAddress);
      formData.append('PAN', values.PAN);
      formData.append('city', values.city);
      formData.append('placeOfSupply', values.placeOfSupply);
      formData.append('state', values.state);
      formData.append('companyImage', companyImage);
      formData.append('signature', signature);
      if(values.isGSTRegistered !== undefined || values.isGSTRegistered){
        formData.append('isGSTRegistered', true)
      }else{
        formData.append('isGSTRegistered', false)
      }
      if(values.GST !== undefined || values.GST){
        formData.append('GST', values.GST)
      }

      const res = await DataService.addCompany(formData);
      notification.success({ message: res });
      form.resetFields();
      setCompanyImage({});
      setSignature({});
      dispatch(fetchCompanies());
    } catch (error) {
      console.log(error);
      notification.error({ message: error.response.data });
    }
  };

  const getAllBusinessTypes = async () => {
    try {
      const { businessTypes } = await DataService.getAllBusinessTypes();
      // console.log(businessTypes, 'businessTypes')
      setBusTypes(businessTypes);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllIndustryTypes = async () => {
    try {
      const { industryTypes } = await DataService.getAllIndustryTypes();
      // console.log(industryTypes, 'industryTypes')
      setIndTypes(industryTypes);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchStates = async () => {
    try {
      const res = await DataService.fetchStates();
      setStates(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllBusinessTypes();
    getAllIndustryTypes();
    fetchStates();
    document.title = 'One Invoice - Add Company';
    dispatch({ type: 'SET_ADMINSCREEN', adminScreen: true });
    dispatch({ type: 'SET_PAGETITLE', pageTitle: 'Add Company' });
    document.title = 'One Invoice - Add Company';
    return () => {
      dispatch({ type: 'SET_ADMINSCREEN', adminScreen: false });
    };
  }, []);

  return (
    <Card style={{ margin: window.innerWidth > 768 ? '50px' : '70px 10px 20px' }}>
      <div className="author-info" style={{ borderBottom: '1px solid #F1F2F6', paddingBottom: '20px' }}>
        <figcaption>
          <div className="info">
            <Heading className="mb-4" as="h5">
              Add Company
            </Heading>
            <p style={{ color: '#9299B8', marginBottom: 0 }}>Add Company </p>
          </div>
        </figcaption>
      </div>
      <BasicFormWrapper>
        <Form form={form} layout="vertical" className="mt-20" name="multi-form" onFinish={handleFinish}>
          <Row gutter={30}>
            <Col xs={24} lg={6} md={12} className="mb-25">
              <Form.Item
                name="companyName"
                label="Company Name"
                rules={[{ required: true, message: 'This is required field' }]}
              >
                <Input placeholder="Company Name" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} md={12} className="mb-25">
              <Form.Item name="businessType" label="Business Type">
                <Select size="large" placeholder="Business Type" className="sDash_fullwidth-select">
                  {busTypes?.map(businessType => (
                    <Option key={businessType?._id} value={businessType?._id}>
                      {businessType?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} md={12} className="mb-25">
              <Form.Item name="industryType" label="Industry Type">
                <Select size="large" placeholder="Industry Type" className="sDash_fullwidth-select">
                  {indTypes?.map(indType => (
                    <Option key={indType?._id} value={indType?._id}>
                      {indType?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} md={12} className="mb-25">
              <Form.Item
                name="phone"
                label="Phone"
                rules={[
                  { required: 'This is required field', message: 'Please enter valid number', pattern: '^[0-9]*$' },
                ]}
              >
                <Input
                  placeholder="Phone"
                  minLength={10}
                  maxLength={10}
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} md={12} className="mb-25">
              <Form.Item name="email" label="Email" rules={[
                      { required: true, message: 'This is required field' },
                      {
                        pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                        message: 'Provide valid email',
                      },
                    ]}>
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} md={12} className="mb-25">
              <Form.Item name="website" label="Website">
                <Input type='url' placeholder="Website" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} className="mb-25">
              <Form.Item
                name="billingAddress"
                label="Billing Address"
                rules={[{ required: true, message: 'This is required field' }]}
              >
                <Input placeholder="Billing Address" maxLength={250}/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} md={12} className="mb-25">
              <Form.Item name="state" label="State" rules={[{ required: true, message: 'This is required field' }]}>
                <Select showSearch placeholder="Select a state" optionFilterProp="children">
                  {states.map(state => (
                    <Option key={state._id} value={state._id}>
                      {state.state}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} md={12} className="mb-25">
              <Form.Item name="city" label="City" rules={[{ required: true, message: 'This is required field' }]}>
                <Input placeholder="City" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} md={12} className="mb-25">
              <Form.Item
                name="placeOfSupply"
                label="Place Of Supply"
              >
                <Input placeholder="Place Of Supply" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} md={12} className="mb-25">
              <Form.Item
                name="PAN"
                label="PAN Number"
                rules={[
                  { required: true, message: 'Enter valid PAN Number', pattern: /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/ },
                ]}
              >
                <Input placeholder="PAN Number" maxLength={10} style={{ textTransform: 'uppercase' }} />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} className="mb-10">
              <Form.Item
                name="CIN"
                label="CIN"
              >
                <Input placeholder="CIN" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} className="mb-10">
              <Form.Item
                name="TAN"
                label="TAN"
              >
                <Input placeholder="TAN" maxLength={10}/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} md={12} className="mb-25">
              <Form.Item label="Is your company GST Registered?" valuePropName="checked" name='isGSTRegistered'>
                <Switch className='switch-class' onChange={(val) => setShowGST(val) }/>
              </Form.Item>
            </Col>  
           {
              showGST ? 
              <Col xs={24} lg={6} md={12} className="mb-25">
                <Form.Item
                  name="GST"
                  label="GST Number"
                  dependencies={['PAN']}
                  rules={[
                    {
                      required: true,
                      message:'Please provide GST number'
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        var re = new RegExp('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$');
                        if(!value){
                          return Promise.resolve()
                        }
                        if(!re.test(value)){
                            return  Promise.reject(new Error('Enter valid GST number'));
                        }
                        if(value.includes(getFieldValue('PAN'))) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('GST No and PAN No does not match'));
                      }
                    }), 
                  ]}
                >
                  <Input style={{ textTransform: 'uppercase' }} placeholder="GST Number" />
                </Form.Item>
              </Col>: null
            }
            <Col xs={24} lg={6} className="mb-10">
              <Form.Item
                  name="companyColor"
                  label="Company Color"
                >
                  <Input type='color' placeholder="Company Color" onChange={(e) => console.log(e.target.value)}/>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} className="mb-10">
              <Form.Item
                name="facebook"
                label="Facebook Link"
              >
                <Input type='url' placeholder="Facebook Link" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} className="mb-10">
              <Form.Item
                name="twitter"
                label="Twitter Link"
              >
                <Input type='url' placeholder="Twitter Link" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} className="mb-10">
              <Form.Item
                name="linkedin"
                label="Linkedin Link"
              >
                <Input type='url' placeholder="Linkedin Link" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} className="mb-10">
              <Form.Item
                name="instagram"
                label="Instagram Link"
              >
                <Input type='url' placeholder="Instagram Link" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} md={12} className="mb-25">
              <Form.Item label="Company Image">
                <Upload
                  showUploadList={false}
                  multiple={false}
                  onChange={val => setCompanyImage(val.file)}
                  className="sDash_upload-basic"
                  beforeUpload={() => false}
                >
                  <span className="sDash_upload-text">
                    {Object.keys(companyImage).length === 0 ? 'Select File' : companyImage.name}
                  </span>
                  <Button htmlType="button" type="default">
                    <span style={{ display: 'inline-block', marginBottom: '5px' }}>Browse</span>
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24} lg={6} md={12} className="mb-25">
              <Form.Item label="Signature">
                <Upload
                  showUploadList={false}
                  multiple={false}
                  onChange={val => setSignature(val.file)}
                  className="sDash_upload-basic"
                  beforeUpload={() => false}
                >
                  <span className="sDash_upload-text">
                    {Object.keys(signature).length === 0 ? 'Select File' : signature.name}
                  </span>
                  <Button htmlType="button" type="default">
                    <span style={{ display: 'inline-block', marginBottom: '5px' }}>Browse</span>
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Button type="primary" className="btn-signin" htmlType="submit" size="large">
                Add
              </Button>
            </Col>
          </Row>
        </Form>
      </BasicFormWrapper>
    </Card>
  );
};

export default AddCompany;
