import { Button, Card, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import DataService from '../../../../config/dataService/dataService';
import FeatherIcon from 'feather-icons-react';
import {  useHistory } from 'react-router-dom';

function EmailVerification() {
  const { token } = useParams();
  const history = useHistory();
  const [result, setResult] = useState('pending');

  useEffect(() => {
    const handleVerification = async () => {
      try {
        await DataService.verifyEmail(token);
        setResult('success');
      } catch (error) {
        console.log(error);
        setResult('error');
      }
    };
    
    if (token) {
      handleVerification(token);
    }
  }, [token]);
  return (
    <>
      <Row gutter={30} style={{ height: '100%', justifyContent: 'center' }} align="middle">
        {result === 'pending' && (
          <>
            <Col md={24} lg={6}></Col>
            <Col xs={20} lg={12} className="mt-30 ">
              <Card
                style={{ textAlign: 'center', backgroundColor: '#fff7df' }}
                headStyle={{ backgroundColor: '#fff7df' }}
                title="Email Verification"
              >
                <FeatherIcon icon="alert-circle" style={{ color: '#ffc107' }} size="40" />
                <h4 style={{ fontSize: '28px' }}>Pending</h4>
                <p>Your email verification is pending.</p>
                <Button type="primary" onClick={() => history.push('/')}>
                  Go To Login
                </Button>
              </Card>
            </Col>
            <Col md={24} lg={6}></Col>
          </>
        )}
        {result === 'success' && (
          <>
            <Col md={24} lg={6}></Col>
            <Col xs={20} lg={12} className="mt-25">
              <Card
                style={{ textAlign: 'center', backgroundColor: '#1ba93c12' }}
                headStyle={{ backgroundColor: '#1ba93c12' }}
                title="Account Activated"
              >
                <FeatherIcon icon="check-circle" style={{ color: '#28a745' }} size="40" />
                <h4 style={{ fontSize: '30px' }}>Success</h4>
                <p>
                  Thank you, your email has been verified. Your account is now active. <br /> Please use the link below
                  to login to your account.
                </p>
                <Button type="primary" onClick={() => history.push('/')}>
                  LOGIN TO YOUR ACCOUNT
                </Button>
              </Card>
            </Col>
            <Col md={24} lg={6}></Col>
          </>
        )}
        {result === 'error' && (
          <>
            <Col md={24} lg={6}></Col>
            <Col xs={20} lg={12} className="mt-25">
              <Card
                className="p-2"
                style={{ textAlign: 'center', backgroundColor: '#ffd9dc' }}
                headStyle={{ backgroundColor: '#ffd9dc' }}
                title="Verification Failed"
              >
                <FeatherIcon icon="x-circle" style={{ color: '#dc3545' }} size="40" />
                <h4 style={{ fontSize: '28px' }}>Email Verification Failed</h4>
                <p>
                  Oops! Your email has not been verified <br /> Please try again.
                </p>
                {/* <Button type="primary" onClick={() => history.push('/')}>
                  Go To Login
                </Button> */}
              </Card>
            </Col>
            <Col xs={24} sm={24} lg={6}></Col>
          </>
        )}
      </Row>
    </>
  );
}

export default EmailVerification;
