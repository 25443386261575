import React, { lazy } from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

const ProformaList = lazy(() => import('./proforma/AllProforma'));
const AddProforma = lazy(() => import('./proforma/AddProforma'));
const EditProforma = lazy(() => import('./proforma/EditProforma'));

function Proforma() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path === '/' ? '' : path}/list`} component={ProformaList} />
      <Route exact path={`${path === '/' ? '' : path}/add`} component={AddProforma} />
      <Route exact path={`${path === '/' ? '' : path}/:id/edit`} component={EditProforma} />
    </Switch>
  );
};

export default Proforma;
