import React, { lazy } from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

const TaskList = lazy(() => import('./task/AllTask.js'));
const AddTask = lazy(() => import('./task/AddTask.js'))

function Task() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path === '/' ? '' : path}/list`} component={TaskList} />
      <Route exact path={`${path === '/' ? '' : path}/add`} component={AddTask} />
    </Switch>
  );
};

export default Task;
