import React from 'react';
import { NavLink } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Input, Radio, Row, Tooltip } from 'antd';
import { CardFrame } from './style';
import Heading from '../../heading/heading';
import { Dropdown } from '../../dropdown/dropdown';
import { Button as CustomBtn } from '../../buttons/buttons';
import { SelectRadioStyle } from '../../../container/ui-elements/ui-elements-styled';

const Cards = props => {
  const {
    title,
    children,
    more,
    moreText,
    size,
    headless,
    caption,
    isbutton,
    bodyStyle,
    headStyle,
    border,
    bodypadding,
    isStatus,
    isImport,
    isConvert,
    isTable,
    searchText,
    onChange,
    handleStatusChange,
    status,
    handleImport,
    sampleLink,
    isExport,
    handleExport,
    type = 'invoice',
    loading
  } = props;
  return (
    <>
      {!headless ? (
        <CardFrame
          size={size}
          title={title}
          bodyStyle={bodyStyle && bodyStyle}
          headStyle={headStyle && headStyle}
          bordered={border}
          bodypadding={bodypadding && bodypadding}
          extra={
            <>
              {more && (
                <Dropdown content={more} placement="bottom">
                  <NavLink to="#">{!moreText ? <FeatherIcon icon="more-horizontal" /> : 'More'}</NavLink>
                </Dropdown>
              )}

              {isbutton && isbutton}
            </>
          }
          style={{ width: '100%' }}
        >
          {children}
        </CardFrame>
      ) : (
        <CardFrame
          bodypadding={bodypadding && bodypadding}
          bodyStyle={bodyStyle && bodyStyle}
          size={size}
          style={{ width: '100%' }}
          bordered={border}
        >
          {isTable && (
            <Row align="space-between" justify="top">
              <Col lg={6} span={24}>
                <Form name="input-form" className="search-input-wrap" layout="horizontal">
                  {/* <Form.Item name="input-text"> */}
                  <Form.Item style={{marginBottom: '10px'}}>
                    <Input className="search-input" placeholder={searchText} onChange={onChange} />
                    <Button>
                      <FeatherIcon icon="search" />
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
              <Col lg={18} span={24}>
                <div style={{ display: 'flex', justifyContent: 'end', alignItems:'center' }}>
                  {
                    sampleLink && 
                    <Tooltip placement="top" title='Sample Excel'>
                      <Button href={sampleLink} download  size='medium' style={{marginRight:'10px'}}>
                        Sample Excel
                      </Button>
                    </Tooltip>
                  }
                  {isImport && (
                    <Tooltip placement="top" title='Import CSV'>
                    <CustomBtn size="small" transparented type="secondary" onClick={handleImport}>
                      Import
                    </CustomBtn>
                    </Tooltip>
                  )}
                  {
                    isExport && (
                      <Button onClick={handleExport} type='primary' htmlType='button' loading={loading} style={{marginRight: '10px'}}>
                        Export
                      </Button>
                    )
                  }
                  {isStatus && (
                    <div className="status-radio">
                      <span>Status :</span>
                      <SelectRadioStyle>
                        <Radio.Group size='small' value={status} onChange={handleStatusChange}>
                          <Radio.Button value="">All</Radio.Button>
                          <Radio.Button value="paid">Paid</Radio.Button>
                           {type === 'invoice' && <Radio.Button value="overdue">Overdue</Radio.Button>}
                          <Radio.Button value="unpaid">Unpaid</Radio.Button>
                          <Radio.Button value="partially-paid">Partially Paid</Radio.Button>
                          <Radio.Button value="draft">Draft</Radio.Button>
                        </Radio.Group>
                      </SelectRadioStyle>
                    </div>
                  )}
                  {isConvert && (
                    <CustomBtn size="small" type="primary">
                      <FeatherIcon icon="plus" /> Convert To Invoice
                    </CustomBtn>
                  )}
                </div>
              </Col>
            </Row>
          )}

          {title && <Heading as="h4">{title}</Heading>}
          {caption && <p>{caption}</p>}
          {children}
        </CardFrame>
      )}
    </>
  );
};

Cards.defaultProps = {
  border: false,
};

Cards.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  size: PropTypes.string,
  more: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  bodyStyle: PropTypes.object,
  headStyle: PropTypes.object,
  isbutton: PropTypes.node,
  headless: PropTypes.bool,
  border: PropTypes.bool,
  caption: PropTypes.string,
  bodypadding: PropTypes.string,
  moreText: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
};

export { Cards };
