import React, { lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import SubCategoryListTable from './items/SubcategoryListTable';

const ItemsList = lazy(() => import('./items/AllItems'));
const AddItems = lazy(() => import('./items/AddItems'));
const AddCategory = lazy(() => import('./items/AddCategory'));
const EditCategory = lazy(() => import('./items/EditCategory'));
const EditSubCategory = lazy(() => import('./items/EditSubCategory'));
const AddSubCategory = lazy(() => import('./items/AddSubCategory'));
const CategoryListTable = lazy(() => import('./items/CategoryListTable'));
const EditItem = lazy(() => import('./items/EditItem'));

function Items() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path === '/' ? '' : path}/list`} component={ItemsList} />
      <Route exact path={`${path === '/' ? '' : path}/add`} component={AddItems} />
      <Route exact path={`${path === '/' ? '' : path}/:id/edit`} component={EditItem} />
      <Route exact path={`${path === '/' ? '' : path}/category/list`} component={CategoryListTable} />
      <Route exact path={`${path === '/' ? '' : path}/category/add`} component={AddCategory} />
      <Route exact path={`${path === '/' ? '' : path}/category/:id/edit`} component={EditCategory} />
      <Route exact path={`${path === '/' ? '' : path}/sub-category/add`} component={AddSubCategory} />
      <Route exact path={`${path === '/' ? '' : path}/sub-category/:id/edit`} component={EditSubCategory} />
      <Route exact path={`${path === '/' ? '' : path}/sub-category/list`} component={SubCategoryListTable} />
      {/* <Route exact path={`${path}/sub-category/:id/edit`} component={EditSubCategory} /> */}
    </Switch>
  );
}

export default Items;
