import React, { lazy } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const PurchaseList = lazy(() => import('./purchase/PurchaseList'))
const AddPurchase = lazy(() => import('./purchase/AddPurchase'))
const EditPurchase = lazy(() => import('./purchase/EditPurchase'))

const Purchase = () => {

  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path === '/' ? '' : path}/list`} component={PurchaseList} />
      <Route exact path={`${path === '/' ? '' : path}/add`} component={AddPurchase} />
      <Route exact path={`${path === '/' ? '' : path}/:id/edit`} component={EditPurchase} />
    </Switch>
  );
};

export default Purchase;
