import React, { lazy } from 'react';

import { Route, Switch, useRouteMatch } from 'react-router-dom';

const CustomersList = lazy(() => import('./customers/AllCustomers'));
const AddCustomer = lazy(() => import('./customers/AddCustomers'));
const EditCustomer = lazy(() => import('./customers/EditCustomers'));
const ViewCustomer = lazy(()=> import('./customers/CustomerDetails'))
const AddForeignCustomer = lazy(()=> import('./customers/AddForeignCustomer.js'))
const ForeignCustomerList = lazy(()=> import('./customers/ForeignCustomerList.js'))
const ViewForeignCustomer = lazy(()=> import('./customers/ViewForeignCustomer.js'))
const EditForeignCustomer = lazy(() => import('./customers/EditForeignCustomer.js'));

function Customers() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path === '/' ? '' : path}/list`} component={CustomersList} />
      <Route exact path={`${path === '/' ? '' : path}/add`} component={AddCustomer} />
      <Route exact path={`${path === '/' ? '' : path}/:id/edit`} component={EditCustomer} />
      <Route exact path={`${path === '/' ? '' : path}/:id/view`} component={ViewCustomer} />

      <Route exact path={`${path === '/' ? '' : path}/foreign/list`} component={ForeignCustomerList} />
      <Route exact path={`${path === '/' ? '' : path}/foreign/add`} component={AddForeignCustomer} />
      <Route exact path={`${path === '/' ? '' : path}/:id/foreign/edit`} component={EditForeignCustomer} />
      <Route exact path={`${path === '/' ? '' : path}/:id/foreign/view`} component={ViewForeignCustomer} />

    </Switch>
  );
};

export default Customers;
