import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Form, Input, Button, Alert, notification, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AuthWrapper } from './style';
import Heading from '../../../../components/heading/heading';
import DataService from '../../../../config/dataService/dataService';
import { setItem } from '../../../../utility/localStorageControl';

const resetPassword = () => {
  const [form] = Form.useForm();
  const history = useHistory()
  const token = history.location.pathname.split('/').slice(-1)[0];

  const handleSubmit = async values => {
    try {
      
      const res = await DataService.resetPassword({data: values, token})
      notification.success({message: res})
      history.push('/login')

    } catch (error) {
      console.log(error)
      notification.error({message: error.response.data})
    }
  }

  return (
    <AuthWrapper>
    <p className="auth-notice">
      Don&rsquo;t have an account? <NavLink to="/register">Sign up now</NavLink>
    </p>
    <div className="auth-contents">
      <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
        <Heading as="h3">
          Reset Your <span className="color-secondary">Password</span>
        </Heading>
   
        <Form.Item
          name="password"
          rules={[{ message: 'Please input your password!', required: true },{min: 8, message:"Password must be minimum 8 characters."}]}
          label="Password"
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          rules={[{ message: 'Please confirm your password!', required: true }]}
          label="Confirm Password"
        >
          <Input.Password placeholder="Confirm Password" />
        </Form.Item>

        <Form.Item>
          <Button className="btn-signin" htmlType="submit" type="primary" size="large">
            Change password
          </Button>
        </Form.Item>
      </Form>
    </div>
  </AuthWrapper>
  )
}

export default resetPassword