import actions from './actions';
import DataService from '../../config/dataService/dataService';

const { setUser, requestError, setCompanies, setCompany,setNoOfInvoices, setStepsComplated } = actions;

const fetchUser = () => {
  return async dispatch => {
    try {
      const user = await DataService.fetchUser();
      dispatch(setUser(user));
      let isStepsCompleted = false
      if(user.isSkipped){
        isStepsCompleted = true
      }else{
         isStepsCompleted =  user.isCompanyEdited && user.isProductAdded && user.isCustomerAdded && user.isBankDetailsAdded 
        //  isStepsCompleted =  user.isCompanyEdited && user.isProductAdded && user.isCustomerAdded && user.isInvoiceAdded && user.isBankDetailsAdded 
      }
      dispatch(setStepsComplated(isStepsCompleted))
      //   dispatch(logoutBegin());
      //   removeItem('token');
      //   dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(requestError(err));
    }
  };
};


const fetchCompanies = () =>{
  return async dispatch => {
    try {
      const res = await DataService.fetchCompanyByUser();
      if(localStorage.getItem('company')){
        let company = JSON.parse(localStorage.getItem('company'))
        company = res.find(c => c._id === company._id)
        dispatch(setCompany(company))
      }else{
        localStorage.setItem('company', JSON.stringify(res[0]))
        dispatch(setCompany(res[0]))
      }
      dispatch(setCompanies(res))
    } catch (error) {
      console.log(error);
    }
  };
}

const getAllInvoices = (company) => {
  return async (dispatch, getState) => {
  try {
    const res = await DataService.listInvoices({
      company: company,
      pageno: 1,
      itemsPerPage: 1,
    });
    dispatch(setNoOfInvoices(res.noOfInvoices));
  } catch (error) {
    console.log(error);
  }
}};


export { fetchUser, fetchCompanies, getAllInvoices };
