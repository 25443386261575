import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Form, Input, Button,  notification } from 'antd';
// import { useDispatch, useSelector } from 'react-redux';
import { AuthWrapper } from './style';
import Heading from '../../../../components/heading/heading';
import DataService from '../../../../config/dataService/dataService';
// import { setItem } from '../../../../utility/localStorageControl';

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const ForgotPassword = () => {
    const [form] = Form.useForm();
    // const history = useHistory()
    const handleSubmit = async values => {
        if(getCookie('forgot-pass')){
          return notification.error({message: "You can't send forgot password link often!"})
        }
        try {
            const res = await DataService.forgotPassword(values)
            notification.success({message: res})
            form.resetFields()
            var now = new Date();
            var time = now.getTime();
            var expireTime = time + 4 * 60 * 60 * 1000;
            now.setTime(expireTime);
            document.cookie = 'forgot-pass=YES;expires='+now.toUTCString()+';path=/';
        } catch (error) {
            console.log(error)
            notification.error({message: error.response.data})
        }
    }

    const throttleFunction=(func, delay)=>{
       let prev = 0;
      return (...args) => {
        let now = new Date().getTime(); 
        if(now - prev> delay){
          prev = now; 
          return func(...args); 
        }
      }
    }

  return (
    <AuthWrapper>
      <div style={{display: 'flex' , alignItems: 'flex-start', justifyContent:'space-between'}}>
          <figure>
            <img style={{ width: window.innerWidth < 576 ? '100px' : '200px', display: window.innerWidth > 991 ? 'none' : '' }} src={require('../../../../static/img/one-invoice-logo.svg')} alt="" />
          </figure>
          <p className="auth-notice" style={{display: window.innerWidth < 576 ? 'none' : ''}}>
            Don&rsquo;t have an account? <NavLink to="/register">Sign up now</NavLink>
          </p>
      </div>

    <div className="auth-contents">
      <Form name="login" form={form} style={{ padding: '20px 10px 1px', borderRadius: '5px', backgroundColor: 'white'}} onFinish={throttleFunction(handleSubmit, 3000)} layout="vertical">
        <Heading as="h3">
          Forgot <span className="color-secondary">Password</span>
        </Heading>
        <Form.Item
          name="email"
          style={{marginBottom: '8px'}}
          rules={[
            { message: 'Please input your Email!', required: true },
            { message: 'Provide valid email address!', type: 'email' },
          ]}
          label="Email Address"
        >
          <Input placeholder="Email Address" />
        </Form.Item>
          <Link to={'/login'} style={{marginBottom: '15px', display: 'block'}} size="large">
            Back to login
          </Link>
        <Form.Item>
          <Button className="btn-signin" htmlType="submit" type="primary" size="large">
            Reset Password Link
          </Button>
        </Form.Item>
        <p className="auth-notice" style={{display: window.innerWidth > 576 ? 'none' : ''}}>
            Don&rsquo;t have an account? <NavLink to="/register">Sign up now</NavLink>
          </p>
        
        {/* <div className="auth-form-action">
          <NavLink className="forgot-pass-link" to="/">
            Have an Click here to login
          </NavLink>
        </div> */}
      </Form>
    </div>
  </AuthWrapper>
  )
}

export default ForgotPassword