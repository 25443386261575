const actions = {
  REQUEST_BEGIN: 'REQUEST_BEGIN',
  REQUEST_SUCCESS: 'REQUEST_SUCCESS',
  REQUEST_ERR: 'REQUEST_ERR',
  SET_USER: 'SET_USER',
  SET_COMPANY: 'SET_COMPANY',
  SET_PAGETITLE: 'SET_PAGETITLE',
  SET_ADMINSCREEN: 'SET_ADMINSCREEN',
  SET_COMPANIES: 'SET_COMPANIES',
  SET_TOTALINVOICES: 'SET_TOTALINVOICES',
  SET_MENUCLOSE: 'SET_MENUCLOSE',
  SET_STEP_STATUS: 'SET_STEP_STATUS',

  requestBegin: () => {
    return {
      type: actions.REQUEST_BEGIN,
    };
  },

  requestSuccess: data => {
    return {
      type: actions.REQUEST_SUCCESS,
      data,
    };
  },

  requestError: data => {
    return {
      type: actions.REQUEST_ERR,
      data,
    };
  },

  setUser: data => {
    return {
      type: actions.SET_USER,
      user: data,
    };
  },

  setCompany: data => {
    return {
      type: actions.SET_COMPANY,
      selectedCompany: data,
    };
  },

  setCompanies: data => {
    return {
      type: actions.SET_COMPANIES,
      companies: data,
    }
  },

  setNoOfInvoices: data => {
    return {
      type: actions.SET_TOTALINVOICES,
      noOfInvoices: data,
    }
  },

  setStepsComplated: data => {
    return {
      type: actions.SET_STEP_STATUS,
      stepsCompleted: data,
    }
  },

  setPageTitle: data => {
    return {
      type: actions.SET_PAGETITLE,
      pageTitle: data,
    };
  },

  setAdminScreen: data => {
    return {
      type: actions.SET_ADMINSCREEN,
      adminScreen: data,
    }
  },
  toggleCollapse: data => {
    return {
      type: actions.SET_MENUCLOSE,
      menuClose: data,
    }
  }

};

export default actions;
