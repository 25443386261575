import { Button, Col, Form, Input, List, Row, Switch, notification } from 'antd'
import React from 'react'
import { fetchUser } from '../../redux/custom/actionCreator';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Heading from '../../components/heading/heading';
import { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import DataService from '../../config/dataService/dataService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const companyDetails = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {user, selectedCompany} = useSelector(state => state.custom)

    const [showGST,setShowGST] = useState(false);
    const [resErr, setResErr] = useState([]);
    const [loading, setLoading] = useState();
    
    const handleCompanyEdit = async(values) => {
        try {
            const res = await DataService.updateCompanyDetails({...values, id: selectedCompany._id})
            notification.success({message: res})
            console.log('pushing to app')
            history.push('/');
        } catch (error) {
            console.log(error)
            notification.error({message: 'Unable to update company details.'})
        }
    }

    useEffect(()=>{
        if(user && selectedCompany) {
            if(selectedCompany?.companyName !== `${user?.email}'s company`){
                history.push('/')
            }
        }
        dispatch(fetchUser)
    },[])

  return (
    <>
        <section className="landing-page">
            <Row align='middle' justify='center' style={{width: window.innerWidth > 991 ? '600px' : '330px'}}>
                <Col span={24}>
                <div className='landing-page-screen'>
                    <Form style={{width: '100%'}} name="companyEdit" form={form} onFinish={handleCompanyEdit} layout="vertical" size='small'>
                        <Heading as="h3">
                            Company Details
                        </Heading>
                        <Row gutter={6}>
                            {/* <Col xs={24} md={12} span={24}>
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[{ required: true, message: 'Please input your First name!' }]}
                            >
                                <Input placeholder="First name" />
                            </Form.Item>
                            </Col>
                            <Col xs={24} md={12} span={24}>
                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[{ required: true, message: 'Please input your Last name!' }]}
                            >
                                <Input placeholder="Last name" />
                            </Form.Item>
                            </Col> */}
                             <Col span={24}>
                                <Form.Item
                                    name="phone"
                                    label="Phone number"
                                    rules={[
                                    { required: true, message: 'Please input your phone number!' },
                                    {
                                        pattern: '^[0-9]*$',
                                        message: 'Phone number must be number!',
                                    },
                                    ]}
                                    className='mb-0'
                                >
                                    <Input placeholder="Phone number" maxLength={10} />
                                </Form.Item>
                                </Col>
                        </Row>
                        
                        <Form.Item
                            name="companyName"
                            label="Company Name"
                            rules={[{ required: true, message: 'Please provide your company name!' }]}
                            className='mb-0'
                        >
                            <Input placeholder="Company Name" />
                        </Form.Item>

                        {
                            showGST ? 
                            <Form.Item
                            name="GST"
                            label="GST Number"
                            rules={[
                                { required: true, message: 'Please input your company GST.' },
                                {
                                message: 'Enter Valid GST Number',
                                pattern: '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$',
                                },
                            ]}
                            >
                            <Input placeholder="GST Number" style={{ textTransform: 'uppercase' }} />
                            </Form.Item>:null
                        }
                        
                        <Form.Item className='mb-5' label="Is your company GST Registered?" valuePropName="checked" name='isGSTRegistered'>
                            <Switch onChange={(val) => setShowGST(val) }/>
                        </Form.Item>

                        {
                            resErr.length > 0 &&
                            <List 
                            bordered
                            style={{marginBottom: '20px', backgroundColor:'#ff4c4c1a'}}
                            dataSource={resErr}
                            renderItem={(item) => (
                                <List.Item style={{color: 'red'}}>
                                <FeatherIcon size={10} icon="x" /> {item}
                            </List.Item>
                            )}
                            />
                        }

                        <Form.Item>
                            <Button className="btn-create" style={{width:'100%'}} htmlType="submit" type="primary" size="default">
                            {loading ? 'Loading...' : 'Create Account'}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                </Col>
            </Row>
        </section>
    </>
  )
}

export default companyDetails
