import { Button, Col, Form, Input, Modal, notification, Row } from 'antd';
import React from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { BasicFormWrapper } from '../../../container/styled';
import DataService from '../../../config/dataService/dataService';
import { TextEditor } from '../invoice/components.js/TextEditor';
import { useState } from 'react';

function TermsAndConditionsPopup({ show, handleCancel, onSubmit }) {
  const [form] = Form.useForm();
  const { selectedCompany } = useSelector(state => ({
    selectedCompany: state.custom.selectedCompany,
  }));

  const [termsContent, setTermsContent] = useState(null);

  const handleSubmit = async values => {
    try {
      values.termAndCondition = termsContent;
      values.company = selectedCompany._id;
      const res = await DataService.addTermsAndConditions(values);
      notification.success({ message: 'Terms And Condition Added successfully!' });
      form.resetFields();
      onSubmit(res);
    } catch (error) {
      console.log(error);
      notification.error({message: error.response.data})
    }
  };

  return (
    <Modal
      type="primary"
      title="Add Terms And Conditions"
      open={show}
      footer={null}
      onCancel={handleCancel}
      centered
      width={ window.innerWidth > 1024 ? '40%' : '90%'}
      bodyStyle={{padding: '10px'}}
    >
      <div className="project-modal">
        <BasicFormWrapper>
          <Form type="vertical" form={form} onFinish={handleSubmit} name="multi-form" layout="vertical" size='small'>
            <Row gutter={30}>
              <Col xs={24} className="mb-10">
                <Form.Item
                  name="title"
                  label="Title"
                  className="add-modal"
                  rules={[{ required: true, message: 'This is required field' }]}
                >
                  <Input placeholder="Title" maxLength={100} />
                </Form.Item>
              </Col>
              {/* <Col xs={24} className="mb-10">
                <Form.Item
                  name="termAndCondition"
                  className='add-modal'
                  label="Terms and Conditions"
                  rules={[{ required: true, message: 'This is required field' }]}
                >
                  <Input placeholder="Terms and Conditions" maxLength={100} />
                </Form.Item>
              </Col> */}
              <Col xs={24} className="mb-25">
                <label>Terms and Conditions</label>
                <TextEditor marginTop="10px" height={300} setDetails={setTermsContent} />
              </Col>
              <Col xs={24}>
                <Button type="primary" className="btn-signin" htmlType="submit" size="large">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </BasicFormWrapper>
      </div>
    </Modal>
  );
}

TermsAndConditionsPopup.propTypes = {
  show: propTypes.bool,
  handleCancel: propTypes.func,
  onSubmit: propTypes.func,
};

export default TermsAndConditionsPopup;
