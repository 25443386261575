import { Button, Col, Form, Input, Modal, notification, Row, Upload } from 'antd';
import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import QRCode from 'qrcode';
import { BasicFormWrapper } from '../../../container/styled';
import DataService from '../../../config/dataService/dataService';
import { Cards } from '../../../components/cards/frame/cards-frame';

function AddBankDetailsPopUp({ show, handleCancel, onSubmit }) {
  const [form] = Form.useForm();
  const [QRCodeImg, setQRCodeImg] = useState({});
  const { selectedCompany } = useSelector(state => ({
    selectedCompany: state.custom.selectedCompany,
  }));
  const [qrLink, setQrlink] = useState(null);
  const handleFinish = async values => {
    try {
      let qrImg;

      if(values.UPI) {
        qrImg = await generateQR(values.UPI)
      }

      const formData = new FormData();
      formData.append('company', selectedCompany._id);
      formData.append('accountNo', values.accountNo);
      formData.append('accountHolderName', values.accountHolderName);
      formData.append('IFSC', values.IFSC);
      formData.append('bankName', values.bankName);
      formData.append('branchName', values.branchName);
      if(values.UPI){
        formData.append('UPI', values.UPI);
      }
      formData.append('QRCodeImg', qrImg);

      const res = await DataService.addBankDetails(formData);
      notification.success({ message: 'Bank details added successfully!' });
      form.resetFields();
      setQRCodeImg({});
      onSubmit(res)
      handleCancel();
    } catch (error) {
      notification.error({ message: error.response.data });
    }
  };

  const urlToFileObject = (url, fileName) => {
    let arr = url.split(',');
    let mime = arr[0].match(/:(.*?);/)[1]
    let bstr = atob(arr[1])
    let n = bstr.length
    let u8arr = new Uint8Array(n)
    while(n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], fileName, {type: mime})
  }

  const generateQR = async text => {
    try {
      let upiLink = `upi://pay?pa=${text}&pn=${selectedCompany.companyName}`;
      let url = await QRCode.toDataURL(upiLink)
      let img = urlToFileObject(url, 'QRCodeImg')
      setQRCodeImg(img)
      return img
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <Modal type="primary" title="Add Bank Details" open={show} footer={null} onCancel={handleCancel} centered  bodyStyle={{
      padding: '10px'
    }}>
      <div className="project-modal">
      <BasicFormWrapper>
        <Form form={form} layout="vertical" name="multi-form" onFinish={handleFinish} size='small'>
          <Row gutter={30}>
            <Col xs={24} lg={12} className="mb-10">
              <Form.Item
                name="accountHolderName"
                label="Account Holder Name"
                className='add-modal'
                rules={[{ required: true, message: 'This is required field' }]}
              >
                <Input placeholder="Account Holder Name" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} className="mb-10">
              <Form.Item
                name="accountNo"
                label="Account Number"
                className='add-modal'
                rules={[{ required: true, message: 'This is required field' }]}
              >
                <Input placeholder="Account Number" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} className="mb-10">
              <Form.Item
                name="IFSC"
                label="IFSC"
                className='add-modal'
                rules={[{ required: true, message: 'Enter Valid IFSC code', pattern: '^[A-Z]{4}0[A-Z0-9]{6}$' }]}
              >
                <Input style={{textTransform: 'uppercase'}} placeholder="IFSC" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} className="mb-10">
              <Form.Item
                name="bankName"
                label="Bank Name"
                className='add-modal'
                rules={[{ required: true, message: 'This is required field' }]}
              >
                <Input placeholder="Bank Name" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} className="mb-10">
              <Form.Item
                name="branchName"
                className='add-modal'
                label="Branch Name"
                rules={[{ required: true, message: 'This is required field' }]}
              >
                <Input placeholder="Branch Name" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12} className="mb-10">
              <Form.Item name="UPI" className='add-modal' label="UPI ID">
                <Input placeholder="Account Number" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Button type="primary" className="btn-signin" htmlType="submit" size="large">
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </BasicFormWrapper>
      </div>
    </Modal>
  );
}

AddBankDetailsPopUp.propTypes = {
  show: propTypes.bool,
  handleCancel: propTypes.func,
  onSubmit: propTypes.func,
};

export default AddBankDetailsPopUp;
