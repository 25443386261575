import { Card } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import RichTextEditor from 'react-rte';
import propTypes from 'prop-types';

export const TextEditor = ({ onChange, setDetails, marginTop = '20px', height = 160, editorData}) => {
  const [content, setContent] = useState({
    onChangeValue: null,
    value: RichTextEditor.createEmptyValue(),
  });
  const onTextInput = value => {
    setContent({ ...content, value });
    if (onChange) {
      onChange(value.toString('html'));
    }
  };
  const toolbarConfig = {
    display: ['BLOCK_TYPE_BUTTONS'],
    BLOCK_TYPE_BUTTONS: [
      { label: 'UL', style: 'unordered-list-item' },
      { label: 'OL', style: 'ordered-list-item' },
    ],
  };

  useEffect(() => {
    if (setDetails !== undefined) {
      setDetails(content.value.toString('html'));
    }
  }, [content]);

  useEffect(() => {
    if (editorData) {
      if(Object.keys(editorData).length === 0) {
        setContent({ ...content, value: RichTextEditor.createEmptyValue() });
      }

      if(editorData.note) {
        setContent({ ...content, value: RichTextEditor.createValueFromString(editorData.note, 'html') });
      }
      if(editorData.termAndCondition) {
        setContent({ ...content, value: RichTextEditor.createValueFromString(editorData.termAndCondition, 'html') });
      }
      if(editorData.remark) {
        setContent({ ...content, value: RichTextEditor.createValueFromString(editorData.remark, 'html') });
      }
    }else {
      setContent({ ...content, value: RichTextEditor.createEmptyValue() });
    }
  }, [editorData]);

  return (
    <Card
      headless="true"
      className="list_card"
      style={{ marginTop, border: '1px solid #f1f1f1', height: `${height}px` }}
    >
      <div className="sDash_editor">
        <RichTextEditor
          style={{ border: '1px solid red' }}
          toolbarConfig={toolbarConfig}
          editorStyle={{ fontFamily: "'Inter', sans-serif", fontSize: '14px', height: `${height - 50}px` }}
          placeholder="Type your message..."
          value={content.value}
          onChange={onTextInput}
        />
      </div>
    </Card>
  );
};
TextEditor.propTypes = {
  onChange: propTypes.func,
  setDetails: propTypes.any,
  marginTop: propTypes.string,
  height: propTypes.number,
  editorData: propTypes.object,
};
