import { Button, Col, Form, Input, notification, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Cards } from '../../../components/cards/frame/cards-frame'
import { PageHeader } from '../../../components/page-headers/page-headers'
import DataService from '../../../config/dataService/dataService'
import { BasicFormWrapper, Main } from '../../../container/styled'
function Support() {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const handleSubmit = async(values) => {
        setLoading(true)
        try{
            const res = await DataService.createSupport(values)
            notification.success({
                message: res
            })
            form.resetFields()
        }catch(error){
            console.log(error)
            notification.error({
                message: error.response.data
            })
        }finally{
            setLoading(false)
        }
    }

    useEffect(() => {
      dispatch({ type: 'SET_PAGETITLE', pageTitle: 'Get Support' });
        document.title = 'One Invoice - Get Support';
    }, [dispatch]);

  return (
    <>
        <PageHeader
            title="Get Support"
        />
        <Main style={{ minHeight: '77.5vh' }}>
        <Cards title="Get Support">
          <BasicFormWrapper>
            <Form form={form} onFinish={handleSubmit} name="multi-form" layout="vertical">
              <Row gutter={30}>
                <Col xs={24} className="mb-25">
                  <Form.Item
                    name="subject"
                    label="Subject"
                    rules={[{ required: true, message: 'This is required field' }]}
                  >
                    <Input placeholder="Subject"  maxLength={100} />
                  </Form.Item>
                </Col>
                <Col xs={24} className="mb-25">
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true, message: 'This is required field' }]}
                  >
                    <Input.TextArea placeholder="Description" maxLength={250} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm={12} xs={24}>
                  <div className="sDash_form-action">
                    <Button loading={loading} htmlType="submit" className="btn-signin" type="primary" size="large">
                      Submit
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </BasicFormWrapper>
        </Cards>
        </Main>
    </>
  )
}

export default Support