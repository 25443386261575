/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Row, Col } from 'antd';
import { Aside, Content } from './overview/style';
import Heading from '../../../components/heading/heading';

const AuthLayout = WraperContent => {

  let mobileStyle = {
    minHeight: '100vh',
    backgroundColor: '#fdf3fa'
  }

  return () => {
    return (
      <Row style={window.innerWidth < 991 ? mobileStyle : {}}>
        <Col style={{ display: window.innerWidth < 991 ? 'none' : '' }} lg={8} md={8} xs={24}>
          <Aside style={{ height: '100vh',position: 'sticky', top: '0', overflow: 'hidden' }}>
            <div className="auth-side-content">
              <img src={require('../../../static/img/auth/topShape.png')} alt="" className="topShape" />
              <img src={require('../../../static/img/auth/bottomShape.png')} alt="" className="bottomShape" />
              <Content style={{ padding: '50px' }}>
                <img style={{ width: '200px' }} src={require('../../../static/img/one-invoice-logo.svg')} alt="" />
                <br />
                <br />
                <Heading as="h2">India's Best GST Invoice Software</Heading>
                <p className="tagline">
                  Create Invoices, Purchases & Quotations in less than 10 seconds. Share on WhatsApp with payments links
                  and get paid faster!
                </p>
                <div className="auth-img-wrapper">
                  <img
                    className="auth-content-figure"
                    style={{filter: 'blur(1px)'}}
                    src={require('../../../static/img/auth/Invoice-template.png')}
                    alt=""
                  />
                </div>
              </Content>
            </div>
          </Aside>
        </Col>

        <Col lg={15} span={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
