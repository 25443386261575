  import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Form, Input, Button,  notification, List } from 'antd';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AuthWrapper } from './style';
// import { Checkbox } from '../../../../components/checkbox/checkbox';
import Heading from '../../../../components/heading/heading';
import DataService from '../../../../config/dataService/dataService';
import { setItem } from '../../../../utility/localStorageControl';
import FeatherIcon from 'feather-icons-react';

const SignIn = () => {
  const history = useHistory();
  const [loading, setLoading] = useState();
  // const [err, setError] = useState('');
  const [resErr, setResErr] = useState([])
  // const { err, isLoading } = useSelector(state => {
  //   return {
  //     err: state.auth.error,
  //     isLoading: state.auth.loading,
  //   };
  // });
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  // const [state, setState] = useState({
  //   checked: null,
  // });

  const handleSubmit = async values => {
    // dispatch({ type: 'LOGIN_BEGIN' });
    setLoading(true);
    try {
      const {token, isCompanyDetailsUpdate} = await DataService.login(values);
      localStorage.removeItem('company')
      localStorage.removeItem('token')
      setItem('token', token);
      dispatch({ type: 'LOGIN_SUCCESS', data: token });
      notification.success(({
        message: "Sign In successful!"
      }))
      if(isCompanyDetailsUpdate){
        history.push('/');
      }else{
        history.push('/company-update');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // setError(error.response.data)
      setResErr([error.response.data])
      // dispatch({ type: 'LOGIN_ERR', err: error.response.data });
    }
  };

  // const onChange = checked => {
  //   setState({ ...state, checked });
  // };

  return (
    <AuthWrapper>
      <div style={{display: 'flex' , alignItems: 'flex-start', justifyContent:'space-between'}}>
          <figure>
            <img style={{ width: window.innerWidth < 576 ? '100px' : '200px', display: window.innerWidth > 991 ? 'none' : '' }} src={require('../../../../static/img/one-invoice-logo.svg')} alt="" />
          </figure>
          {/* <p className="auth-notice" style={{display: window.innerWidth < 576 ? 'none' : ''}}>
            Don&rsquo;t have an account? <NavLink to="/register">Sign up now</NavLink>
          </p> */}
      </div>
      {/* {err && <Alert message={err} type="error" style={{ marginBottom: '30px' }} />} */}
      <div className="auth-contents" style={{marginTop: window.innerWidth < 991 ? '60px' : '10px',  backgroundColor:'#fff', padding: '10px', borderRadius: '6px'}}>
        <Form name="login" form={form} style={{width: window.innerWidth < 991 ? '95%' : '45%'}} onFinish={handleSubmit} layout="vertical" size='small'>
          <Heading as="h3" className="text-center">
            Sign in to your <span className="color-secondary">Account</span>
          </Heading>
          <Form.Item
            name="email"
            rules={[
              { message: 'Please input your Email!', required: true },
              { message: 'Provide valid email address!', type: 'email' },
            ]}
            label="Email Address"
          >
            <Input placeholder="Email Address" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ message: 'Please input your password!', required: true }, {min: 8, message:"Password must be minimum 8 characters."}]}
            label="Password"
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <div className="auth-form-action">
            {/* <Checkbox onChange={onChange}>Keep me logged in</Checkbox> */}
            <NavLink className="forgot-pass-link" to="/forgot-password">
              Forgot password?
            </NavLink>
          </div>
          {
            resErr.length > 0 &&
            <List 
              bordered
              style={{marginBottom: '20px', backgroundColor:'#ff4c4c1a'}}
              dataSource={resErr}
              renderItem={(item) => (
                <List.Item style={{color: 'red'}}>
                <FeatherIcon size={10} icon="x" /> {item}
              </List.Item>
              )}
            />
          }
          <Form.Item>
            <Button style={{width:'100%'}} className="btn-signin" htmlType="submit" type="primary" size="default">
              {loading ? 'Loading...' : 'Sign In'}
            </Button>
          </Form.Item>
          <p className="auth-notice" style={{textAlign: 'center'}}>
            Don&rsquo;t have an account? <NavLink to="/register">Sign up now</NavLink>
          </p>
        </Form>
      </div>
    </AuthWrapper>
  );
};

export default SignIn;
