import React, { memo, useEffect, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Divider,
  DatePicker,
  Button,
  notification,
  Upload,
  Empty,
  AutoComplete,
  Card,
  Tooltip,
  Switch,
  Space
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, BasicFormWrapper } from '../../../container/styled';

import { Cards } from '../../../components/cards/frame/cards-frame';
import DataService from '../../../config/dataService/dataService';
import AddCustomerPopup from '../common/AddCustomerPopup';
import AddForeignCustomerPopup from '../common/AddForeignCustomerPopup.js';
import TermsAndConditionsPopup from '../common/TermsAndConditionsPopup';
import AddNotePopup from '../common/AddNotePopup';
import AddItemPopup from '../common/AddItemPopup';
import AddBankDetailsPopUp from '../common/AddBankDetailsPopUp';
import { isConditionalExpression } from 'typescript';
import FontAwesome from 'react-fontawesome';
import { TextEditor } from '../invoice/components.js/TextEditor';
import { fetchCompanies } from '../../../redux/custom/actionCreator';
// import TermsAndConditions from '../settings/overview/TermsAndConditions';
const { Option } = Select;
let timeout;
let currentValue;

const EditCreditNote = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { selectedCompany, loading } = useSelector(state => {
    return {
      selectedCompany: state.custom.selectedCompany,
      loading: state.custom.loading,
    };
  });

  const [creditNoteDetails, setCreditNoteDetails] = useState(null);
  const [bankDetails, setBankDetails] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [termsConditions, setTermsConditions] = useState([]);
  const [notes, setNotes] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [itemOptions, setItemOptions] = useState([]);
  const [showCustomerPopup, setShowCustomerPopup] = useState(false);
  const [showTermsAndConditionsPopup, setshowTermsAndConditionsPopup] = useState(false);
  const [showNotePopup, setshowNotePopup] = useState(false);
  const [showItemPopup, setshowItemPopup] = useState(false);
  const [showAddBankDetailPopup, setshowAddBankDetailPopup] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [company, setCompany] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [currentTermsAndCondition, setCurrentTermsAndCondition] = useState('');
  const [currentNote, setCurrentNote] = useState('');

  const [termsData, setTermsData] = useState({});
  const [termsContent, setTermsContent] = useState(null);

  const [noteData, setNoteData] = useState({});
  const [notesContent, setNotesContent] = useState(null);
  const [invoiceOption, setInvoiceOption] = useState([]);
    const [invoiceCompanyDetails, setInvoiceCompanyDetails] = useState({
    logoImg: '',
    signImg: ''
  })
  const [isForeignCustomer, setIsForeignCustomer] = useState(false)
  const [showForeignCustomerPopup, setShowForeignCustomerPopup] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState('')


  /* Customer popup handling */
  const onCancelCustomerPopup = () => {
    setShowCustomerPopup(false);
  };

  const onCancelForeignCustomerPopup = () => {
    setShowForeignCustomerPopup(false);
  };

  const setCustomer = customer => {
    setCustomerOptions(prev => [...prev, customer]);
    form.setFieldsValue({ customer: customer._id });
    setSelectedCustomer(customer);
    setShowCustomerPopup(false);
  };

  const setForeignCustomer = customer => {
    setCustomerOptions(prev => [...prev, customer]);
    form.setFieldsValue({ customer: customer._id });
    setSelectedCustomer(customer);
   setShowForeignCustomerPopup(false)
  }

  /* Terms And Conditions popup handling */
  const onCancelTermsConditionsPopup = () => {
    setshowTermsAndConditionsPopup(false);
  };

  const setTermsCondition = item => {
    setTermsConditions(prev => [...prev, item]);
    const prevTerms = form.getFieldValue('termsAndConditions');
    form.setFieldsValue({ termsAndConditions: item._id });
    setshowTermsAndConditionsPopup(false);
  };

  /* Notes popup handling */
  const onCancelNotesPopup = () => {
    setshowNotePopup(false);
  };

  const setNote = item => {
    setNotes(prev => [...prev, item]);
    form.setFieldsValue({ note: item._id });
    setshowNotePopup(false);
  };

  /* Bank details popup handling */
  const onCancelBankDetailPopup = () => {
    setshowAddBankDetailPopup(false);
  };

  const setBankDetail = item => {
    setBankDetails(prev => [...prev, item]);
    form.setFieldsValue({ bankDetails: item._id });
    setshowAddBankDetailPopup(false);
  };

  /* Item popup handling */
  const onCancelItemPopup = () => {
    setshowItemPopup(false);
  };

  /* On search item handling */
  const fetchItemsList = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
    const fetchItems = async () => {
      try {
        const items = await DataService.searchItem({ company: selectedCompany._id, title: currentValue });
        if(callback) {
          callback(items);
        }else {
          setCustomerOptions(items)
        }
      } catch (error) {
        console.log(error);
      }
    };
    timeout = setTimeout(fetchItems, 300);
  };
  const SearchItem = ({ setSelectedItem, fieldIndex, ...rest }) => {
    const [data, setData] = useState(itemOptions);
    const [value, setValue] = useState();

    const handleSearch = newValue => {
      if (newValue) {
        fetchItemsList(newValue, setData);
      } else {
        setData([]);
      }
    };

    const handleChange = newValue => {
      rest.onChange(newValue);
      const item = data.find(item => item._id.toString() === newValue);
      setSelectedItem(item);
      
      form.setFieldsValue({
        items: [
          ...form.getFieldValue('items').map((_item, index) => {
            if(index === fieldIndex) {
              return {
                ..._item,
                customLabel: item?.title
              }
            }
            return _item;
          })
        ]
      })

      return newValue;
    };

    const options = data.map(d => (
      <Option key={d._id} value={d._id}>
        {d.title}
      </Option>
    ));
    return (
      <Select
        showSearch
        {...rest}
        // value={value}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={null}
        placeholder="Search Items"
      >
        {options}
      </Select>
    );
  };

  // Serch Invoice handling
  const fetchInvoiceList = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
    const fetchInvoices = async () => {
      try {
        const invoices = await DataService.searchInvoice({ company: selectedCompany?._id, invoiceNo: currentValue });
        callback(invoices);
      } catch (error) {
        console.log(error);
      }
    };
    timeout = setTimeout(fetchInvoices, 300);
  };

  const SearchInvoice = useMemo(() => {
    return props => {
      const [data, setData] = useState(invoiceOption);
  
      const handleSearch = newValue => {
        if (newValue) {
          fetchInvoiceList(newValue, setData);
        } else {
          setData([]);
        }
      };
  
      const handleChange = newValue => {
        props.onChange(newValue);
        // console.log(rest.fieldKey);
        // const item = data.find(item => item._id.toString() === newValue);
        // setSelectedItem(item);
        return newValue;
      };
  
      const options = data.map(d => (
        <Option key={d._id} value={d._id}>
          {d.invoiceNo}
        </Option>
      ));
  
      return (
        <Select
          showSearch
          {...props}
          // value={value}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleChange}
          notFoundContent={null}
          placeholder="Search Invoices"
        >
          {options}
        </Select>
      );

    }
  },[invoiceOption, selectedCompany]);

  /* On search customer handling */
  const fetchCustomersList = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
    const fetchItems = async () => {
      try {
        const items = await DataService.searchCustomer({ company: selectedCompany?._id, name: currentValue });
        // console.log(items, currentValue);
        // setCustomerOptions(prev => ([...prev , ...items]);
        callback(items);
      } catch (error) {
        console.log(error);
      }
    };
    timeout = setTimeout(fetchItems, 300);
  };

  const SearchCustomer = useMemo(() => {
    return props => {
      const [data, setData] = useState(customerOptions);

      const handleSearch = newValue => {
        if (newValue) {
          fetchCustomersList(newValue, setData);
        } else {
          setData([]);
        }
      };

      const options = data.map(d => (
        <Option key={d._id} value={d._id}>
          {d.name}
        </Option>
      ));
      const handleSelect = v => {
        const customer = data.find(c => v == c._id);
        setSelectedCustomer(customer);
        setSelectedAddress('');
        form.setFieldsValue({displayAddress: ''})
      };

      return (
        <Select
          {...props}
          showSearch
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onSelect={handleSelect}
          notFoundContent={null}
          placeholder="Search Customers"
        >
          {options}
        </Select>
      );
    };
  }, [customerOptions, selectedCompany]);

  const fetchForeignCustomersList = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
    const fetchItems = async () => {
      try {
        const items = await DataService.searchForeignCustomer({ company: selectedCompany?._id, name: currentValue });
        if(callback) {
          callback(items);
        }else {
          setCustomerOptions(items)
        }
      } catch (error) {
        console.log(error);
      }
    };
    if(callback) {
      timeout = setTimeout(fetchItems, 300);
    }else {
      fetchItems()
    }
  };

  const SearchForeignCustomer = useMemo(() => {
    return props => {
      const [data, setData] = useState(customerOptions);
      const handleSearch = newValue => {
        if (newValue) {
          fetchForeignCustomersList(newValue, setData);
        } else {
          setData([]);
        }
      };

      const options = data.map(d => (
        <Option key={d._id} value={d._id}>
          {d.name}-{d.phone}
        </Option>
      ));

      const handleSelect = v => {
        const customer = data.find(c => v == c._id);
        setSelectedCustomer(customer);
      };
      return (
        <Select
          {...props}
          showSearch
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onSelect={handleSelect}
          notFoundContent={null}
          placeholder="Search Customers"
        >
          {options}
        </Select>
      );
    };
  }, [customerOptions, selectedCompany]);

  const fetchBankDetails = async () => {
    try {
      const res = await DataService.fetchBankDetails({ company: selectedCompany._id });
      setBankDetails(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTaxes = async () => {
    try {
      const allTaxes = await DataService.getTaxes();
      setTaxes(allTaxes);
    } catch (error) {
      console.log(error);
    }
  };

  const updateSelectedValue = (value) => {
    let _address;
    if(selectedCustomer.addressLabel === value) {
      _address = selectedCustomer.address;
    }else {
      _address = selectedCustomer.alternateAddress.find(addr => addr.addressLabel === value).address
    }
    setSelectedAddress(_address)
  }

  const fetchTermsAndConditions = async () => {
    try {
      const res = await DataService.fetchAllTermsAndConditions({ company: selectedCompany._id });
      setTermsConditions(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNotes = async () => {
    try {
      const res = await DataService.fetchAllNotes({ company: selectedCompany._id });
      setNotes(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (termsConditions.length && currentTermsAndCondition) {
      // handleTermsChange(currentTermsAndCondition);
      termsConditions.map(term => {
        if (term._id === currentTermsAndCondition) {
          setTermsData({
            ...term,
            termAndCondition: creditNoteDetails.termText ?? term.termAndCondition,
          });
        }
      });
    }
  }, [termsConditions, currentTermsAndCondition]);

  useEffect(() => {
    if (notes.length && currentNote) {
      // handleNoteChange(currentNote);
      notes.map(note => {
        if (note._id === currentNote) {
          setNoteData({
            ...note,
            note: creditNoteDetails.noteText ?? note.note,
          });
        }
      });
    }
  }, [currentNote, notes]);

  const fetchInvoice = async () => {
    try {
      const res = await DataService.fetchCreditNote(id);
      setCreditNoteDetails(res);
      setInvoiceCompanyDetails(res.companyDetails)
      const items = res.items.map(item => item.item);

      let itemCopy = [...res.items];
      let _txAmount = itemCopy.reduce((acc, item) => (acc += parseFloat(item.taxableAmount)), 0);
      let _gtAmount = itemCopy.reduce((acc, item) => (acc += parseFloat(item.total)), 0);
      let _stAmount = itemCopy.reduce((acc, item) => (acc += parseFloat(item.subTotal)), 0);
      
      setTotalTaxAmount(Math.ceil(_gtAmount - _txAmount))
      setGrandTotal(Math.ceil(_gtAmount))
      setSubTotalAmount(Math.ceil(_stAmount))

      setCurrentTermsAndCondition(res.termsAndConditions);
      setCurrentNote(res.note);
      setItemOptions(items);

      let amount = 0;
      let taxAmount = 0;
      res.items.map(item => {
        amount += Number(item.displayTotal);
        if(item.includingTax){
          taxAmount += Number(item.taxableAmount);
        }else {
          taxAmount += Number(item.subTotal);
        }
      });
      // setTotalTaxAmount(amount - taxAmount);
      // setGrandTotal(amount);

      setInvoiceOption([res.invoice])
      setCustomerOptions([res.customer]);
      setSelectedCustomer(res.customer);

      if(res?.displayAddress) {
        let _address;

        if(res?.displayAddress === res.customer?.addressLabel) {
          _address = res.customer.address;
        }else {
          _address = res.customer.alternateAddress.find(addr => addr.addressLabel === res?.displayAddress).address
        }
        setSelectedAddress(_address)
      }

      form.setFieldsValue({
        ...res,
        items: res.items.map(item => {
          let displayTotal = 0;
          const isIncluded = item.includingTax;
          let cgst,
            igst,
            sgst;
            if(company && company.isGSTRegistered){
              if (!isIncluded) {
                const sellingPrice = item.subTotal;
                const taxableAmount = sellingPrice;
                const tax = taxes.find(t => t._id === item.tax);
                if(tax){
                  const taxAmount = parseFloat(((taxableAmount * tax.percentage) / 100).toFixed(2));
                  cgst = taxAmount / 2;
                  sgst = taxAmount / 2;
                  igst = taxAmount;
                  displayTotal = taxableAmount + taxAmount;
                }
              } else {
                const sellingPrice = item.subTotal;
                const tax = taxes.find(t => t._id === item.tax);
                if(tax){
                  const taxAmount = parseFloat(((sellingPrice * tax.percentage) / (100 + tax.percentage)).toFixed(2));
                  cgst = taxAmount / 2;
                  sgst = taxAmount / 2;
                  igst = taxAmount;
                  displayTotal = item.subTotal;
                }
              }
            }else{
              displayTotal = item.displayTotal.toString()
            }
            
          return {
            ...item,
            item: item.item._id,
            customLabel: item?.customLabel ? item.customLabel : item.item?.title,
            amount: item.amount.toString(),
            quantity: item.quantity.toString(),
            total: item.subTotal.toString(),
            cgst,
            sgst,
            igst,
            displayTotal: displayTotal.toString(),
            HSN_SAC: item.HSN_SAC,
            description: item.description ? item.description : (item.item.description ? item.item.description : '')
          };
        }),
        discount: res.discount?.toString(),
        date: moment(res.date),
        dueDate: moment(res.dueDate),
        customer: res.customer._id,
        invoice: res.invoice._id
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCompany = async () => {
    try {
      const res = await DataService.fetchCompany(selectedCompany._id);
      setCompany(res.company);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      fetchBankDetails();
      fetchTaxes();
      fetchTermsAndConditions();
      fetchNotes();
      fetchCompany();
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (company && taxes.length) {
      fetchInvoice();
    }
  }, [company, taxes]);
  const setMeta = () => {
    dispatch({ type: 'SET_PAGETITLE', pageTitle: 'Edit Credit  Note' });
  };
  useEffect(() => {
    setMeta();
    document.title = 'One Invoice - Edit Credit  Note';
  }, []);

  const getTotal = () => {
    let items = form.getFieldValue('items');
    items = items.filter(i => i !== undefined && i?.item);

    let amount = 0;
    let taxAmount = 0;
    let subTotal = 0;

    if(items.length === 0) {
      setSubTotalAmount(0)
      setGrandTotal(0);
      if(company.isGSTRegistered){
        setTotalTaxAmount(0);
      }
    }else {
      items.map(item => {
        amount += Number(item?.displayTotal);
        taxAmount += Number(item?.igst);
        subTotal += Number(item?.total);
        setSubTotalAmount(subTotal)
        setGrandTotal(amount);
        if(company.isGSTRegistered){
          setTotalTaxAmount(taxAmount);
        }
      });
    }
  };

  const ItemFields = useMemo(() => {
    return ({ name, restField, remove, form, index }) => {
      const [selectedItem, setSelectedItem] = useState();
      const [isIncluded, setIsIncluded] = useState(undefined);

      useEffect(() => {
        if (form.getFieldValue('items')[index]) {
          const isIncluded = form.getFieldValue('items')[index].includingTax;
          setIsIncluded(isIncluded);
        } else {
          setIsIncluded(undefined);
        }
      }, [index]);

      const handlSetFields = () => {
        const key = index;
        const items = form.getFieldValue('items');
        let displayTotal = 0;
        const isIncluded = selectedItem.includingTax;
        let cgst;
        let igst;
        let sgst;
        if(company.isGSTRegistered){
          if (!isIncluded) {
            const sellingPrice = selectedItem.totalAmount;
            const taxableAmount = sellingPrice;
            const tax = taxes.find(t => t._id === selectedItem.tax);
            if(tax){
              const taxAmount = parseFloat(((taxableAmount * tax.percentage) / 100).toFixed(2));
              cgst = taxAmount / 2;
              sgst = taxAmount / 2;
              igst = taxAmount;
              displayTotal = taxableAmount + taxAmount;
            }
          } else {
            const sellingPrice = selectedItem.totalAmount;
            const tax = taxes.find(t => t._id === selectedItem.tax);
            if(tax){
              const taxAmount = parseFloat(((sellingPrice * tax.percentage) / (100 + tax.percentage)).toFixed(2));
              cgst = taxAmount / 2;
              sgst = taxAmount / 2;
              igst = taxAmount;
              displayTotal = selectedItem.totalAmount.toString();
            }
          }
        }else{
          displayTotal = selectedItem.totalAmount.toString()
        }
        items[key] = {
          ...items[key],
          amount: selectedItem.totalAmount.toString(),
          quantity: '1',
          tax: selectedItem.tax?.toString(),
          displayTotal: displayTotal.toString(),
          total: selectedItem.totalAmount.toString(),
          includingTax: selectedItem.includingTax,
          cgst,
          sgst,
          igst,
          HSN_SAC: selectedItem.HSN_SAC,
          description: selectedItem.description ? selectedItem.description : ''
        };
        form.setFieldsValue({
          items,
        });
        form.validateFields(['items']);
        getTotal();
      };

      const handleRemoveItem = () => {
        remove(name)
        getTotal()
      }

      useEffect(() => {
        if (selectedItem) {
          handlSetFields();
          setIsIncluded(selectedItem.includingTax);
        }
      }, [selectedItem]);

      const handleChange = e => {
        const key = index;
        const items = form.getFieldValue('items');

        let displayTotal = 0;
        let cgst;
        let igst;
        let sgst;
        const isIncluded = items[key].includingTax;
        if(company.isGSTRegistered){
          if (!isIncluded) {
            const sellingPrice = items[key].amount;
            const taxableAmount = sellingPrice * items[key].quantity;
            const tax = taxes.find(t => t._id === items[key].tax);
            if(tax){
              const taxAmount = parseFloat(((taxableAmount * tax.percentage) / 100).toFixed(2));
              cgst = taxAmount / 2;
              sgst = taxAmount / 2;
              igst = taxAmount;
              displayTotal = (taxableAmount + taxAmount).toFixed(2);
            }
          } else {
            const sellingPrice = items[key].amount;
            const tax = taxes.find(t => t._id === items[key].tax);
            if(tax){
              const taxAmount = parseFloat(
                (((sellingPrice * tax.percentage) / (100 + tax.percentage)) * items[key].quantity).toFixed(2),
              );
              cgst = taxAmount / 2;
              sgst = taxAmount / 2;
              igst = taxAmount;
              displayTotal = (items[key].amount * items[key].quantity).toFixed(2);
            }
          }
        }else{
          displayTotal = (items[key].amount * items[key].quantity).toFixed(2)
        }
        items[key] = {
          ...items[key],
          displayTotal,
          total: (items[key].amount * items[key].quantity).toFixed(2),
          cgst,
          igst,
          sgst,
        };
        form.setFieldsValue({
          items,
        });
        getTotal();
      };

      return (
        <Row align="top" gutter={10} className="mb-10" key={restField.fieldKey}>
          <Col xs={24} md={8} lg={5} className="mb-5">
            {
               form.getFieldValue('items')[index]?.item ?
               <Form.Item
                 {...restField}
                 name={[name, 'customLabel']}
                 label={window.innerWidth > 991 ? (index === 0 ? 'Item' : '') : 'Item'}
                 rules={[{ required: true, message: 'This field is required' }]}
               >
                 <Input placeholder='Item Title' />
               </Form.Item>
               :
              <Form.Item
                {...restField}
                name={[name, 'item']}
                label={window.innerWidth > 991 ? (index === 0 ? 'Item' : '') : 'Item'}
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <SearchItem fieldIndex={index} setSelectedItem={setSelectedItem} />
              </Form.Item>
            }
          </Col>
          <Col xs={24} md={8} lg={3} className="mb-5">
            <Form.Item
              {...restField}
              name={[name, 'amount']}
              label={window.innerWidth > 991 ? (index === 0 ? 'Selling Price' : '') : 'Selling Price'}
              rules={[
                { required: true, message: 'This field is required' },
                { pattern: '^[0-9]*(\.?[0-9]*)$', message: 'Selling Price should be digit only!' },
              ]}
            >
              <Input
                placeholder="Selling Price"
                onChange={handleChange}
                onKeyPress={event => {
                  if (!/^[0-9]*(\.?[0-9]*)$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col md={8} xs={24} lg={2} className="mb-5">
            <Form.Item
              {...restField}
              name={[name, 'quantity']}
              label={window.innerWidth > 991 ? (index === 0 ? 'Quantity' : '') : 'Quantity'}
              rules={[
                { required: true, message: 'This field is required' },
                { pattern: '^[0-9]*$', message: 'Quantity should be digit only!' },
              ]}
            >
              <Input
                placeholder="Quantity"
                onChange={handleChange}
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col md={8} xs={24} lg={3} className="mb-5">
            <Form.Item
              {...restField}
              name={[name, 'total']}
              label={window.innerWidth > 991 ? (index === 0 ? 'Subtotal' : '') : 'Subtotal'}
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input
                placeholder="Total"
                disabled
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
              {
                company && company.isGSTRegistered ? 
                <>
                  <Col md={8} xs={24} lg={3} className="mb-5">
                    <div style={{ position: 'relative' }}>
                      <Form.Item
                        {...restField}
                        name={[name, 'tax']}
                        label={window.innerWidth > 991 ? (index === 0 ? 'Tax' : '') : 'Tax'}
                        rules={[{ required: true, message: 'This field is required' }]}
                      >
                        <Select onChange={handleChange} placeholder="Tax">
                          {taxes.map(t => (
                            <Option key={t._id} value={t._id}>
                              {t.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      {typeof isIncluded !== 'undefined' && (
                        <span style={{ position: 'absolute', bottom: '-20px', right: 0, fontSize: '12px' }}>
                          {isIncluded ? '*Inclusive' : '*Exclusive'}
                        </span>
                      )}
                    </div>
                  </Col>
                  {selectedCustomer?.state && company?.state === selectedCustomer?.state ? (
                    <>
                      <Col xs={24} lg={2} className="mb-5">
                        <Form.Item
                          {...restField}
                          label={window.innerWidth > 991 ? (index === 0 ? 'CGST' : '') : 'CGST'}
                          name={[name, 'cgst']}
                        >
                          <Input placeholder="CGST" disabled />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={2} className="mb-5">
                        <Form.Item
                          {...restField}
                          label={window.innerWidth > 991 ? (index === 0 ? 'SGST' : '') : 'SGST'}
                          name={[name, 'sgst']}
                        >
                          <Input placeholder="SGST" disabled />
                        </Form.Item>
                      </Col>
                    </>
                  ) : (
                    <Col xs={24} lg={4} className="mb-5">
                      <Form.Item
                        {...restField}
                        label={window.innerWidth > 991 ? (index === 0 ? 'IGST' : '') : 'IGST'}
                        name={[name, 'igst']}
                      >
                        <Input placeholder="IGST" disabled />
                      </Form.Item>
                    </Col>
                  )}
                </>: null
          }
           <Col xs={24} md={8} lg={4} className="mb-10" hidden>
            <Form.Item
              {...restField}
              label='HSN_SAC'
              name={[name, 'HSN_SAC']}
              hidden
            >
              <Input placeholder="HSN_SAC" disabled />
            </Form.Item>
          </Col>
          <Col md={8} xs={24} lg={3} className="mb-10">
            <Form.Item
              {...restField}
              name={[name, 'displayTotal']}
              label={window.innerWidth > 991 ? (index === 0 ? 'Total' : '') : 'Total'}
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input
                placeholder="Total"
                disabled
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={2} hidden>
            <Form.Item {...restField} name={[name, 'includingTax']}>
              <Input />
            </Form.Item>
          </Col>
          <Col sm={1} xs={24}>
            <Button
              onClick={handleRemoveItem}
              type="danger-transparent"
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 0,
                height:'30px',
                marginTop:
                  window.innerWidth > 991 ? (index === 0 ? '30px' : '') : window.innerWidth < 576 ? '' : '25px',
              }}
              size="default"
            >
              <FeatherIcon icon="x" size={16} />
            </Button>
          </Col>
          <Col span={24} className="mb-20">
          <Form.Item
              name={[name, 'description']}
              {...restField}
            >
              <Input placeholder='Description' />
            </Form.Item>
          </Col>
        </Row>
      );
    };
  }, [itemOptions, taxes, selectedCompany, selectedCustomer, company]);
  const updateTermAndCondition = async id => {
    try {
      let termValue;
      termsConditions.map(term => {
        if (term._id === id) {
          termValue = term;
        }
      });
      termValue.termAndCondition = termsContent;
      await DataService.editTermsAndCondition({ data: termValue, id });
      return;
    } catch (err) {
      console.log(err);
      notification.success({ message: err.response.data });
    }
  };

  const updateNote = async id => {
    try {
      let noteValue;
      notes.map(note => {
        if (note._id === id) {
          noteValue = note;
        }
      });
      noteValue.note = notesContent;
      const res = await DataService.editNote({ data: noteValue, id });
      return;
    } catch (err) {
      console.log(err);
      notification.success({ message: err.response.data });
    }
  };

  const handleSubmit = async values => {
    if (values.generateStatus === 'share') {
      setShareLoading(true);
    }
    if (values.generateStatus === 'generate') {
      setGenerateLoading(true);
    }
    if (values.generateStatus === 'save') {
      setDraftLoading(true);
    }
    try {
      // if(values.termsAndConditions){
      //   await updateTermAndCondition(values.termsAndConditions);
      // }
      // if(values.note){
      //   await updateNote(values.note);
      // }
      const formData = new FormData();
      let temp = values.items.map((item,i) => {
        var tempObj = {}
        Object.keys(item).forEach(key => {
            if(item[key] !== undefined){
              tempObj[key] = item[key]
            }})
        return tempObj    
      })
      // return console.log(temp)
      temp.forEach((item, i) => {
        Object.keys(item).forEach(key => {
          formData.append(`items[${i}][${key}]`, item[key]);
        });
      });
      if (values.termsAndConditions) {
        // values.termsAndConditions.forEach((item, i) => {
        //   formData.append(`termsAndConditions[${i}]`, item);
        // });
        formData.append('termsAndConditions', values.termsAndConditions);
        formData.append('termsContent', termsContent);
      }
      if (values.note) {
        formData.append('note', values.note);
        formData.append('notesContent', notesContent);
      }

      formData.append('bankDetails', values.bankDetails);
      formData.append('customer', values.customer);
      if(values?.displayAddress) {
        formData.append('displayAddress', values.displayAddress);
      }
      formData.append('customerType', isForeignCustomer);
      formData.append('date', values.date);
      if (values.discount) {
        formData.append('discount', values.discount);
      }
      if(values.dueDate){
        formData.append('dueDate', values.dueDate);
      }
      formData.append('generateStatus', values.generateStatus);
      if (values.status) {
        formData.append('status', values.status);
      }
      if (values.signature) {
        formData.append('signature', values.signature.file);
      }
      formData.append('company', selectedCompany._id);
      formData.append('invoice', values.invoice)
      const res = await DataService.editCreditNote({ formData, id });
      if (res.generateStatus === 'generate') {
        const link = document.createElement('a');
        link.href = `${process.env.REACT_APP_BACKEND_URL}/uploads/credit-note/${res.creditNote}.pdf`;
        link.setAttribute('download', `${res.creditNote}.pdf`);
        link.target = '_blank';
        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      }
      if (res.generateStatus === 'share') {
        notification.success({ message: 'Credit note generated and sent successfully.' });
      }
      if (res.generateStatus === 'save') {
        notification.success({ message: 'Credit note updated successfully.' });
      }
      fetchInvoice();
      setShareLoading(false);
      setGenerateLoading(false);
      setDraftLoading(false);
    } catch (error) {
      console.log(error);
      notification.error({ message: error.response.data });
      setShareLoading(false);
      setGenerateLoading(false);
      setDraftLoading(false);
    }
  };

  const handleStatusSubmit = generateStatus => {
    form.setFieldsValue({
      generateStatus,
    });
    form.submit();
  };

  const updateCompanyImage = async e => {
    try {
      if (e.target.files[0]) {
        const formData = new FormData();
        formData.append('companyImage', e.target.files[0]);

        const res = await DataService.updateCompanyImage({ file: formData, id: company._id });
        fetchCompany();
        dispatch(fetchCompanies())
        notification.success({ message: res });
      }
    } catch (err) {
      console.log(err);
      notification.error({ message: 'Something went wrong' });
    }
  };

  const ImageUploadComponent = () => {
    const [signature, setSignature] = useState({});

    const handleSignUpload = file => {
      if (file.fileList.length === 1) {
        setSignature(file.file);
      } else {
        setSignature({});
      }
    };

    return (
      <>
        <Form.Item
          name="signature"
          label="Signature"
          style={{ marginBottom: '6px' }}
          // rules={[
          //   () => ({
          //     validator(_, value) {
          //       if (company?.signature || value) {
          //         return Promise.resolve();
          //       }
          //       return Promise.reject(new Error('Please provide signature!'));
          //     },
          //   }),
          // ]}
        >
          <Upload
            multiple={false}
            listType="picture"
            onChange={handleSignUpload}
            className="sDash_upload-basic"
            beforeUpload={() => false}
            accept="image/*"
          >
            <span className="sDash_upload-text">
              {' '}
              {Object.keys(signature).length === 0 ? 'Select File' : signature?.name}
            </span>
            <Button htmlType="button" size="small" style={{height: '30px', lineHeight: '30px', backgroundColor: '#EEF7FF', color: '#5598E8'}}>
              <span style={{ display: 'inline-block', marginBottom: '2px' }}>Browse</span>
            </Button>
          </Upload>
        </Form.Item>
        {(company?.signature || (invoiceCompanyDetails && invoiceCompanyDetails?.signImg)) ? (
        <Card headless="true" style={{ width: '100%', border: '1px solid #f1f1f1' }}>
          <figure className="mb-10" style={{ width: 'auto', height: '100px' }}>
            {
              (invoiceCompanyDetails && invoiceCompanyDetails?.signImg) ?
                <img
                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  src={`${process.env.REACT_APP_BACKEND_URL}/uploads/company/signature/${invoiceCompanyDetails?.signImg}`}
                  alt=""
                />
              :
              (
                <>
                  {
                    company?.signature &&
                    <img
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                    src={`${process.env.REACT_APP_BACKEND_URL}/uploads/company/signature/${company?.signature}`}
                    alt=""
                  />
                  }
                </>
              )
            }
          </figure>
        </Card>
      ): 
      <Card headless="true" style={{ width: '100%', border: '1px solid #f1f1f1', marginTop: '0px' }}>
        <Empty className='mb-0' image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Card>
        }
        {/* <div style={{ border: '1px solid #f0f0f0', borderRadius: '10px' }}>
          <Cards title="Uploaded Signature" size="default">
            {company?.signature ? (
              <figure className="mb-0" style={{ width: '100%' }}>
                <img
                  style={{ width: '100%', maxHeight: '400px', objectFit: 'cover', borderRadius: '6px' }}
                  src={`${process.env.REACT_APP_BACKEND_URL}/uploads/company/signature/${company?.signature}`}
                  alt=""
                />
              </figure>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </Cards>
        </div> */}
      </>
    );
  };

  const handleTermsChange = e => {
    if(!e) {
      setTermsData({})
      setTermsContent(null)
      return;
    }
    termsConditions.map(term => {
      if (term._id === e) {
        setTermsData(term);
      }
    });
  };

  const handleNoteChange = e => {
    if(!e) {
      setNoteData({})
      setNotesContent(null)
      return;
    }
    notes.map(note => {
      if (note._id === e) {
        setNoteData(note);
      }
    });
  };

  const handleStatusChange = value => {
    if (value === 'draft') {
      setShowPrintShare(false);
    } else {
      setShowPrintShare(true);
    }
  };

  const handleForeignCustomerToggle = (checked) => {
    setIsForeignCustomer(checked)
    if(checked) {
      form.setFieldsValue({ customer: '' });
      fetchForeignCustomersList()
    }else {
      fetchInvoice()
    }
  }

  return (
    <>
      <PageHeader
        ghost
        className="invoice-header"
        title="Edit Credit Note"
        buttons={[
          <div key="1" className="page-header-actions">
            <Button
              htmlType="button"
              type="primary"
              loading={draftLoading}
              size={'small'}
              onClick={() => handleStatusSubmit('save')}
            >
              Save
            </Button>
            <Button
              htmlType="button"
              type="secondary"
              size={'small'}
              onClick={() => handleStatusSubmit('generate')}
              loading={generateLoading}
            >
              Save & Print
            </Button>
            <Button
              htmlType="button"
              type="success"
              size={'small'}
              onClick={() => handleStatusSubmit('share')}
              loading={shareLoading}
            >
              Save & Send
            </Button>
          </div>,
        ]}
      />
      <Main style={{ minHeight: '77.5vh' }}>
        <BasicFormWrapper>
          <Form form={form} onFinish={handleSubmit} name="multi-form" layout="vertical" size='small'>

          <Cards headless>
          <Row gutter={18}>
              {
                  (company && !company.isGSTRegistered) ?
                  <>
                   <Col span={24} md={12}>
                    <h4 className='text-right'>
                        Foreign Customer {" "}
                        <Switch onChange={handleForeignCustomerToggle} />
                      </h4>
                    </Col>
                    <Divider style={{marginTop: '10px 0'}} />
                  </> : ''
                }
                <Col span={24} md={6}>
                  <p style={{fontSize: '13px', marginBottom: '6px', marginTop: '5px'}}>Company Logo</p>
                  <label htmlFor="companyImage" className="change-company-profile">
                      <span className="change-img-btn">
                        <FeatherIcon icon="edit" size={'15px'} />
                      </span>
                      {company?.companyImage ? (
                        <img
                          style={{ objectFit: 'scale-down', border: '1px solid #E3E6EF', borderRadius: '4px' }}
                          width="100%"
                          height="165"
                          src={`${process.env.REACT_APP_BACKEND_URL}/uploads/company/companyImage/${company?.companyImage}`}
                          alt={company?.companyName}
                        />
                      ) : (
                        <img
                          style={{ objectFit: 'scale-down', border: '1px solid #E3E6EF', borderRadius: '4px' }}
                          width="100%"
                          height="165"
                          src={`https://ui-avatars.com/api/?name=${company?.companyName}&background=237CFF&color=fff`}
                          alt={company?.companyName}
                        />
                      )}
                    </label>
                    <input
                      type="file"
                      id="companyImage"
                      accept="image/*"
                      onChange={updateCompanyImage}
                      name="companyImage"
                      style={{ display: 'none' }}
                    />
                </Col>
                <Col span={24} md={8}>
                  <Row gutter={0}>
                    <Col span={24} className='mb-5'>
                      <Form.Item
                        name="invoice"
                        label="Choose invoice"
                        rules={[{ required: true, message: 'This field in required' }]}
                      >
                        <SearchInvoice/>
                      </Form.Item>      
                    </Col>
                    <Col span={24} className='mb-5'>
                      <Form.Item name="status" label="Status">
                        <Select placeholder="Select Status" onChange={handleStatusChange}>
                          <Option value="paid">Paid</Option>
                          <Option value="unpaid">Unpaid</Option>
                          <Option value="draft">Draft</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24} className='mb-5'>
                      <Form.Item
                        name="date"
                        label="Date"
                        rules={[{ required: true, message: 'This field is required' }]}
                      >
                        <DatePicker style={{ width: '100%' }} placeholder="Date" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} md={10}>
                  <Row gutter={0}>
                    {
                       isForeignCustomer ? 
                      <Col span={24} className='mb-5'>
                        <Space align='end' className='input_btn_wrap _quot'>
                          <Form.Item
                            name="customer"
                            label="Choose customer"
                            rules={[{ required: true, message: 'This field in required' }]}
                          >
                            <SearchForeignCustomer />
                          </Form.Item>

                          <Tooltip title="Add Customer">
                            <Button
                              type="info"
                              size="default"
                              style={{ width: '100%' }}
                              onClick={() => setShowForeignCustomerPopup(true)}
                            >
                              <div className="flex align-center-v justify-content-center">
                                <FeatherIcon icon={'plus'} size={'18px'} />
                              </div>
                            </Button>
                          </Tooltip>

                          <Tooltip title="Customer list">
                            <Button
                              type="info"
                              size="default"
                              style={{ paddingTop: '1px !important', marginLeft: '5px', width: '100%' }}
                              onClick={() => {
                                window.open('/customers/foreign/list', '_blank');
                              }}
                              target="_blank"
                            >
                              <FontAwesome name="address-book" style={{ fontSize: '20px' }} />
                            </Button>
                          </Tooltip>
                        </Space>
                      </Col>
                      :
                      <Col span={24} className='mb-5'>
                        <Space align='end' className='input_btn_wrap _quot'>
                          <Form.Item
                            name="customer"
                            label="Choose customer"
                            rules={[{ required: true, message: 'This field in required' }]}
                          >
                            <SearchCustomer />
                          </Form.Item>

                          <Tooltip title="Add Customer">
                            <Button
                              type="info"
                              size="default"
                              style={{ width: '100%' }}
                              onClick={() => setShowCustomerPopup(true)}
                            >
                              <div className="flex align-center-v justify-content-center">
                                <FeatherIcon icon={'plus'} size={'18px'} />
                              </div>
                            </Button>
                          </Tooltip>
                          <Tooltip title="Customer list">
                            <Button
                              type="info"
                              size="default"
                              style={{ paddingTop: '1px !important', marginLeft: '5px', width: '100%' }}
                              onClick={() => {
                                window.open('/app/customers/list', '_blank');
                              }}
                              target="_blank"
                            >
                              <FontAwesome name="address-book" style={{ fontSize: '20px' }} />
                            </Button>
                          </Tooltip>
                        </Space>
                      </Col>
                    }
                    {
                      (selectedCustomer && (selectedCustomer?.alternateAddress?.length > 0)) &&
                      <Col span={24} className='mb-5'>
                        <Space className='alt-address-wrap'>
                          <Form.Item name="displayAddress" label="Address">
                              <Select placeholder="Choose Address" onChange={updateSelectedValue}>
                                <Option value={selectedCustomer?.addressLabel}>{selectedCustomer?.addressLabel}</Option>
                                {
                                  selectedCustomer?.alternateAddress?.map(address => (
                                    <Option key={address?._id} value={address.addressLabel}>{address?.addressLabel}</Option>
                                  ))
                                }
                              </Select>
                          </Form.Item>
                          <Form.Item label="Selected Address">
                            <Input disabled readOnly value={selectedAddress} />
                          </Form.Item>
                        </Space>
                      </Col>
                    }
                    <Col span={24} className='mb-5'>
                      <Space align='end' className='input_btn_wrap _single'>
                        <Form.Item
                          name="bankDetails"
                          label="Bank Details"
                          rules={[{ required: true, message: 'This field in required' }]}
                        >
                          <Select className="sDash_fullwidth-select" placeholder="Select Bank Account">
                            {bankDetails.map((bankDetail, i) => (
                              <Option key={i + 1} value={bankDetail._id}>
                                {bankDetail.bankName} - {bankDetail.accountNo} - {bankDetail.accountHolderName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Tooltip title="Add Bank Details">
                          <Button size="default" type="info" onClick={() => setshowAddBankDetailPopup(true)}>
                            <div className="flex align-center-v justify-content-center">
                              <FeatherIcon icon={'plus'} size={'18px'} />
                            </div>
                          </Button>
                        </Tooltip>
                      </Space>
                    </Col>
                  </Row>
                </Col>
          </Row>
        </Cards>

        <Cards headless>
          <Row gutter={18}>
            <Col xs={24} className="mb-5">
                <Form.List name="items">
                    {(fields, { add, remove }) => (
                    <>
                     <Row style={{alignItems: 'center'}} justify="space-between" gutter={5}>
                        <Col>
                          <h4 style={{color: '#237CFF', fontWeight: '600'}} className='mb-0'>ITEMS</h4>
                        </Col>
                        <Col>
                          <Space>
                            <Button type="info" className="w-100" size="default" onClick={() => setshowItemPopup(true)}>
                              <div className="flex align-center-v justify-content-center">
                                <FeatherIcon icon={'plus'} size={'13px'} />
                                <span>Create new item</span>
                              </div>
                            </Button>
                            
                            <Form.Item>
                              <Button
                                type="dashed"
                                size="default"
                                onClick={() => add()}
                                block
                                icon={<FeatherIcon style={{marginRight: '2px', marginBottom:'-2px'}} icon={'plus'} size={'13px'} />}
                                style={{height: '30px'}}
                              >
                                Add more items
                              </Button>
                            </Form.Item>
                          </Space>
                        </Col>
                      </Row>
                      <Divider style={{margin: '10px 0 5px'}} />
                      {fields.map(({ key, name, ...restField }, i) => (
                        <ItemFields
                          index={i}
                          key={key}
                          name={name}
                          restField={restField}
                          remove={remove}
                          form={form}
                        />
                      ))}
                      <Divider style={{margin: '0px 0 15px'}} />
                      <div className='total_display'>
                        <ul>
                          <li>
                            <span>Subtotal:</span> {subTotalAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                          </li>
                          {
                            (company && company.isGSTRegistered) ?
                            <li>
                              <span>Tax:</span> {totalTaxAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                            </li>
                            : 
                            null
                          }
                          <li>
                          <span>Grand Total:</span> {" "}
                          {grandTotal.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                          </li>
                        </ul>
                      </div>
                    </>
                  )}
                </Form.List>
            </Col>
          </Row>
        </Cards>

        <Cards headless>
          <Row gutter={18}>
            <Col span={24} md={9}>
              <Row gutter={0}>
                <Col span={24}>
                  <Space align='end' className='input_btn_wrap _single'>
                    <Form.Item
                        name="termsAndConditions"
                        label="Terms And Conditions"
                      >
                        <Select allowClear showSearch placeholder="Select terms and conditions" onChange={handleTermsChange} >
                          {termsConditions?.map(t => (
                            <Option key={t._id} value={t._id}>
                              {t.title} 
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Tooltip title="Add terms and condition">
                        <Button
                          transparented="true"
                          type="info"
                          size="default"
                          style={{ width: '100%' }}
                          onClick={() => setshowTermsAndConditionsPopup(true)}
                        >
                          <div className="flex align-center-v justify-content-center">
                            <FeatherIcon icon={'plus'} size={'18px'} />
                          </div>
                        </Button>
                      </Tooltip>
                  </Space>
                </Col>
                <Col span={24}>
                <TextEditor editorData={termsData} setDetails={setTermsContent} marginTop='10px' />
                </Col>
              </Row>
            </Col>
            <Col span={24} md={9}>
              <Row gutter={0}>
                <Col span={24}>
                  <Space align='end' className='input_btn_wrap _single'>
                    <Form.Item
                        name="note"
                        label="Note"
                      >
                        <Select allowClear showSearch placeholder="Select notes" onChange={handleNoteChange}>
                          {notes.map(note => (
                            <Option key={note._id} value={note._id}>
                              {note.title} 
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Tooltip title="Add Notes">
                        <Button
                          size="default"
                          transparented="true"
                          type="info"
                          style={{ width: '100%' }}
                          onClick={() => setshowNotePopup(true)}
                        >
                          <div className="flex align-center-v justify-content-center">
                            <FeatherIcon icon={'plus'} size={'18px'} />
                          </div>
                        </Button>
                      </Tooltip>
                  </Space>
                </Col>
                <Col span={24}>
                  <TextEditor editorData={noteData} setDetails={setNotesContent} marginTop='10px' />
                </Col>
              </Row>
            </Col>
            <Col span={24} md={6}>
              <ImageUploadComponent data={selectedCompany?.signature} />
            </Col>
            <Col span={24}>
              <Form.Item name="generateStatus">
                <Input type="hidden" />
              </Form.Item>
            </Col>
          </Row>
        </Cards>

            {/* <Cards headless>
              <Row gutter={15}>
              {
                  (company && !company.isGSTRegistered) ?
                  <>
                    <Col span={24}>
                    <h4 className='text-right'>
                        Foreign Customer {" "}
                        <Switch onChange={handleForeignCustomerToggle} />
                      </h4>
                    </Col>
                    <Divider style={{marginTop: '10px'}} />
                  </> : ''
                }
                <Col xs={24} md={4} lg={4} className="mb-10">
                  <label htmlFor="companyImage" className="change-company-profile">
                    <span className="change-img-btn">
                      <FeatherIcon icon="edit" size={'15px'} />
                    </span>
                    {(invoiceCompanyDetails && invoiceCompanyDetails?.logoImg) ? (
                      <img
                      style={{ objectFit: 'scale-down', border: '1px solid #E3E6EF', borderRadius: '4px' }}
                      width="100%"
                      height="85"
                      src={`${process.env.REACT_APP_BACKEND_URL}/uploads/company/companyImage/${invoiceCompanyDetails?.logoImg}`}
                      alt={invoiceCompanyDetails?.logoImg}
                    />
                    ) : (
                      <>
                      {
                        company?.companyImage ? 
                        <img
                            style={{ objectFit: 'scale-down', border: '1px solid #E3E6EF', borderRadius: '4px' }}
                            width="100%"
                            height="85"
                            src={`${process.env.REACT_APP_BACKEND_URL}/uploads/company/companyImage/${company?.companyImage}`}
                            alt={company?.companyName}
                        />
                        :
                        <img
                          style={{ objectFit: 'scale-down', border: '1px solid #E3E6EF', borderRadius: '4px' }}
                          width="100%"
                          height="85"
                          src={`https://ui-avatars.com/api/?name=${company?.companyName}&background=237CFF&color=fff`}
                          alt={company?.companyName}
                        /> 
                      }
                    </>
                    )}
                  </label>
                  <input
                    type="file"
                    id="companyImage"
                    accept="image/*"
                    onChange={updateCompanyImage}
                    name="companyImage"
                    style={{ display: 'none' }}
                  />
                </Col>
                <Col xs={24} md={0} lg={8}>
                    <Form.Item
                      name="invoice"
                      label="Choose invoice"
                      rules={[{ required: true, message: 'This field in required' }]}
                    >
                      <SearchInvoice/>
                    </Form.Item>        
                </Col> */}
                {/* <Col xs={24} md={0} lg={2}></Col> */}
                {/* <Col xs={24} md={6} lg={6} className="mb-10">
                  <Form.Item name="status" label="Status">
                    <Select placeholder="Select Status">
                      <Option value="paid">Paid</Option>
                      <Option value="unpaid">Unpaid</Option>
                      <Option value="draft">Draft</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={10} lg={6} className="mb-10">
                  <Form.Item
                    name="date"
                    label="Credit Note date"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <DatePicker
                      style={{ width: '100%', minWidth: 'unset' }}
                      placeholder="Select credit note date"
                    />
                  </Form.Item>
                </Col> */}
                {/* <Col xs={24} md={10} lg={6} className="mb-10">
                  <Form.Item
                    name="dueDate"
                    label="Proforma invoice due date"
                    rules={[
                      { required: true, message: 'This field is required' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || moment(getFieldValue('date')).isBefore(moment(value))) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error('Proforma invoice due date can not be less than invoice date!'),
                          );
                        },
                      }),
                    ]}
                  >
                    <DatePicker style={{ width: '100%', minWidth: 'unset' }} placeholder="Select invoice due date" />
                  </Form.Item>
                </Col> */}
                {/* {
                  isForeignCustomer ?
                  <Col span={24} md={12} className='mb-10'>
                    <Row gutter={10}>
                    <Col xs={16} sm={18}>
                        <Form.Item
                          name="customer"
                          label="Choose customer"
                          rules={[{ required: true, message: 'This field in required' }]}
                        >
                          <SearchForeignCustomer />
                        </Form.Item>
                      </Col>
                      <Col xs={3} lg={2} style={{ marginTop: '40px' }}>
                        <Tooltip title="Add Customer">
                          <Button
                            type="info"
                            size="default"
                            style={{ width: '100%' }}
                            onClick={() => setShowForeignCustomerPopup(true)}
                          >
                            <div className="flex align-center-v justify-content-center">
                              <FeatherIcon icon={'plus'} size={'18px'} />
                            </div>
                          </Button>
                        </Tooltip>
                      </Col>
                      <Col xs={3} style={{ marginTop: '40px' }}>
                        <Tooltip title="Customer list">
                          <Button
                            type="info"
                            size="default"
                            style={{ paddingTop: '1px !important', marginLeft: '5px', width: '100%' }}
                            onClick={() => {
                              window.open('/customers/foreign/list', '_blank');
                            }}
                            target="_blank"
                          >
                            <FontAwesome name="address-book" style={{ fontSize: '20px' }} />
                          </Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Col>
                  : 
                  <Col xs={24} md={12} className="mb-10">
                    <Row gutter={10}>
                      <Col xs={16} sm={18}>
                        <Form.Item
                          name="customer"
                          label="Choose customer"
                          rules={[{ required: true, message: 'This field in required' }]}
                        >
                          <SearchCustomer />
                        </Form.Item>
                      </Col>
                      <Col xs={3} lg={2} style={{ marginTop: '40px' }}>
                        <Tooltip title="Add Customer">
                          <Button
                            type="info"
                            size="default"
                            style={{ width: '100%' }}
                            onClick={() => setShowCustomerPopup(true)}
                          >
                            <div className="flex align-center-v justify-content-center">
                              <FeatherIcon icon={'plus'} size={'18px'} />
                            </div>
                          </Button>
                        </Tooltip>
                      </Col>
                      <Col xs={3} style={{ marginTop: '40px' }}>
                        <Tooltip title="Customer list">
                          <Button
                            type="info"
                            size="default"
                            style={{ paddingTop: '1px !important', marginLeft: '5px', width: '100%' }}
                            onClick={() => {
                              window.open('/customers/list', '_blank');
                            }}
                            target="_blank"
                          >
                            <FontAwesome name="address-book" style={{ fontSize: '20px' }} />
                          </Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  </Col>
                }

                <Col xs={24} md={12} className="mb-10">
                  <Row gutter={10} align={'middle'}>
                    <Col xs={20}>
                      <Form.Item
                        name="bankDetails"
                        label="Bank Details"
                        rules={[{ required: true, message: 'This field in required' }]}
                      >
                        <Select className="sDash_fullwidth-select" placeholder="Select Bank Account">
                          {bankDetails.map((bankDetail, i) => (
                            <Option key={i + 1} value={bankDetail._id}>
                              {bankDetail.bankName} - {bankDetail.accountNo} - {bankDetail.accountHolderName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={4} style={{ marginTop: '37px' }}>
                      <Tooltip title="Add Bank Details">
                        <Button size="default" type="info" onClick={() => setshowAddBankDetailPopup(true)}>
                          <div className="flex align-center-v justify-content-center">
                            <FeatherIcon icon={'plus'} size={'18px'} />
                          </div>
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>

                {
                  (selectedCustomer && (selectedCustomer?.alternateAddress?.length > 0)) &&
                  <>
                  <Col span={24} md={6} className='mb-10'>
                    <Form.Item name="displayAddress" label="Address">
                        <Select placeholder="Choose Address" onChange={updateSelectedValue}>
                          <Option value={selectedCustomer?.addressLabel}>{selectedCustomer?.addressLabel}</Option>
                          {
                            selectedCustomer?.alternateAddress?.map(address => (
                              <Option key={address?._id} value={address.addressLabel}>{address?.addressLabel}</Option>
                            ))
                          }
                        </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={18} className='mb-10'>
                      <Form.Item label="Selected Address">
                        <Input disabled readOnly value={selectedAddress} />
                      </Form.Item>
                    </Col>
                  </>
                }

                <Divider
                  style={{ margin: '0px 0px 20px', backgroundColor: '#fff', borderTop: '1px dashed #26269642' }}
                />

                <Col xs={24} className="mb-10">
                  <Form.List name="items">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }, i) => (
                          <ItemFields
                            index={i}
                            key={key}
                            name={name}
                            restField={restField}
                            remove={remove}
                            form={form}
                          />
                        ))}
                        <Row justify="end" gutter={5}>
                          <Col>
                            <Button
                              type="primary"
                              className="w-100"
                              size="large"
                              onClick={() => setshowItemPopup(true)}
                            >
                              <div className="flex align-center-v justify-content-center">
                                <FeatherIcon icon={'plus'} size={'16px'} />
                                <span>Add a new item</span>
                              </div>
                            </Button>
                          </Col>
                          <Col>
                            <Form.Item>
                              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add More Items
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Form.List>
                </Col> */}
                {/* <Divider style={{ backgroundColor: '#fff', borderTop: '1px dashed #26269642', margin: '10px 0' }} />

                <Col xs={24} md={12} className="mb-10">
                  <Form.Item
                    name="discount"
                    label="Discount in Rupees"
                    rules={[
                      { pattern: '^[0-9]*$', message: 'Discount should be digit only!' },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const items = getFieldValue('items');
                          let amount = 0;
                          items.map(item => {
                            amount += Number(item?.displayTotal);
                            setGrandTotal(amount - value);
                          });
                          if (items) {
                            const subtotal = items.reduce((a, b) => {
                              if (typeof b.total !== 'undefined') {
                                return a + parseFloat(b.total);
                              }
                              return 0;
                            }, 0);
                            if (!value || subtotal > value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Discount must be less than subtotal of items.'));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      style={{ paddingLeft: '10px' }}
                      placeholder="Discount"
                      prefix="₹"
                      onKeyPress={event => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                </Col> */}
                {/* <Col xs={24} md={12} style={{ textAlign: 'end' }} className="mb-10"> */}
                  {/* <div>
                    <h3 style={{ marginTop: '25px' }}>
                      <span style={{ display: 'inline-block', fontWeight: '700', width: '100px', marginRight: '20px' }}>
                        Total:
                      </span>
                      {grandTotal.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                    </h3>
                    {
                      (company && company.isGSTRegistered) ?
                      <h5>
                        <span style={{ display: 'inline-block', fontWeight: '700', width: '100px', marginRight: '20px' }}>
                          Total Tax:
                        </span>
                        {totalTaxAmount.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                      </h5>
                      :  null

                    }
                  </div> */}
                {/* </Col>

                <Divider style={{ backgroundColor: '#fff', borderTop: '1px dashed #26269642', margin: '10px 0' }} />

                <Col xs={24} md={12} lg={10} className="mb-10">
                  <Row gutter={10}>
                    <Col xs={20}>
                      <Form.Item name="termsAndConditions" label="Terms And Conditions">
                        <Select showSearch placeholder="Select terms and conditions" onChange={handleTermsChange}>
                          {termsConditions.map(t => (
                            <Option key={t._id} value={t._id}>
                              {t.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={4} lg={4} style={{ marginTop: '37px' }}>
                      <Button
                        type="primary"
                        style={{ width: '100%' }}
                        size="large"
                        onClick={() => setshowTermsAndConditionsPopup(true)}
                      >
                        <div className="flex align-center-v justify-content-center">
                          <FeatherIcon icon={'plus'} size={'16px'} />
                          <span>New</span>
                        </div>
                      </Button>
                    </Col>
                    <Col xs={24}>
                      <TextEditor editorData={termsData} setDetails={setTermsContent} />
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={12} lg={10} className="mb-10">
                  <Row gutter={10} align="middle">
                    <Col xs={20}>
                      <Form.Item name="note" label="Note">
                        <Select showSearch placeholder="Select notes" onChange={handleNoteChange}>
                          {notes.map(note => (
                            <Option key={note._id} value={note._id}>
                              {note.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={4} lg={4} style={{ marginTop: '35px' }}>
                      <Button
                        size="large"
                        type="primary"
                        style={{ width: '100%' }}
                        onClick={() => setshowNotePopup(true)}
                      >
                        <div className="flex align-center-v justify-content-center">
                          <FeatherIcon icon={'plus'} size={'16px'} />
                          <span>New</span>
                        </div>
                      </Button>
                    </Col>
                    <Col xs={24}>
                      <TextEditor editorData={noteData} setDetails={setNotesContent} />
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} md={12} lg={4} className="mb-10">
                  <ImageUploadComponent />
                </Col>
                <Col lg={12} xs={24}>
                  <Form.Item name="generateStatus">
                    <Input type="hidden" />
                  </Form.Item>
                </Col>
              </Row>
            </Cards> */}

          </Form>
        </BasicFormWrapper>
      </Main>

      <AddCustomerPopup show={showCustomerPopup} handleCancel={onCancelCustomerPopup} onSubmit={setCustomer} />
      <AddForeignCustomerPopup show={showForeignCustomerPopup} handleCancel={onCancelForeignCustomerPopup} onSubmit={setForeignCustomer} />
      <TermsAndConditionsPopup
        show={showTermsAndConditionsPopup}
        handleCancel={onCancelTermsConditionsPopup}
        onSubmit={setTermsCondition}
      />
      <AddNotePopup show={showNotePopup} handleCancel={onCancelNotesPopup} onSubmit={setNote} />
      <AddItemPopup show={showItemPopup} handleCancel={onCancelItemPopup} onSubmit={() => setshowItemPopup(false)} />
      <AddBankDetailsPopUp
        show={showAddBankDetailPopup}
        handleCancel={onCancelBankDetailPopup}
        onSubmit={setBankDetail}
      />
    </>
  );
};

EditCreditNote.propTypes = {
  match: propTypes.object,
};

export default EditCreditNote;
